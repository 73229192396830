import api from '@api';

const END_POINT = `/settings/organization`;

const transform = (item) => ({
  id: item.id,
  name: item.organization_name,
  description: item.description,
  createdAt: item.created_time
});

const sortKeyMap = {
  name: 'organization_name',
  createdAt: 'created_time'
};

const searchableColumns = ['organization_name', 'description'];

export function getAllOrganizationApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getOrganizationApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function updateOrganization(item) {
  return api
    .put(`${END_POINT}/${item.id}`, {
      organization_name: item.name,
      description: item.description
    })
    .then((data) => {
      return getOrganizationApi(data.result);
    });
}

export function createOrganization(data) {
  return api
    .post(`${END_POINT}`, {
      organization_name: data.name,
      description: data.description
    })
    .then((data) => {
      return getOrganizationApi(data.result);
    });
}

export function deleteOrganization(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}
