import { createContext, useEffect, useContext, useMemo, useState } from 'react';
import { Spin } from 'antd';
import {
  getBrandingApi,
  updateBrandingApi
} from '../modules/settings/api/system-settings/branding';

const BrandingContext = createContext();

const Provider = (props) => {
  const [loading, setLoading] = useState(true);
  let [branding, setBranding] = useState({});

  useEffect(() => {
    getBrandingApi().then((preference) => {
      setBranding(preference);
      if (props.onDataReceived) {
        props.onDataReceived(preference);
      }
      setTimeout(() => {
        setLoading(false);
      });
    });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const value = useMemo(() => {
    function updateBranding(data) {
      return updateBrandingApi(data).then((preference) => {
        getBrandingApi().then((preference) => {
          setBranding(preference);
          if (props.onDataReceived) {
            props.onDataReceived(preference);
          }
        });
      });
    }
    return {
      updateBranding,
      branding
    };
  }, [branding, props]);

  return (
    <BrandingContext.Provider value={value}>
      {loading ? (
        <div className="flex-1 flex items-center justify-center h-full">
          <Spin spinning />
        </div>
      ) : (
        props.children
      )}
    </BrandingContext.Provider>
  );
};

function contextHook() {
  // eslint-disable-next-line
  return useContext(BrandingContext);
}

export const Branding = {
  useBranding: contextHook,
  Provider: Provider
};
