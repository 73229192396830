import { Form, Button, Row, Col } from 'antd';
import { useState } from 'react';
import { ProductSetup } from './ProductSetupProvider';

export default function ProductSetupStepForm({ children, onDone }) {
  const [processingForm, setProcessingForm] = useState(false);
  const [formKey, setFormKey] = useState(1);
  const { currentSection } = ProductSetup.setup();

  function submitForm(values) {
    if (currentSection.submitFormData) {
      setProcessingForm(true);
      return currentSection
        .submitFormData(values)
        .then(() => onDone())
        .finally(() => setProcessingForm(false));
    }
    console.error('Section data process function is not provided!');
    return onDone();
  }

  function resetForm() {
    setFormKey((key) => key + 1);
  }

  return (
    <Form
      layout="vertical"
      key={formKey}
      className="h-full"
      requiredMark={true}
      onFinish={submitForm}
      {...(currentSection.defaultFormItem
        ? { initialValues: currentSection.defaultFormItem }
        : {})}>
      <Row>
        <Col span={24}>{children}</Col>
      </Row>
      <div className="flex-shrink-0 text-right">
        <Button type="primary" loading={processingForm} htmlType="submit" className="mr-2">
          Create
        </Button>
        <Button type="primary" ghost htmlType="reset" onClick={resetForm}>
          Reset
        </Button>
      </div>
    </Form>
  );
}
