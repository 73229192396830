export const colors = [
  '#CE178D',
  '#811278',
  // '#210826',
  '#341a4a',
  '#4f3b78',
  '#6463a5',
  '#89c540',
  '#3279be',
  '#f5bc18',
  '#f58518',
  '#f7a35c',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f04e3e',
  '#91e8e1'
];

const defaultConfig = {
  title: {
    text: null, // widgetName || '',
    show: false,
    align: 'left',
    textStyle: {
      color: 'var(--page-text-color)',
      fontSize: '14px'
    }
  },
  backgroundColor: 'transparent',
  textStyle: {
    fontFamily: 'var(--chart-font-family)'
  },
  color: colors,
  grid: {
    left: 25,
    right: 20,
    top: '10%',
    bottom: '15%',
    containLabel: true
  },
  xAxis: {
    tickLength: 0,
    type: 'time',
    axisLabel: {
      color: 'var(--page-text-color)',
      fontSize: '0.65rem',
      overflow: 'truncate',
      interval: 0,
      width: 75
    },
    nameLocation: 'center',
    nameTextStyle: {
      color: 'var(--page-text-color)'
    },
    axisLine: {
      lineStyle: { color: 'var(--border-color)' }
    }
  },
  yAxis: {
    // min: 0,
    reversedStacks: false,
    axisLabel: {
      color: 'var(--page-text-color)',
      fontSize: '0.65rem',
      overflow: 'breakAll',
      interval: 0,
      width: 75
    },
    nameLocation: 'center',
    nameTextStyle: {
      color: 'var(--page-text-color)'
    },
    axisLine: {
      lineStyle: { color: 'var(--border-color)' }
    }
  },
  toolbox: {
    orient: 'horizontal',
    itemSize: 13,
    top: 10,
    right: 15,
    feature: {
      dataZoom: {
        yAxisIndex: 'none',
        icon: {
          // zoom: 'path://', // hack to remove zoom button
          // back: 'path://', // hack to remove restore button
        }
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    renderMode: 'html',
    crosshairs: true,
    confine: true,
    backgroundColor: 'var(--chart-tooltip-background)',
    borderColor: 'var(--border-color)',
    textStyle: { color: 'var(--page-text-color)' }
  },
  legend: {
    type: 'scroll',
    align: 'auto',
    pageIconColor: 'var(--page-text-color)',
    pageTextStyle: { color: 'var(--page-text-color)' },
    // pageButtonPosition: 'start',
    orient: 'horizontal',
    top: 'bottom',
    left: 'center',
    bottom: 20,
    right: 15,
    itemGap: 35,
    icon: 'rect',
    padding: [0, 20, 5, 20],
    inactiveColor: 'var(--input-placeholder-color)',
    itemWidth: 11,
    itemHeight: 11,
    borderRadius: 1,
    itemStyle: {
      opacity: '1'
    },
    textStyle: {
      color: 'var(--page-text-color)',
      fontWeight: 'normal',
      fontSize: '0.65rem',
      width: 200,
      overflow: 'truncate'
    },
    itemHoverStyle: {
      color: 'var(--page-text-color)',
      fontWeight: 'normal',
      opacity: '1'
    },
    itemHiddenStyle: {
      opacity: '0.5'
    },
    animationDuration: 500,
    animationDurationUpdate: 200
  },
  useUTC: true
};

export default defaultConfig;
