// import { ProductSetup } from './ProductSetupProvider';
// import { CheckCircleOutlined } from '@ant-design/icons';

export default function WelcomeStep({ sections, onSectionSelected }) {
  // const { preference } = ProductSetup.setup();
  return (
    <div className="flex items-center justify-center">
      <ul className="list-decimal">
        {sections
          .filter((s) => !s.excluded)
          .map((section) => (
            <li
              className={`text-base font-semibold my-2 cursor-pointer ${
                section.isEnabled
                  ? section.isEnabled()
                    ? ''
                    : ' opacity-40 cursor-not-allowed pointer-events-none'
                  : ''
              }`}
              key={section.id}
              onClick={() => onSectionSelected(section)}>
              <div className="flex items-center">
                <span className="mr-4">{section.title}</span>
                {/* {((preference.setup || {}).completedSections || []).includes(section.id) ? (
                  <CheckCircleOutlined />
                ) : null} */}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}
