import { Form, Input, Row, Col, Switch, Avatar } from 'antd';
import TimezonePicker from '@/src/components/pickers/TimezonePicker';
import Uploader from '@/src/components/Uploader';
import { Department } from '@/src/components/pickers/DepartmentPicker';
import { Role } from '@/src/components/pickers/RolePicker';
import { useAuth } from '@/src/hooks/auth';
import { Organization } from '@/src/components/pickers/OrganizationPicker';

export default function UserForm({ item = {} }) {
  const { token } = useAuth();

  const form = Form.useFormInstance();

  return (
    <>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Username" name="username" rules={[{ required: true }]}>
            <Input placeholder="Username" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Phone" name="phone">
            <Input placeholder="Phone" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Password" name="password" rules={[{ required: !item.id }]}>
            <Input placeholder="Password" type="password" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            dependencies={['password']}
            rules={[
              {
                required: !item.id
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The passwords that you entered do not match!'));
                }
              })
            ]}>
            <Input placeholder="Confirm Password" type="password" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Timezone" name="timezone" rules={[{ required: true }]}>
            <TimezonePicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row gutter={32}>
            <Col span={4}>
              <Form.Item label="Status" name="status" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Login Allowed" name="login_allow" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Asset Assignment Allowed"
                name="asset_assignment_allow"
                valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="relative">
          {item.profile &&
            item.profile.length > 0 &&
            (item.profile[0].ref || item.profile[0].response) && (
              <Avatar
                // eslint-disable-next-line
                src={`/api/download?id=${
                  item.profile[0].ref || item.profile[0].response
                  // eslint-disable-next-line
                }&mid=${token.access_token}`}
                size={55}
                className="absolute"
                style={{ top: '40px', left: '15px' }}
              />
            )}
          <Form.Item label=" " name="profile" className="flex-1">
            <Uploader />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Organization" name="organization" rules={[{ required: true }]}>
            <Organization.Picker
              onChange={(organization) => {
                form.setFieldValue('organization', organization);
                form.setFieldValue('department', undefined);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Department" name="department" rules={[{ required: true }]}>
            <Department.Picker
              hideWithoutOrganization
              organization={form.getFieldValue('organization')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Role" name="role" rules={[{ required: true }]}>
            <Role.Picker />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
