import navicons from './nav';
import generalIcons from './general';
import brandIcons from './brand';
import platformIcons from './platform';

const allIcons = {
  ...navicons,
  ...generalIcons,
  ...brandIcons,
  ...platformIcons
};

export default allIcons;
