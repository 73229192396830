import { getAllUserApi } from '@/src/modules/settings/api/user-management/users';
import Picker from './Picker.jsx';
import buildPicker from './pickerBuilder';

const { Provider, contextHook } = buildPicker(getAllUserApi, UserPicker, (i) => ({
  value: i.id,
  label: i.name,
  email: i.email
}));

function UserPicker({ value, onChange, ...props }) {
  const { filteredOptions, allOptions, search } = contextHook();
  if (props.disabled && props.textOnly) {
    return allOptions.has(value) ? allOptions.get(value).label : null;
  }
  return (
    <Picker
      {...props}
      style={{ width: '100%' }}
      placeholder="Please Select"
      value={value}
      onChange={onChange}
      onSearch={search}
      options={filteredOptions || allOptions}
    />
  );
}

export const User = {
  useUsers: contextHook,
  Provider: Provider,
  Picker: UserPicker
};
