import { createContext, useContext, useMemo, useState } from 'react';
import { Divider } from 'antd';
import Uniq from 'lodash/uniq';
import { UserPreference } from '../UserPreference';
import ProductSetupStepContainer from './ProductSetupStepContainer';
import {
  getActionsForSection,
  getComponentForSection,
  getCurrentSection,
  getNextSection,
  getPreviousSection
} from './steps';
import { useAuth } from '@/src/hooks/auth';

const ProductSetupContext = createContext();

const Provider = (props) => {
  const { user } = useAuth();
  const [currentSectionId, setCurrentSectionId] = useState('welcome');
  const { preference, updateMyPreference } = UserPreference.usePreference();
  const [open, setOpen] = useState(
    user.id === 0 ? (preference.setup && preference.setup.isCompleted ? false : true) : false
  );

  function completeSetup() {
    return updateMyPreference({
      setup: {
        isCompleted: true
      }
    });
  }

  // eslint-disable-next-line
  const value = useMemo(() => ({
    completeSetup,
    preference,
    currentSection: getCurrentSection(currentSectionId),
    start: () => {
      setCurrentSectionId('welcome');
      setOpen(true);
    }
  }));

  function markAsComplete(sectionId) {
    let completedSections = (preference.setup || {}).completedSections || [];
    completedSections = Uniq([...completedSections, sectionId]);
    return updateMyPreference({
      setup: {
        ...(preference.setup || {}),
        completedSections
      }
    });
  }

  function moveToNextSection(autoClose = true) {
    let section = getNextSection(currentSectionId);
    if (section) {
      setCurrentSectionId(section.id);
    } else {
      setOpen(false);
    }
  }

  function moveToPreviousSection() {
    let section = getPreviousSection(currentSectionId);
    if (section) {
      setCurrentSectionId(section.id);
    }
  }

  return (
    <ProductSetupContext.Provider value={value}>
      {props.children}
      {open && (
        <ProductSetupStepContainer open={open} onClose={() => setOpen(false)}>
          <div className="flex flex-col h-full">
            <div className="flex flex-col flex-1">
              {getComponentForSection({
                sectionId: currentSectionId,
                completedSections: (preference.setup || {}).completedSections || [],
                onSectionSelected(section) {
                  setCurrentSectionId(section.id);
                },
                moveToNextSection,
                moveToPreviousSection,
                markAsComplete,
                onDone() {
                  markAsComplete(currentSectionId).then(() => moveToNextSection());
                }
              })}
            </div>
            <Divider />
            {getActionsForSection({
              sectionId: currentSectionId,
              onSectionSelected(section) {
                setCurrentSectionId(section.id);
              },
              onCompleted() {
                completeSetup().then(() => setOpen(false));
              },
              markAsComplete,
              moveToNextSection,
              moveToPreviousSection
            })}
          </div>
        </ProductSetupStepContainer>
      )}
    </ProductSetupContext.Provider>
  );
};

function contextHook() {
  // eslint-disable-next-line
  return useContext(ProductSetupContext);
}

export const ProductSetup = {
  setup: contextHook,
  Provider: Provider
};
