import api from '@api';

const END_POINT = `/settings/user`;

const transform = (item) => ({
  id: item.id,
  username: item.username,
  name: item.full_name,
  email: item.email,
  role: +item.role,
  status: Boolean(item.status),
  type: item.type,
  phone: item.mobile_no,
  // isValidLicense: item.isValidLicense,
  isValidLicense: true,
  timezone: item.timezone,
  permissions: item.permissions,
  department: +item.department !== -1 ? +item.department : undefined,
  organization: +item.organization,
  login_allow: Boolean(item.login_allow),
  asset_assignment_allow: Boolean(item.asset_assignment_allow),
  ...(item.profile_image_ref
    ? {
        profile: [
          {
            ref: item.profile_image_ref,
            name: item.profile_image
          }
        ]
      }
    : {}),
  createdAt: item.created_time
});

const transformForServer = async (item) => {
  return Promise.resolve({
    username: item.username,
    full_name: item.name,
    email: item.email,
    role: item.role,
    department: item.department,
    organization: item.organization,
    type: 0,
    mobile_no: item.phone,
    timezone: item.timezone,
    status: item.status ? 1 : 0,
    login_allow: item.login_allow ? 1 : 0,
    asset_assignment_allow: item.asset_assignment_allow ? 1 : 0,
    ...(item.password ? { password: item.password } : {}),
    ...(item.profile && item.profile.length > 0 && item.profile[0].response
      ? {
          profile_image_ref: item.profile[0].response.ref,
          profile_image: item.profile[0].response.name
        }
      : {})
  });
};

const sortKeyMap = {
  name: 'full_name',
  phone: 'mobile_no',
  createdAt: 'created_time'
};

const searchableColumns = ['username', 'full_name', 'email', 'mobile_no'];

export function getAllUserApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getAssignableUsersApi(offset, size, sortFilter) {
  return api.post(`${END_POINT}/assignment/ `).then((data) => {
    return {
      totalCount: data.totalCount,
      result: data.result.map(transform)
    };
  });
}

export function getUserApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function getMeApi() {
  return api.get(`/user/me`).then(({ result }) => transform(result));
}

export function getMyPreferenceApi() {
  return api.get(`/user/preference`).then(({ result }) => result.preference || {});
}

export function updateMyPreferenceApi(preference) {
  return api.put(`/user/preference`, { preference }).then(() => getMyPreferenceApi());
}

export function updateUser(item) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${END_POINT}/${item.id}`, data);
    })
    .then((data) => getUserApi(data.result));
}

export function createUser(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getUserApi(data.result));
}

export function deleteUser(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}

export function importUsersByCsvApi(data) {
  return api.post(`${END_POINT}/import`, {
    ref_name: data.csv_file[0].ref || data.csv_file[0].response.ref,
    file_name: data.csv_file[0].ref || data.csv_file[0].response.name
  });
}
