import { createContext, useContext, useMemo } from 'react';
import { useAuth } from '../hooks/auth';

const PermissionsContext = createContext();

const Provider = (props) => {
  const { user } = useAuth();

  const hasPermission = (permissions, hasAny = true) => {
    permissions = (Array.isArray(permissions) ? permissions : [permissions]).filter(Boolean);
    if (permissions.length) {
      return permissions[hasAny ? 'some' : 'every']((p) => user.permissions.includes(p));
    }
    return true;
  };

  const value = useMemo(
    () => ({
      permissions: user.permissions,
      hasPermission
    }),
    // eslint-disable-next-line
    [user]
  );

  return <PermissionsContext.Provider value={value}>{props.children}</PermissionsContext.Provider>;
};

function contextHook() {
  // eslint-disable-next-line
  return useContext(PermissionsContext);
}

export const Permissions = {
  usePermission: contextHook,
  Provider: Provider
};
