const colorPalettes = {
  'palette-1': [
    // '#142459',
    '#176BA0',
    '#19AADE',
    '#1AC9E6',
    '#18D4D4',
    '#1DE4BD',
    '#6DF0D2',
    '#C7F9EE'
  ],
  'palette-2': [
    // '#29066B',
    '#7C3AC1',
    '#AF4BCF',
    '#DB4CB2',
    '#EB548C',
    '#EA7369',
    '#F0A58F',
    '#FCEAE6'
  ],
  'palette-3': [
    // '#820400',
    '#C02423',
    '#DE542D',
    '#EF7E33',
    '#EE9A3A',
    '#EABD3B',
    '#E7E34E',
    '#F8F4BF'
  ]
};

export default colorPalettes;
