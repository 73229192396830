import { Routes, Route } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import ReactLoading from 'react-loading';
import AvailableModules from './available-modules';
import tokens, { components } from './design/theme';
import { AuthLayout } from './layouts/AuthLayout';
import AppLayout from './layouts/Layout';
import ReportLayout from './layouts/ReportLayout';
import NotFound from './components/NotFound';
import { createContext, useContext } from 'react';
import ReportExportPage from './views/ReportExportPage';

Spin.setDefaultIndicator(
  <ReactLoading type={'cylon'} color={'var(--page-text-color)'} height={40} width={40} />
);

const AppContext = createContext();

function App() {
  return (
    <AppContext.Provider value={{}}>
      <ConfigProvider
        componentSize={'small'}
        theme={{
          token: tokens,
          components
        }}>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            {AvailableModules.map((moduleDef) => moduleDef.router)}
            <Route
              path="/report-export/:id"
              element={
                <ReportLayout>
                  <ReportExportPage />
                </ReportLayout>
              }
            />
            <Route
              path="unauthorized"
              element={
                <AppLayout>
                  <NotFound />
                </AppLayout>
              }
            />
            <Route
              path="404"
              element={
                <AppLayout>
                  <NotFound />
                </AppLayout>
              }
            />
            <Route
              path="*"
              element={
                <AppLayout>
                  <NotFound />
                </AppLayout>
              }
            />
          </Route>
        </Routes>
      </ConfigProvider>
    </AppContext.Provider>
  );
}

export default App;

export function useApp() {
  return useContext(AppContext);
}
