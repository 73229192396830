import { createContext, useContext, useMemo, useState } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const BreadcrumbContext = createContext();

const Provider = (props) => {
  const outerBreadcrumb = useBreadcrumbs();
  const [breadcrumb, setBreadcrumb] = useState(null);

  // eslint-disable-next-line
  const value = useMemo(() => ({
    setBreadcrumb,
    breadcrumb: breadcrumb || outerBreadcrumb
  }));

  return <BreadcrumbContext.Provider value={value}>{props.children}</BreadcrumbContext.Provider>;
};

function contextHook() {
  // eslint-disable-next-line
  return useContext(BreadcrumbContext);
}

export const Breadcrumb = {
  useBreadcrumb: contextHook,
  Provider: Provider
};
