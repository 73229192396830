import { Button, Tag } from 'antd';
import { CrudProvider } from '@/src/hooks/crud';
import {
  bulkDeleteConfigurationApi,
  createConfigurationApi,
  deleteConfigurationApi,
  getAllConfigurationsApi,
  updateConfigurationApi
} from '../configurations';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';
import { User } from '@/src/components/pickers/UserPicker';
import ConfigurationForm from '../components/ConfigurationForm';
import BulkDeleteButton from '@/src/components/BulkDeleteButton';
import Icon from '@/src/components/Icon';

export default function Configurations({ fetchFn, disabled }) {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'name',
      key: 'name',
      type: 'view_link'
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      align: 'center',
      sortable: false,
      render({ record }) {
        return (
          <div className="text-xl">
            <Icon name={`platform_${record.os}`} />
          </div>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'configurationType',
      align: 'center',
      key: 'configurationType',
      sortable: false,
      render({ record }) {
        return (
          <Tag
            color={'processing'}
            className="inline-flex items-center justify-center"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>
            {record.configurationType}
          </Tag>
        );
      }
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      sortable: false,
      key: 'createdBy',
      render({ record }) {
        return <User.Picker textOnly value={record.createdBy} disabled />;
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      type: 'datetime'
    },
    ...(disabled
      ? []
      : [
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            editPermissions: [constants.Update_Configuration],
            deletePermissions: [constants.Delete_Configuration]
          }
        ])
  ];

  return (
    <User.Provider>
      <CrudProvider
        columns={columns}
        defaultFormItem={{
          configurationType: 'command',
          configurationActions: [{}]
        }}
        resourceTitle="Configuration"
        disableFormScrolling
        hasSearch
        fetchFn={fetchFn || getAllConfigurationsApi}
        deleteFn={deleteConfigurationApi}
        createFn={createConfigurationApi}
        updateFn={updateConfigurationApi}
        disableColumnSelection={disabled}
        allowSelection={!disabled}
        createAfterSlot={(_, { selectedItems, fetchData, setSelectedItems }) => (
          <BulkDeleteButton
            selectedItems={selectedItems}
            resourceTitle="Configurations"
            className="ml-2"
            onConfirm={() =>
              bulkDeleteConfigurationApi(selectedItems).then(() => {
                setSelectedItems([]);
                return fetchData();
              })
            }
          />
        )}
        createSlot={(createFn) =>
          !disabled && (
            <PermissionChecker permission={constants.Create_Configuration}>
              <Button type="primary" onClick={createFn}>
                Create
              </Button>
            </PermissionChecker>
          )
        }
        formFields={(item) => <ConfigurationForm item={item} />}
      />
    </User.Provider>
  );
}
