import { Form, Input, Col, Row, DatePicker, Radio, Divider, Switch, InputNumber } from 'antd';
// import Dayjs from 'dayjs';
import RecipientsPicker from '@/src/components/pickers/RecipientsPicker';
import { DeploymentPolicy } from '@/src/components/pickers/DeploymentPolicyPicker';
import DrawerSelectionItemPicker from '@components/pickers/DrawerSelectionItemPicker';
import { DRAWER_SELECTION_PICKER_TYPE } from '@components/helper/picker-helper';
import { END_POINT } from '../api/patch-list';
import AssetScopePicker from '@/src/components/pickers/AssetScopePicker';
import { searchableColumns } from '../api/patch-list';
import { REFMODEL } from '@modules/settings/api/software-deployment/deployment-policy';
import DeploymentPolicyShower from '@modules/settings/components/software-deployment/DeploymentPolicyShower';

export default function PatchDeploymentForm({ item = {} }) {
  const form = Form.useFormInstance();

  return (
    <Row gutter={32}>
      <Col span={24}>
        <Form.Item label="Name" name="displayName">
          <Input placeholder="Name" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label="Description" name="description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Install After" name="nextExecutionTime">
          <DatePicker placeholder="Install After" className="w-full" showTime />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Expiry Date" name="lastExecutionTime">
          <DatePicker placeholder="Expiry Date" className="w-full" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Configuration Type" name="deploymentType" rules={[{ required: true }]}>
          <Radio.Group optionType="button">
            <Radio value="install">Install</Radio>
            <Radio value="uninstall">Uninstall</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      {/* <Col span={24}>
        <div className=" flex flex-col  my-2 text-primary text-sm grow-0">Patches</div>
        <Divider className="my-1 grow-0" />
      </Col> */}
      <Col span={24}>
        <Form.Item label=" " noStyle name="scope">
          <AssetScopePicker
            label="Scope"
            gutter={16}
            name={['scope', 'assetFilter']}
            subname={['scope', 'assets']}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Patches" name="refIds" rules={[{ required: true }]}>
          <DrawerSelectionItemPicker
            type={DRAWER_SELECTION_PICKER_TYPE.PATCH}
            apiEndpoint={`${END_POINT}/search`}
            searchableColumns={searchableColumns}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <div className=" flex flex-col  my-2 text-primary text-sm grow-0">
          Configuration Settings
        </div>
        <Divider className="my-1 grow-0" />
      </Col>

      <Col span={12}>
        <Form.Item label="Deployment Policy" name="deploymentPolicyId" rules={[{ required: true }]}>
          <DeploymentPolicy.Picker
            value={item.deploymentPolicyId}
            refModel={REFMODEL.PATCH}
            emitFullObject
            setFullObject={(policy) => {
              form.setFieldValue('policyContext', policy);
            }}
          />
        </Form.Item>
      </Col>
      <Col
        span={12}
        className="flex items-denter"
        key={form.getFieldValue('deploymentPolicyId') || 'id'}>
        <Form.Item label="policyContext" name="policyContext" style={{ visibility: 'hidden' }}>
          <Input placeholder="policyContext" disabled={form.getFieldValue('policyContext')} />
        </Form.Item>
      </Col>

      <Col span={24} className="mb-6">
        <DeploymentPolicyShower
          policyId={form.getFieldValue('deploymentPolicyId')}
          policyContext={form.getFieldValue('policyContext')}
        />
      </Col>
      <Col span={12}>
        <Form.Item label="Notify to" name="notifyEmailIds">
          <RecipientsPicker />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label="Retry Failed Configuration"
          name="retryFailedConfiguration"
          valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>

      {form.getFieldValue('retryFailedConfiguration') ? (
        <Col span={12}>
          <Form.Item label="Retry Count" name="retrycount">
            <InputNumber placeholder="Retry Count" className="w-full" />
          </Form.Item>
        </Col>
      ) : null}
    </Row>
  );
}
