import { getAllComputerGroupsApi } from '@/src/modules/settings/api/patch-management/computer-groups.js';
import Picker from './Picker.jsx';
import buildPicker from './pickerBuilder';

const { Provider, contextHook } = buildPicker(
  getAllComputerGroupsApi,
  ComputerGroupsPicker,
  (i) => ({
    value: i.id,
    label: i.name
  })
);

function ComputerGroupsPicker({ value, onChange, ...props }) {
  const { filteredOptions, allOptions, search } = contextHook();
  if (props.disabled && props.textOnly) {
    return allOptions.has(value) ? allOptions.get(value).label : null;
  }
  return (
    <Picker
      {...props}
      style={{ width: '100%' }}
      placeholder="Please Select"
      value={value}
      onChange={onChange}
      onSearch={search}
      options={filteredOptions || allOptions}
    />
  );
}

export const ComputerGroups = {
  useComputerGroups: contextHook,
  Provider: Provider,
  Picker: ComputerGroupsPicker
};
