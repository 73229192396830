import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import api from '@api';

export default function Uploader({ onChange, value, message, accept = 'image/*' }) {
  const uploaderProps = {
    accept,
    multiple: false,
    fileList: value,
    customRequest({ onProgress, file, onSuccess }) {
      const headers = api.getHeaders();
      const formData = new FormData();
      formData.append('attachment', file);
      return api
        .getNewClient(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}`)
        .post(`/upload`, formData, {
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
          headers: {
            Authorization: headers.common.Authorization,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(({ data }) => {
          const obj = {
            ref: data.result['file-ref'],
            name: data.result['file-name']
          };
          onSuccess(obj);
        });
    },
    onChange(data) {
      for (let i = 0; i < data.fileList.length - 1; i++) {
        data.fileList.splice(i, 1);
      }
      onChange(data.fileList);
    }
  };

  return (
    <Upload.Dragger {...uploaderProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        {message} {message ? <br /> : null} Support for a single upload.
      </p>
    </Upload.Dragger>
  );
}
