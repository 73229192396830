import { Row, Col, Transfer } from 'antd';
import buildPicker from './pickerBuilder';
import Icon from '../Icon.jsx';
import { getAllBundlesApi } from '@/src/modules/device-automation/bundles.js';

const { Provider, contextHook } = buildPicker(
  (...args) => getAllBundlesApi(...args, { category: 'package' }),
  PackageBundlePicker,
  (i, options) => ({
    key: i.id,
    os: i.os,
    label: `${i.name} - ${i.description}`,
    nodeRenderer: (
      <Row gutter={16} wrap={false} align="middle">
        <Col flex="none" className="items-center inline-flex">
          <Icon name={`platform_${i.os}`} className="text-base" />
        </Col>
        <Col flex="auto">{i.name}</Col>
      </Row>
    )
  })
);

function PackageBundlePicker({ target, ...props }) {
  const { filteredOptions, allOptions } = contextHook();
  let options = filteredOptions || allOptions;
  if (target === 4) {
    options = Array.isArray(options)
      ? options
      : Array.from(options.values()).filter((i) => i.os.toLowerCase() === 'windows');
  }
  if (target === 5) {
    options = Array.isArray(options)
      ? options
      : Array.from(options.values()).filter((i) => i.os.toLowerCase() === 'linux');
  }
  if (target === 6) {
    options = Array.isArray(options)
      ? options
      : Array.from(options.values()).filter((i) => i.os.toLowerCase() === 'mac');
  }
  return (
    <Transfer
      {...props}
      showSearch
      filterOption={(searchTerm, node) => {
        return node.title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
      }}
      render={(i) => i.nodeRenderer}
      dataSource={Array.isArray(options) ? options : Array.from(options.values())}
      listStyle={{
        width: '45%',
        height: 350
      }}
    />
  );
}

export const PackageBundle = {
  usePackageBundle: contextHook,
  Provider: Provider,
  Picker: PackageBundlePicker
};
