import React, { useState } from 'react';
import { message, notification, Spin, ConfigProvider } from 'antd';
import { AuthGuard } from '@components/AuthGuard';
import Loading from '../components/Loading';
import { Socket } from '../components/SocketListener';
import { UserPreference } from '../components/UserPreference';
import { Branding } from '../components/Branding';

export default function ReportLayout({ children }) {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [messageApi, messageHolder] = message.useMessage();
  const notificationHolder = notification.useNotification()[1];
  const url = `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SOCKET_URL : ''}`;

  return (
    <AuthGuard>
      <ConfigProvider componentSize={'small'}>
        <UserPreference.Provider>
          <Branding.Provider>
            <Socket.Provider
              url={`${url}/eventbus`}
              onDisconnect={() => {
                messageApi.open({
                  key: 'socket-message',
                  type: 'error',
                  content: 'Unable to connect to socket for live update!',
                  duration: 0
                });
              }}
              onConnect={() => {
                messageApi.destroy('socket-message');
                setLoading(false);
              }}>
              <React.Suspense fallback={<Loading />}>
                {loading ? (
                  <div className="flex-1 flex items-center justify-center h-full">
                    <Spin spinning />
                  </div>
                ) : (
                  children
                )}
              </React.Suspense>
            </Socket.Provider>
            {messageHolder}
            {notificationHolder}
          </Branding.Provider>
        </UserPreference.Provider>
      </ConfigProvider>
    </AuthGuard>
  );
}
