import Capitalize from 'lodash/capitalize';
import generateId from '@/src/utils/id';
import api from '@api';
import { transformTimelineForServer } from '../../dashboard/widget-api';
import { transformPackageIcon } from '@/src/helpers/package-icon';

const END_POINT = `/inventory/asset`;

function transformAssetSummary(item) {
  return {
    id: item.id,
    hostname: item.hostname,
    osName: item.name,
    vendor: item.hardware_vendor,
    owner: item.users !== null ? +item.users : undefined,
    hardwareModel: item.hardware_model,
    department: item.departments !== null ? +item.departments : undefined,
    location: item.location !== null ? +item.location : undefined,
    currentStatus: item.current_status,
    agentVersion: item.agent_version,
    lastAssessment: item.last_assessment,
    lastSeen: item.last_seen,
    vulnerability: item.vulnerability,
    compliance: item.compliance,
    rootkit: item.rootkit,
    riskScore: item.risk_score,
    quickCheckResult: item.quick_check_result,
    malware: item.malware,
    expiredCertificates: item.expired_cert,
    releaseDate: item.release_date,
    alert: ['All', 'Critical', 'High', 'Medium', 'Low'].reduce(
      (prev, s) => ({ ...prev, [s]: (item.alert || {})[s] || 0 }),
      {}
    ),
    eolDate: item.eol_date,
    eosDate: item.eos_date,
    createdAt: item.created,
    loggedInUsers: item.total_logged_in_users,
    uptime: item.uptime,
    ...(item.system_info
      ? {
          systemInfo: {
            disk: item.system_info.total_disk_space,
            os: item.system_info.os,
            osBuild: item.system_info.os_build,
            vendor: item.system_info.vendor,
            osVersion: item.system_info.os_version,
            cpuCore: item.system_info.cpu_core,
            cpu: item.system_info.cpu,
            cpuUtilization: item.system_info.cpu_utilization,
            diskUtilization: item.system_info.disk_utilization,
            freeDiskSpace: item.system_info.free_disk_space,
            memoryUtilization: item.system_info.memory_utilization,
            freeMemory: item.system_info.free_memory,
            freeSwapMemory: item.system_info.free_swap_memory,
            totalSwapMemory: item.system_info.total_swap_memory,
            usedDiskSpace: item.system_info.used_disk_space,
            cpuType: item.system_info.cpu_type,
            uuid: item.system_info.uuid,
            memory: item.system_info.total_memory,
            diskPartitions: item.disk_partitions || []
          }
        }
      : {}),
    ...(item.security
      ? {
          security: {
            maliciousProcesses: item.security.malicious_process,
            softwareVulnerabilities: item.security.software_vulnarabilities,
            complianceIssues: item.security.compliance_issues,
            malware: item.security.malware,
            rootkit: item.security.rootkit,
            expiredCertificates: item.security.expired_cert,
            fimEvents: item.security.fim_events
          }
        }
      : {}),
    ...(item.os_and_softwares
      ? {
          osAndSoftwares: {
            eol: item.os_and_softwares.eol_packages || 0,
            blacklisted: item.os_and_softwares.blacklist_packages || 0,
            processes: item.os_and_softwares.processes,
            softwares: item.os_and_softwares.softwares || 0,
            packages: item.os_and_softwares.packages,
            certificates: item.os_and_softwares.certificates,
            kernelInfo: item.os_and_softwares.kernel_info
          }
        }
      : {}),
    ...(item.asset_users
      ? {
          systemUsers: item.asset_users.map((user) => ({
            id: user.id,
            username: user.username,
            uid: user.uid,
            group: user.groupname,
            shell: user.shell,
            description: user.description,
            createdAt: user.created_time,
            directory: item.directory
          }))
        }
      : {}),
    ...(item.logged_in_users
      ? {
          loggedInUsers: item.logged_in_users.map((user) => ({
            id: user.id,
            username: user.user,
            host: user.host,
            pid: user.pid,
            type: user.type,
            tty: user.tty
          }))
        }
      : {})
  };
}

export function transform(item) {
  return {
    ...item,
    id: item.id,
    hostname: item.host_name,
    computerName: item.computer_name,
    vendor: item.vendor,
    risk: item.risk,
    owner: item.users !== null ? +item.users : undefined,
    department: item.departments !== null ? +item.departments : undefined,
    location: item.location !== null ? +item.location : undefined,
    version: item.version,
    currentStatus: item.current_status,
    createdAt: item.created_at
  };
}

function transformForServer(item) {
  return {
    id: item.id,
    users: item.owner,
    departments: item.department,
    location: item.location
  };
}

const sortKeyMap = {
  computerName: 'computer_name',
  hostname: 'host_name',
  owner: 'users',
  department: 'departments',
  createdAt: 'created_at'
};

const searchableColumns = [
  'host_name',
  'uuid',
  'arch',
  'code_name',
  'major',
  'minor',
  'name',
  'patch',
  'platform',
  'platform_like',
  'os_version',
  'osquery_version',
  'vendor',
  'version',
  'cpu_type',
  'cpu_subtype',
  'cpu_brand',
  'cpu_physical_cores',
  'cpu_logical_cores',
  'hardware_vendor',
  'hardware_model',
  'hardware_version',
  'hardware_serial',
  'computer_name',
  'kernel_version'
];

export function getInventoryHostsApi(
  offset,
  size,
  sortFilter,
  filter,
  locationFilter,
  departmentFilter
) {
  return api
    .post(`${END_POINT}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {
            sortBy: 'current_status'
          }),
      ...(filter && filter.key !== 'all'
        ? {
            ...(filter.type === 'version'
              ? {
                  version: filter.title,
                  os: filter.platform
                }
              : {
                  os: filter.children.map((item) => item.platform).join(',')
                })
          }
        : {}),
      ...(locationFilter && locationFilter.key !== 'all' && locationFilter.key !== 'location'
        ? {
            location: locationFilter.key
          }
        : {}),
      ...(departmentFilter &&
      departmentFilter.key !== 'all' &&
      departmentFilter.key !== 'department'
        ? {
            department: departmentFilter.key
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getInventoryHostsStatisticsApi() {
  const availableKeys = ['All End Points', 'Windows', 'Linux', 'Mac'];
  return api.get(`${END_POINT}/categories/analytics`).then(({ result }) => {
    return availableKeys.reduce(
      (prev, key) => ({
        ...prev,
        [key]: result[key] || { total: 0, online: 0, offline: 0, chart: [] }
      }),
      {}
    );
  });
}

export function getInventoryHostByIdApi(id) {
  return api.get(`${END_POINT}/${id}`);
}

export function getRulesForFrameworkApi(assetId, frameworkId, timeline) {
  return api
    .post(`/inventory/asset/compliance/${assetId}/${frameworkId}`, {
      timeline_context: transformTimelineForServer(timeline)
    })
    .then((data) => {
      return {
        totalCount: data.result.length,
        result: data.result.map((i) => ({
          ...i,
          id: generateId()
        }))
      };
    });
}

const startupItemSearchableColumns = ['name', 'status'];

export function getStartupItemsForHostApi(assetId, offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/startup/items/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${sortFilter.sort.field}`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? startupItemSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => ({
      totalCount: data.totalCount,
      result: data.result.map((i) => ({
        id: i.id,
        name: i.name,
        status: i.status
      }))
    }));
}

const loggedUserSearchableColumns = ['user', 'tty', 'type', 'sid', 'host'];

export function getLoggedInUsersForHostApi(assetId, offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/logged/users/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${sortFilter.sort.field}`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? loggedUserSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => ({
      totalCount: data.totalCount,
      result: data.result.map((i) => ({
        id: i.id,
        user: i.user,
        tty: i.tty,
        type: i.type,
        pid: i.pid,
        sid: i.sid,
        host: i.host
      }))
    }));
}

const usersSearchableColumns = [
  // 'uid',
  'username',
  'description',
  'directory',
  'shell',
  'uuid',
  'type',
  'groupname'
];

export function getUsersForHostApi(assetId, offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/users/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${sortFilter.sort.field}`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? usersSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => ({
      totalCount: data.totalCount,
      result: data.result.map((i) => ({
        id: i.id,
        uid: i.uid,
        username: i.username,
        description: i.description,
        directory: i.directory,
        shell: i.shell,
        uuid: i.uuid,
        groupname: i.groupname
      }))
    }));
}

export function getInventoryPackagesApi(offset, size, sortFilter, filter) {
  return api
    .post(`/inventory/package/packages`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              browserSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      ...(filter && filter.key !== 'all'
        ? {
            ...(filter.type === 'version'
              ? {
                  version: filter.title,
                  os: filter.platform
                }
              : {
                  os: filter.children.map((item) => item.platform).join(',')
                })
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? packageSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((i) => ({
          id: i.id,
          name: i.name,
          blacklist: i.blacklist === 'yes',
          version: i.version,
          releaseDate: i.release_date,
          eolDate: i.eol_date,
          eosDate: i.eos_date,
          asset_count: i.asset_count,
          vulnerability: i.vulnerability_count
        }))
      };
    });
}

export function updateInventoryPackageApi(packageData) {
  return api
    .put(`/inventory/package/packages/${packageData.id}`, {
      blacklist: packageData.blacklist ? 'yes' : 'no'
    })
    .then(() => packageData);
}

const browserSortKeyMap = {
  uid: 'id',
  vulnerability: 'vulnerability_count'
};

export function getInventoryBrowsersApi(offset, size, sortFilter, filter) {
  return api
    .post(`/inventory/package/browsers`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              browserSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      ...(filter && filter.key !== 'all'
        ? {
            ...(filter.type === 'version'
              ? {
                  version: filter.title,
                  os: filter.platform
                }
              : {
                  os: filter.children.map((item) => item.platform).join(',')
                })
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? packageSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((i) => ({
          id: i.id,
          name: i.name,
          version: i.version,
          blacklist: i.blacklist === 'yes',
          releaseDate: i.release_date,
          eolDate: i.eol_date,
          eosDate: i.eos_date,
          asset_count: i.asset_count,
          vulnerability: i.vulnerability_count
        }))
      };
    });
}

export function updateInventoryBrowserApi(packageData) {
  return api
    .put(`/inventory/package/browsers/${packageData.id}`, {
      blacklist: packageData.blacklist ? 'yes' : 'no'
    })
    .then(() => packageData);
}

export function updateInventoryHostApi(data) {
  return api.put(`${END_POINT}/${data.id}`, transformForServer(data)).then(() => data);
}

export function deleteInventoryHostApi(data) {
  return api.delete(`${END_POINT}/${data.id}`);
}

export function getHostsSummaryApi(id) {
  return api
    .get(`${END_POINT}/summary/${id}`)
    .then(({ result }) => transformAssetSummary({ ...result, id }));
}

const processSortKeyMap = {
  status: 'state',
  commandline: 'cmdline',
  vulnerability: 'vulnerability_count'
};

const processSearchableColumns = [
  // 'pid',
  'cast(pid as varchar)',
  'state',
  'name',
  'cmdline',
  'path',
  // 'ppid',
  'cast(ppid as varchar)',
  'sha256',
  'md5',
  'uid',
  'username'
];

export function getProcessesForAssetApi(assetId, offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/process/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              processSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? processSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((item) => ({
          ...item,
          id: item.id,
          pid: item.pid,
          status: item.state === 'S' ? 'Stopped' : 'Running',
          name: item.name,
          commandline: item.cmdline,
          path: item.path
        }))
      };
    });
}

const openSocketSortKeyMap = {};
const openSocketSearchableColumns = [
  'name',
  'family',
  'protocol',
  'local_address',
  'remote_address'
];

export function getOpenSocketsForAssetApi(assetId, offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/open/sockets/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              openSocketSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? openSocketSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((item) => ({
          ...item
        }))
      };
    });
}

const packageSearchableColumns = ['name', 'version'];

const packageSortKeyMap = {
  eosDate: 'eos_date',
  eolDate: 'eol_date',
  releaseDate: 'release_date'
};

export function getPackagesForAssetApi(assetId, source, offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/packages/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              packageSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      ...(source ? { source } : {}),
      qualification: sortFilter.searchTerm
        ? packageSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((item) => ({
          id: item.id,
          name: item.name,
          version: item.version,
          releaseDate: item.release_date,
          eolDate: item.eol_date,
          eosDate: item.eos_date,
          vulnerability: item.vulnerability_count,
          blacklist: item.blacklist === 'yes'
        }))
      };
    });
}

export function getPackagesCategoryApi(assetId) {
  return api.get(`${END_POINT}/packages/categories/${assetId}`).then(({ result }) => {
    return result;
  });
}

export function getBrowsersForAssetApi(assetId, source, offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/browsers/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              processSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      ...(source ? { source } : {}),
      qualification: sortFilter.searchTerm
        ? packageSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((item) => ({
          id: item.id,
          name: item.name,
          version: item.version,
          blacklist: item.blacklist === 'yes',
          releaseDate: item.release_date,
          eolDate: item.eol_date,
          eosDate: item.eos_date,
          vulnerability: item.vulnerability_count
        }))
      };
    });
}

export function getBrowserCategoriesApi(assetId) {
  return api.get(`${END_POINT}/browsers/categories/${assetId}`).then(({ result }) => result);
}

const certSortKeyMap = {
  commonName: 'common_name',
  notValidBefore: 'not_valid_before',
  notValidAfter: 'not_valid_after',
  signingAlgorithm: 'signing_algorithm',
  keyAlgorithm: 'key_algorithm',
  status: 'expiration',
  selfSigned: 'self_signed'
};

const certificateSearchableColumns = [
  'common_name',
  'ca',
  'self_signed',
  'signing_algorithm',
  'key_algorithm'
];

export function getCertificatesForAssetApi(assetId, status, offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/certificates/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              certSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      ...(status ? { status } : {}),
      qualification: sortFilter.searchTerm
        ? certificateSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((item) => ({
          id: generateId(),
          commonName: item.common_name,
          ca: item.ca,
          issuer: item.issuer,
          status: item.expiration,
          self_signed: item.self_signed ? true : false,
          notValidBefore: item.not_valid_before,
          notValidAfter: item.not_valid_after,
          signingAlgorithm: item.signing_algorithm,
          keyAlgorithm: item.key_algorithm
        }))
      };
    });
}

export function getCertificateCountsApi(assetId) {
  return api
    .get(`${END_POINT}/certificates/expiration/count/${assetId}`)
    .then(({ result }) => result);
}

const vulnerabilitySortKeyMap = {
  score: 'scor',
  severity: 'severity',
  cvss3BaseScore: 'cvss3_base_score',
  cvss2BaseScore: 'cvss2_base_score',
  description: 'Description',
  version: 'version'
};

const vulnerabilitySearchableColumns = [
  'cve',
  'severity',
  'name',
  'version',
  'score',
  'cvss3_base_score',
  'cvss2_base_score',
  'epss_probability'
];

export function getVulnerabilitiesForAssetApi(assetId, severity, offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/vulnerabilities/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              vulnerabilitySortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      ...(severity ? { severity } : {}),
      qualification: sortFilter.searchTerm
        ? vulnerabilitySearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((item) => ({
          ...item,
          id: generateId(),
          cve: item.cve,
          score: item.Score,
          name: item.name,
          version: item.version,
          severity: item.Severity,
          publishedDate: item.PublishedDate,
          description: item.Description,
          cvss3BaseScore: item['CVSS3_Base Score'],
          cvss2BaseScore: item['CVSS2_Base Score'],
          cvss2Severity: item['CVSS2_Severity'],
          cvss3Severity: item['CVSS3_Severity']
        }))
      };
    });
}

export function getVulnerabilitiesForSoftawreApi(assetId, offset, size, sortFilter) {
  return api
    .post(`/software/vulnerability/details/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              vulnerabilitySortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? vulnerabilitySearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((item) => ({
          ...item,
          id: generateId(),
          cve: item.cve,
          score: item.Score,
          name: item.name,
          version: item.version,
          severity: item.Severity,
          publishedDate: item.PublishedDate,
          description: item.Description,
          cvss3BaseScore: item['CVSS3_Base Score'],
          cvss2BaseScore: item['CVSS2_Base Score'],
          cvss2Severity: item['CVSS2_Severity'],
          cvss3Severity: item['CVSS3_Severity']
        }))
      };
    });
}

export function getAvailableSeverityApi(assetId) {
  return api
    .get(`/software/vulnerability/severities?id=${assetId}`)
    .then(({ result }) =>
      Object.keys(result).reduce(
        (prev, key) => ({ ...prev, [key === 'total_count' ? 'All' : key]: result[key] }),
        {}
      )
    );
}

// const complianceSortKeyMap = {
//   name: 'compliance_name',
//   testedRules: 'tested_rules',
//   passedRules: 'passed_rules'
// };

export function getComplianceForAssetApi(assetId, timeline, offset, size, sortFilter) {
  return api
    .post(
      `patch/asset/compliance/heatmap/${assetId}`,
      {
        ...(timeline ? { timeline: transformTimelineForServer(timeline) } : {})
      }
      // {
      //   timeline_context: transformTimelineForServer(timeline),
      //   offset,
      //   size,
      //   ...(sortFilter && sortFilter.sort && sortFilter.sort.field
      //     ? {
      //         sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
      //           complianceSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
      //         }`
      //       }
      //     : {})
      // }
    )
    .then((data) => {
      let bundles = [];
      Object.keys(data).forEach((deploymentId) => {
        let deployment_metadata = data[deploymentId].metadata;
        Object.keys(data[deploymentId]).forEach((frameworkId) => {
          let frameworkMetadata = data[deploymentId][frameworkId].metadata;
          if (frameworkId !== 'metadata') {
            let rules = [];
            let testedRules = 0;
            let passedRules = 0;
            Object.keys(data[deploymentId][frameworkId]).forEach((ruleId) => {
              if (ruleId !== 'metadata') {
                testedRules++;
                if (data[deploymentId][frameworkId][ruleId].status === 'success') {
                  passedRules++;
                }
                let ruleMetadata = data[deploymentId][frameworkId][ruleId].metadata;
                rules.push({
                  id: +ruleId,
                  name: ruleMetadata.name,
                  bindings: ruleMetadata.bindings,
                  description: ruleMetadata.description,
                  status: data[deploymentId][frameworkId][ruleId].status
                });
              }
            });
            bundles.push({
              id: `${deploymentId}-${frameworkId}`,
              framework_id: +frameworkId,
              deploymentId: +deploymentId,
              deployment_name: deployment_metadata.name,
              name: frameworkMetadata.name,
              description: frameworkMetadata.description,
              rules,
              testedRules,
              passedRules,
              iconFile: transformPackageIcon(frameworkMetadata)
            });
          }
        });
      });
      return bundles;
      // return {
      //   totalCount: data.totalCount,
      //   result: data.result.map((item) => ({
      //     id: generateId(),
      //     frameworkId: item.framework_id,
      //     name: item.compliance_name,
      //     testedRules: item.tested_rules,
      //     passedRules: item.passed_rules
      //   }))
      // };
    });
}

const networkSortKeyMap = {
  physicalAdapter: 'physical_adapter',
  dhcpEnabled: 'dhcp_enabled',
  dhcpServer: 'dhcp_server',
  dnsHostName: 'dns_host_name',
  pointToPoint: 'point_to_point'
};

const networkSearchableColumns = [
  'interface',
  'address',
  'mask',
  'broadcast',
  'point_to_point',
  'type',
  'mac',
  'description',
  'manufacturer',
  'dhcp_enabled',
  'dhcp_server',
  'dns_host_name',
  'gateway'
];

export function getNetworkForAssetApi(assetId, offset, size, sortFilter) {
  return api
    .post(`/inventory/asset/network/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              networkSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? networkSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((item) => ({
          id: item.id,
          interface: item.interface,
          address: item.address,
          mask: item.mask,
          manufacturer: item.manufacturer,
          enabled: item.enabled,
          physicalAdapter: item.physical_adapter,
          dhcpEnabled: item.dhcp_enabled,
          dhcpServer: item.dhcp_server,
          dnsHostName: item.dns_host_name,
          point_to_point: item.point_to_point,
          friendly_name: item.friendly_name,
          type: item.type,
          mac: item.mac,
          gateway: item.gateway
        }))
      };
    });
}

const portSearchableColumns = ['name', 'protocol', 'family', 'address', 'net_namespace', 'path'];
const portSortKeyMap = {};

export function getListeningPortsForAssetApi(assetId, offset, size, sortFilter) {
  return api
    .post(`/inventory/asset/listening/ports/${assetId}`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              portSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? portSearchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result
      };
    });
}

export function getHierarchyForInventoryApi(category) {
  return api.get(`/inventory/${category}/categories`).then(({ result }) => {
    result = Object.keys(result)
      ? result
      : {
          Linux: [],
          Mac: [],
          Windows: [],
          Linux_total_count: { total_count: 0 },
          Mac_total_count: { total_count: 0 },
          Windows_total_count: { total_count: 0 }
        };
    return [
      {
        key: 'all',
        title: 'All',
        count: Object.keys(result)
          .filter((platform) => platform.indexOf('_total_count') < 0)
          .reduce((carry, platform) => carry + result[`${platform}_total_count`].total_count, 0)
      },
      ...Object.keys(result).map((platform) => {
        if (platform.indexOf('_total_count') >= 0) {
          return null;
        }
        return {
          key: platform,
          title: platform,
          type: 'os',
          count: (result[`${platform}_total_count`] || {}).total_count || 0,
          children: (result[platform] || []).map((i) => {
            const versionKey = Object.keys(i).find((k) => k !== 'platform');
            return {
              key: versionKey,
              title: versionKey,
              type: 'version',
              platform: i.platform,
              parent: platform,
              count: i[versionKey] || 0
            };
          })
        };
      })
    ].filter(Boolean);
  });
}

export function getLocationDepartmentHierarchyForInventoryApi(platform) {
  return api
    .get(`/inventory/asset/hierarchy`, {
      params: {
        ...(platform && platform.key !== 'all'
          ? {
              ...(platform.type === 'version'
                ? {
                    version: platform.title,
                    os: platform.platform
                  }
                : {
                    os: platform.children.map((item) => item.platform).join(',')
                  })
            }
          : {})
      }
    })
    .then(({ result }) => {
      return [
        ...Object.keys(result).map((platform) => {
          return {
            key: platform,
            title: Capitalize(platform),
            type: platform,
            selectable: true,
            // count: result[platform]['All'],
            children: Object.keys(result[platform]).map((i) => {
              const splitParts = i.split('!##!');
              return {
                key: splitParts[1] || splitParts[0].toLowerCase(),
                title: splitParts[0],
                type: platform,
                [platform]: platform,
                parent: platform,
                count: result[platform][i]
              };
            })
          };
        })
      ].filter(Boolean);
    });
}

export function getAssetSummaryDetailApi(assetId) {
  return api.get(`/inventory/asset/overview/${assetId}`).then(({ result }) => {
    return {
      disk: result.disk,
      cpu: {
        logical: result.cpu.cpu_logical_cores,
        physical: result.cpu.cpu_physical_cores
      },
      ram: result.ram,
      softwares: {
        malicious: result.other['Malicious Processes'],
        vulnerable: result.other['Vulnerable Software'],
        risk_score: result.other['risk']
      }
    };
  });
}

function convertProcessListToParentChild(data) {
  const nodes = [];
  const edges = [];
  data.forEach(function collectChildren(item) {
    nodes.push({
      data: {
        id: `${item.pid}-${item.ppid}-${item.name}`,
        label: `${item.name}`,
        pid: item.pid
      }
    });
    const children = data.filter((innerItem) => innerItem.ppid === item.pid);
    children.forEach((child) => {
      edges.push({
        data: {
          source: `${item.pid}-${item.ppid}-${item.name}`,
          target: `${child.pid}-${child.ppid}-${child.name}`
        }
      });
    });
    children.forEach(collectChildren);
  });
  return {
    nodes,
    edges
  };
}

export function getProcessDependencyApi(assetId, processId) {
  return api.get(`/inventory/asset/process/tree/${assetId}/${processId}`).then((data) => {
    return convertProcessListToParentChild(data.result);
  });
}

export function getQuickCheckForHostApi(assetId, offset, size, sortFilter) {
  return api
    .get(`${END_POINT}/quick/checks/${assetId}`, {
      params: {
        offset,
        size
      }
    })
    .then((data) => {
      const resultData = (Array.isArray(data.result) ? data.result : []).map((i) => ({
        id: i.id,
        name: i.name,
        status: i.result === 1 ? 'enable' : i.result === 0 ? 'disable' : 'unknown'
      }));
      return {
        totalCount: data.result.length,
        result: sortFilter.searchTerm
          ? resultData.filter((d) =>
              d.name.toLowerCase().includes(sortFilter.searchTerm.toLowerCase())
            )
          : resultData
      };
    });
}

export function getRdpUrlForAssetApi(assetId) {
  return api.get(`${END_POINT}/rdp/${assetId}`).then(({ result }) => result);
}

export function getComplianceHeatMapForAssetApi(assetId) {
  return api.get(`${END_POINT}/compliance/chart/${assetId}`).then(({ result }) => result);
}

export function getAllInvestigateForAssetApi(assetId, offset, size, sortFilter = {}) {
  return api
    .post(`/live/investigate/search`, {
      offset,
      size,
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return data.result.map((i) => ({
        value: i.id,
        label: i.query_name,
        query: i.query
      }));
    });
}

const patchTabSearchableColumns = ['patchName', 'title'];

export function getPatchForInventoryDrilldownApi(
  assetId,
  selectedCategory,
  offset,
  size,
  sortFilter
) {
  return api
    .post(`patch/asset-patch-relation/search`, {
      offset,
      size,
      // ...(sortFilter && sortFilter.sort && sortFilter.sort.field
      //   ? {
      //       sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
      //         processSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
      //       }`
      //     }
      //   : {}),
      qualification: [
        { operator: 'equals', column: 'assetId', value: assetId, condition: 'and' },
        {
          operator: 'equals',
          column: 'patchState',
          value: selectedCategory,
          condition: 'and',
          type: 'enum',
          reference: 'patchState'
        },
        ...(sortFilter.searchTerm
          ? patchTabSearchableColumns.map((c) => ({
              operator: 'Contains',
              column: c,
              value: sortFilter.searchTerm
            }))
          : [])
      ]
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result
      };
    });
}

export function executeActionApi(action, record = {}, asset = {}) {
  const actionMap = {
    kill_process: 'KillProcess',
    block_ip: 'BlockIp',
    block_port: 'BlockPort',
    reboot: 'Reboot',
    shutdown: 'PowerOff',
    remote_wipeout: 'WipeOut'
  };
  const payload = !['reboot', 'shutdown', 'remote_wipeout'].includes(action)
    ? [
        {
          ...(action === 'kill_process'
            ? { pid: record.pid }
            : action === 'block_ip'
            ? { ipAddress: record.remote_address }
            : action === 'block_port'
            ? { pid: record.pid }
            : {})
        }
      ]
    : undefined;

  return api.post(`inventory/asset/action/execute/${asset.id}`, {
    action: actionMap[action] || action,
    ...(payload ? { remediation_action_context: payload } : {})
  });
}

export function cleanAllPatchesApi() {
  return api.delete(`/patch/delete-all/pch-relation`);
}

const actionhistorySearchColumns = ['displayName', 'taskStatus', 'actionType'];

const actionHistorySortKeyMap = {
  displayName: 'displayName',
  taskStatus: 'taskStatus',
  actionType: 'actionType'
};

const transformTask = (task) => ({
  id: task.id,
  displayName: task.displayName,
  taskStatus: task.taskStatus,
  result: atob(task.taskResult),
  createdBy: task.createdById,
  createdAt: task.createdTime,
  type: task.refModel,
  endpoint: task.agentId,
  iconFile: transformPackageIcon(task),
  actionType: task.actionType
});

export function fetchActionHistoryApi(assect, offset, size, sortFilter) {
  return api
    .post(`/patch/agent/task/search`, {
      offset,
      size,

      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              actionHistorySortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),

      qualification: [
        {
          operator: 'equals',
          column: 'agentId',
          value: assect.id,
          condition: 'and'
        },
        {
          type: 'enum',
          operator: 'equals',
          column: 'taskType',
          value: 'system_action',
          condition: 'and'
        }
      ].concat(
        sortFilter.searchTerm
          ? actionhistorySearchColumns.map((c) => ({
              operator: 'Contains',
              column: c,
              value: sortFilter.searchTerm
            }))
          : []
      )
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((i) => transformTask(i))
      };
    });
}

export function patchScanApi(assetId) {
  return api.post(`/patch/asset/execute-scan-patch/${+assetId}`);
}

export function getPatchScanHistoryApi(id) {
  return api.get(`/patch/asset/execute-scan-patch/history/${id}`).then((data) => {
    return data.result;
  });
}
