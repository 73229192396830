import { Modal, Row, Col, Form, Select } from 'antd';
import Merge from 'lodash/merge';
import { useState } from 'react';
import { getAllEnrollSecretForOrgAndDepApi } from '../../modules/settings/api/agent-management/enroll-secret';
import { Organization } from '@/src/components/pickers/OrganizationPicker';
import { Department } from '@/src/components/pickers/DepartmentPicker';
import AgentDownloadOptions from './AgentDownloadOptions';

export default function EnrollDownloadFormModal({ enrollSecretId, isModalOpen, onDone }) {
  const [enrollSecretOptions, setEnrollSecretOptions] = useState([]);
  const [form] = Form.useForm();
  const [formItem, setFormItem] = useState({
    enrollSecretId
  });

  function getEnrollOptionsFor(organization, department) {
    getAllEnrollSecretForOrgAndDepApi(organization, department).then(({ result }) =>
      setEnrollSecretOptions(result.map((i) => ({ value: i.id, label: i.name })))
    );
  }

  return (
    <Modal
      open={Boolean(isModalOpen)}
      title={enrollSecretId ? `Download Agent for Platform` : null}
      onCancel={() => onDone()}
      wrapClassName="agent-download-modal"
      width="65%"
      destroyOnClose={true}
      footer={null}>
      <Organization.Provider>
        <Department.Provider>
          <Form
            layout="vertical"
            className="h-full"
            form={form}
            onValuesChange={(values) => {
              setFormItem(Merge({ ...formItem }, { ...values }));
            }}
            initialValues={formItem}>
            <Row gutter={32} className="items-center">
              <Col span={8}>
                <Form.Item label="Organization" name="organization" rules={[{ required: true }]}>
                  <Organization.Picker
                    onChange={(organization) => {
                      form.setFieldValue('organization', organization);
                      form.setFieldValue('department', undefined);
                      form.setFieldValue('enrollSecretId', undefined);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Department" name="department" rules={[{ required: true }]}>
                  <Department.Picker
                    organization={form.getFieldValue('organization')}
                    onChange={(department) => {
                      form.setFieldValue('department', department);
                      form.setFieldValue('enrollSecretId', undefined);
                      getEnrollOptionsFor(form.getFieldValue('organization'), department);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Enroll Secret" name="enrollSecretId" rules={[{ required: true }]}>
                  <Select options={enrollSecretOptions} placeholder="Please Select" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <AgentDownloadOptions
            onDone={onDone}
            enrollSecretId={form.getFieldValue('enrollSecretId')}
          />
        </Department.Provider>
      </Organization.Provider>
    </Modal>
  );
}
