import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const AlertLayout = lazy(() => import(/* webpackChunkName: "alert" */ './layout/AlertLayout'));
const List = lazy(() => import(/* webpackChunkName: "alert" */ './views/List'));

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="alerts" key="alerts" element={<AppLayout />}>
      <Route element={<AlertLayout />}>
        {/* if no route is defined navigate to about route */}
        <Route index element={<List />}></Route>
      </Route>
    </Route>
  );
}
