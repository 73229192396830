import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@hooks/auth';

export const AuthGuard = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  if (
    !user.isValidLicense &&
    location.pathname !== '/settings/system-settings/license-management'
  ) {
    return <Navigate to="/settings/system-settings/license-management" />;
  }
  return children;
};
