import JsPdf from 'jspdf';
import 'jspdf-autotable';

const defaultPdfStyle = {
  rowPageBreak: 'auto',
  bodyStyles: { valign: 'top' },
  margin: 5,
  theme: 'grid',
  headStyles: {
    fillColor: [9, 157, 217],
    minCellWidth: 20
  }
};

function getValue(row, column, contextData, options = {}) {
  const key = column.exportKey || column.key;
  if (column.exportFormatter) {
    return column.exportFormatter(row) || '';
  } else if (column.contextKey) {
    const context = contextData[column.contextKey] || new Map();
    let values = Array.isArray(row[key]) ? row[key] : [row[key]];
    values = values
      .filter(Boolean)
      .map((i) => (context.has(i) ? context.get(i).groupName || context.get(i).name : undefined))
      .filter(Boolean)
      .join(', ');
    return values;
  } else if (row[`${key}Formatted`]) {
    return row[`${key}Formatted`];
  } else if (row[key] instanceof Date) {
    return row[key].toLocaleString();
  } else if (key === 'type' && row.isAgent) {
    return `${row[key]} (Agent)`;
  } else if (column.exportType === 'boolean') {
    return row[key] ? 'Yes' : 'No';
  }
  return (row[key] || '').toString();
}

function processCsvRow(columns, row, contextData, options) {
  let finalVal = '';
  for (let j = 0; j < columns.length; j++) {
    const column = columns[j];
    let innerValue = getValue(row, column, contextData, options);
    if (!innerValue) {
      console.log(column);
    }
    let result = innerValue.replace(/"/g, '""');
    if (result.search(/("|,|\n)/g) >= 0) {
      result = `"${result}"`;
    }
    if (j > 0) {
      finalVal += ',';
    }
    finalVal += result;
  }
  return `${finalVal}\n`;
}

export function exportAsCsv(columns, data, contextData, options) {
  return new Promise((resolve, reject) => {
    try {
      const filteredColumns = columns.filter((c) => c.export !== false && c.key !== 'actions');
      let fileContent = `${filteredColumns.map((c) => `${c.title || c.name}`).join(',')}\n`;
      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        fileContent += processCsvRow(filteredColumns, row, contextData, options);
      }
      const blob = new Blob([fileContent], { type: 'text/csv;charset=utf-8;' });
      resolve(blob);
    } catch (e) {
      reject(e);
    }
  });
}

function processPdfRow(columns, row, contextData, options) {
  const data = [];
  for (let j = 0; j < columns.length; j++) {
    const column = columns[j];
    let innerValue =
      column.key === 'alerts'
        ? (getValue(row, column, contextData, options) || '').replace(',', '\n')
        : getValue(row, column, contextData, options);
    data.push(innerValue);
  }
  return data;
}

export function exportAsPdf(columns, data, contextData, options) {
  return new Promise((resolve, reject) => {
    try {
      const filteredColumns = columns.filter((c) => c.export !== false);
      let headers = filteredColumns.map((c) => `${c.title || c.name}`);
      const rows = [];
      for (let i = 0; i < data.length; i++) {
        rows.push(processPdfRow(filteredColumns, data[i], contextData, options));
      }
      resolve({
        headers,
        rows
      });
    } catch (e) {
      reject(e);
    }
  });
}

export default async function exportData(columns, data, type = 'csv', contextData, options) {
  if (type === 'csv') {
    return exportAsCsv(columns, data, contextData, options);
  } else if (type === 'pdf') {
    const pdf = exportAsPdf(columns, data, contextData, options);
    const doc = new JsPdf('l');
    doc.autoTable({
      head: [pdf.headers],
      body: pdf.rows,
      ...defaultPdfStyle
    });
    return new Blob([doc.output()], { type: 'application/pdf' });
  }
  throw new Error('type can only be csv and pdf in export');
}
