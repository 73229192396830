import React, { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout, message, notification, Spin, ConfigProvider, theme } from 'antd';
import { AuthGuard } from '@components/AuthGuard';
import AppHeader from '@components/Header';
import Navbar from '../components/Navbar';
import Loading from '../components/Loading';
import { Socket } from '../components/SocketListener';
import { UserPreference } from '../components/UserPreference';
import { Breadcrumb } from '../components/Breadcrumb';
import { Branding } from '../components/Branding';
import { Permissions } from '../components/Permissions';
import { PageHeader } from '../components/PageHeader';
import { ProductSetup } from '../components/product-setup/ProductSetupProvider';

const { Content } = Layout;
const { darkAlgorithm } = theme;
const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

const LayoutContext = createContext();

export default function AppLayout({ children }) {
  const [myTheme, setMyTheme] = useState(null);

  const [collapsed, setCollapsed] = useState(true);
  const [autoThemeType, setAutoThemeType] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [messageApi, messageHolder] = message.useMessage();
  const [notificationApi, notificationHolder] = notification.useNotification();
  const url = `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SOCKET_URL : ''}`;

  function handleSetTheme(themeType) {
    setMyTheme(themeType);
    if (themeType === 'dark') {
      document.body.setAttribute('data-theme', 'dark-theme');
    } else if (themeType === 'light') {
      document.body.removeAttribute('data-theme');
    } else if (themeType === 'auto') {
      if (mediaQuery.matches) {
        setAutoThemeType('dark');
        document.body.setAttribute('data-theme', 'dark-theme');
      } else {
        setAutoThemeType('light');
        document.body.removeAttribute('data-theme');
      }
      const listener = (e) => {
        if (e.matches) {
          setAutoThemeType('dark');
          document.body.setAttribute('data-theme', 'dark-theme');
        } else {
          setAutoThemeType('light');
          document.body.removeAttribute('data-theme');
        }
      };
      mediaQuery.addEventListener('change', listener);
    }
  }

  return (
    <AuthGuard>
      <LayoutContext.Provider value={{ notification: notificationApi, message: messageApi }}>
        <Permissions.Provider>
          <ConfigProvider
            componentSize={'small'}
            theme={{
              ...(myTheme === 'dark' || (myTheme === 'auto' && autoThemeType === 'dark')
                ? { algorithm: darkAlgorithm }
                : {})
            }}>
            <UserPreference.Provider
              onDataReceived={(preference) =>
                handleSetTheme(preference ? preference.theme || autoThemeType : autoThemeType)
              }>
              <Branding.Provider>
                <ProductSetup.Provider>
                  <Breadcrumb.Provider>
                    <Layout>
                      <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />
                      <PageHeader.Provider>
                        <Layout className="bg-page-background">
                          <AppHeader
                            theme={myTheme}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                            handleThemeChange={handleSetTheme}
                            className="mx-4 border-solid border-t-0 border-x-0 border-b border-border"
                          />
                          <Content className="mx-4 flex flex-col">
                            <Socket.Provider
                              url={`${url}/eventbus`}
                              onDisconnect={() => {
                                // messageApi.open({
                                //   key: 'socket-message',
                                //   type: 'error',
                                //   content: 'Unable to connect to socket for live update!',
                                //   duration: 0
                                // });
                              }}
                              onConnect={() => {
                                messageApi.destroy('socket-message');
                                setLoading(false);
                              }}>
                              <React.Suspense fallback={<Loading />}>
                                {loading ? (
                                  <div className="flex-1 flex items-center justify-center h-full">
                                    <Spin spinning />
                                  </div>
                                ) : (
                                  <Outlet />
                                )}
                                {children}
                              </React.Suspense>
                            </Socket.Provider>
                          </Content>
                        </Layout>
                      </PageHeader.Provider>
                      {messageHolder}
                      {notificationHolder}
                    </Layout>
                  </Breadcrumb.Provider>
                </ProductSetup.Provider>
              </Branding.Provider>
            </UserPreference.Provider>
          </ConfigProvider>
        </Permissions.Provider>
      </LayoutContext.Provider>
    </AuthGuard>
  );
}

export function useLayout() {
  return useContext(LayoutContext);
}
