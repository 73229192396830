import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function NotFound({ redirectTo }) {
  const navigate = useNavigate();

  function onClick() {
    navigate(redirectTo || '/dashboard', {
      replace: true
    });
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={onClick}>
          Back Home
        </Button>
      }
    />
  );
}
