import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function SuccessStep({ onDone }) {
  const navigate = useNavigate();
  function navigateToEndpoints() {
    onDone();
    navigate('/inventory/endpoints');
  }

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="text-5xl text-success">
          <CheckCircleOutlined />
        </div>
        <h2 className="text-center">
          You have successfully configured product with basic required configuration!
        </h2>
        <div className="my-4">
          <Button
            size="medium"
            className="gradient-btn"
            type="primary"
            onClick={navigateToEndpoints}>
            Check Enrollment Status
          </Button>
        </div>
      </div>
    </div>
  );
}
