import bytesPerSecToSize, { bytesToSize } from './bytes';
import Duration from './duration';
import hz from './hz';

const statusMap = {
  1: 'Up',
  2: 'Down',
  3: 'Suspend',
  4: 'Unknown',
  5: 'Maintenance',
  6: 'Disable',
  7: 'Unreachable'
};

export default function applyUnit(counter, value) {
  if (String(value).length === 0) {
    return value;
  }
  if (/\.status(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return statusMap[value] || value;
  }
  if (/\.(?:percent(?:age)?)(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    if (value !== null && value !== undefined) {
      if (/\d+\.\d+/.test(String(value))) {
        return `${parseFloat(value).toFixed(2)}%`;
      }
      return `${parseInt(value)}%`;
    }
    return value;
  } else if (/\.dbm(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return `${value || 0} dBm`;
  } else if (/\.hz(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return hz(value, undefined);
  } else if (/\.(?:bytes\.per\.sec)(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return bytesPerSecToSize(value, undefined);
  } else if (/\.bytes(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return bytesToSize(value, undefined);
  } else if (
    /\.(?:ms|time|duration|sec|seconds)(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter) &&
    typeof value !== 'string'
  ) {
    // if per.sec kind of counter then we don't convert value to sec
    if (/per\.(?:ms|time|duration|sec|seconds)/.test(counter)) {
      return value;
    }
    const initialUnit = /\.ms(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)
      ? 'milliseconds'
      : undefined;
    return Duration(
      value,
      initialUnit,
      /\.ms(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)
        ? false
        : /\.(?:duration|time)(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter),
      undefined,
      /\.ms(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)
    );
  }
  return value;
}
