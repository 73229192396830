import api from '@api';
import { transformTimelineForServer } from '../../dashboard/widget-api';

const END_POINT = `/fim/events`;

const sortKeyMap = {
  createdAt: 'created_time',
  asset: 'host_name'
};

const searchableColumns = [
  'host_name',
  'target_path',
  'action',
  'category',
  'mode',
  'md5',
  'sha1',
  'sha256',
  'username'
];

const windowsSearchableColumns = [
  'host_name',
  'action',
  'event_id',
  'username',
  'md5',
  'process_guid',
  'process_name',
  'sha256',
  'target_path'
];

export function getCategoriesApi(timeline) {
  return api
    .post(`${END_POINT}/categories`, {
      ...((timeline || {}).selected
        ? {
            timeline: transformTimelineForServer(timeline)
          }
        : {})
    })
    .then(({ result }) => {
      result.All = Object.keys(result).reduce((prev, item) => prev + result[item] || 0, 0);
      return ['Windows', 'Linux', 'Mac'].reduce(
        (prev, item) => ({ ...prev, [item]: result[item] || 0 }),
        {}
      );
    });
}

export function getAllEventsApi(offset, size, sortFilter = {}, { type, timeline }) {
  return api
    .post(`${END_POINT}`, {
      offset,
      size,
      ...((timeline || {}).selected
        ? {
            timeline: transformTimelineForServer(timeline)
          }
        : {}),
      ...(type && type !== 'All' ? { type } : {}),
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? ((type || '').toLowerCase() === 'windows'
            ? windowsSearchableColumns
            : searchableColumns
          ).map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result
      };
    });
}

export function getFimEventChartApi() {
  return api.get(`${END_POINT}/chart`).then(({ result }) => {
    const colors = ['#89c540', '#3279be', '#f5bc18'];
    return {
      series: Object.keys(result[0])
        .filter((i) => i !== 'Event Date')
        .map((series, index) => ({
          name: series,
          color: colors[index],
          data: result.map((i) => i[series])
        })),
      categories: result.map((i) => i['Event Date'])
    };
  });
}

export function getFimEventChartWithCategoryApi(platform, timeline) {
  return api
    .post(`${END_POINT}/action/chart`, {
      type: platform,
      ...(timeline ? { timeline: transformTimelineForServer(timeline) } : {})
    })
    .then(({ result }) => {
      return {
        series: [
          {
            name: 'FIM Events',
            data: Object.keys(result).map((key) => result[key])
          }
        ],
        categories: Object.keys(result)
      };
    });
}
