import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';

const InvestigateLayout = lazy(() =>
  import(/* webpackChunkName: "investigate" */ './layout/InvestigateLayout')
);
const Create = lazy(() =>
  import(/* webpackChunkName: "investigate" */ './views/CreateInvestigate')
);
const Edit = lazy(() => import(/* webpackChunkName: "investigate" */ './views/EditInvestigate'));
/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="investigate" key="investigate" element={<AppLayout />}>
      <Route element={<InvestigateLayout />}>
        {/* if no route is defined navigate to about route */}
        <Route index element={<Navigate replace to="create" />}></Route>
        <Route path="create" element={<Create />}></Route>
        <Route path=":id/edit" element={<Edit />}></Route>
        <Route path=":id/view" element={<Edit disabled />}></Route>
      </Route>
    </Route>
  );
}
