import api from '@api';
import Moment from 'moment';
import dayjs from 'dayjs';

const END_POINT = `/patch/policy`;

export const REFMODEL = [
  'all',
  'package',
  'configuration',
  'patch',
  'system_actions',
  'compliances'
].reduce((acc, o) => ({ ...acc, [o.toUpperCase()]: o }), {});

export const refModelOptions = [
  { label: 'All', value: REFMODEL.ALL },
  { label: 'Package', value: REFMODEL.PACKAGE },
  { label: 'Configuration', value: REFMODEL.CONFIGURATION },
  { label: 'Patch', value: REFMODEL.PATCH },
  { label: 'System Actions', value: REFMODEL.SYSTEM_ACTIONS },
  { label: 'Compliances', value: REFMODEL.COMPLIANCES }
];

const convertToDurationForServer = (time) => {
  const ms = Moment.duration(time.format('HH:mm'));
  return ms.asMilliseconds();
};

const convertToDayJs = (time) => {
  const ms = Moment.utc(time).format('HH:mm:ss');
  return dayjs(ms, 'HH:mm:ss');
};
const convertToTime = (time, name) => {
  const ms = Moment.utc(time).format('HH:mm:ss');
  return ms;
};

export const transform = (item) => ({
  id: item.id,
  name: item.name,
  displayName: item.displayName,
  description: item.description,
  type: item.type,
  initiateDeploymentOn: item.InitiateDeploymentOn,
  ...(item.InitiateDeploymentOn === 'recurring'
    ? {
        executionInterval: {
          value: item.afterEveryTime,
          unit: item.afterEveryTimeUnit
        }
      }
    : {
        deploymentDays: item.deploymentDays,
        deploymentTimeFrom: item.deploymentTimeFrom
          ? convertToDayJs(item.deploymentTimeFrom)
          : undefined,

        deploymentTimeFromFormated: item.deploymentTimeFrom
          ? convertToTime(item.deploymentTimeFrom, item.displayName)
          : undefined,

        deploymentTimeTo: item.deploymentTimeTo ? convertToDayJs(item.deploymentTimeTo) : undefined,

        deploymentTimeToFormated: item.deploymentTimeTo
          ? convertToTime(item.deploymentTimeTo, item.displayName)
          : undefined
      }),
  createdBy: item.createdById,
  createdAt: item.createdTime,
  restartType: item.restartType,
  refModel: item.refModel
});

const transformForServer = async (item) => {
  return Promise.resolve({
    id: item.id,
    displayName: item.displayName,
    description: item.description,
    type: item.type,
    initiateDeploymentOn: item.initiateDeploymentOn,
    restartType: item.restartType,

    refModel: item.refModel,

    ...(item.initiateDeploymentOn === 'recurring'
      ? {
          afterEveryTime: item.executionInterval.value,
          afterEveryTimeUnit: item.executionInterval.unit
        }
      : {
          deploymentDays: item.deploymentDays,
          deploymentTimeFrom: item.deploymentTimeFrom
            ? convertToDurationForServer(item.deploymentTimeFrom)
            : undefined,
          deploymentTimeTo: item.deploymentTimeTo
            ? convertToDurationForServer(item.deploymentTimeTo)
            : undefined
        })
  });
};

const sortKeyMap = {
  createdAt: 'createdTime'
};

const searchableColumns = ['name', 'displayName', 'description'];

export function getAllDeploymentPoliciesApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map(transform)
      };
    });
}

export function getDeploymentPolicyApi(id) {
  return api.get(`${END_POINT}/${id}`).then((result) => transform(result));
}

export function updateDeploymentPolicyApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${END_POINT}/${item.id}`, data);
    })
    .then((data) => getDeploymentPolicyApi(data.result));
}

export function createDeploymentPolicyApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getDeploymentPolicyApi(data.result));
}

export function deleteDeploymentPolicyApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}
