const theme = {
  colorTextDisabled: 'var(--page-text-color)',
  colorBorder: 'var(--border-color)',
  fontSize: 12,
  borderRadius: 8,
  colorPrimary: '#3584DC',
  colorSuccess: '#89c540',
  colorWarning: '#f5bc18',
  colorError: '#f04e3e',
  colorInfo: '#3279be',
  colorBgContainerDisabled: 'transparent',
  fontFamily: '"Nunito", sans-serif',
  paddingXS: 4,
  colorText: 'var(--page-text-color)'
  // colorTextBase: '#7B8FA5',
};

export const severityColors = {
  low: '#89c540',
  critical: '#f04e3e',
  high: '#f58518',
  medium: '#f5bc18'
};

export const components = {};

export default theme;
