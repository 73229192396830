import PackageUploader from '@/src/components/PackageUploader';
import { ComplianceRule } from '@/src/components/pickers/ComplianceRulePicker';
import { Configuration } from '@/src/components/pickers/ConfigurationPicker';
import { Package } from '@/src/components/pickers/PackagePicker';
import PlatformPicker from '@/src/components/pickers/PlatformPicker';
import { Form, Input, Row, Col, Select } from 'antd';

export default function BundleForm({ item = {} }) {
  // const bundleTypeOptions = [
  //   { label: 'Software', value: 'package' },
  //   { label: 'Configuration', value: 'configuration' }
  // ];

  const typeOptions = ['CIS', 'HIPPA', 'PCI', 'FIMA', 'ISO', 'RBI', 'SOC-2'].map((i) => ({
    label: i,
    value: i.toLowerCase()
  }));

  const form = Form.useFormInstance();

  const isCompliance = form.getFieldValue('type') === 'compliances';
  const isPackage = form.getFieldValue('type') === 'package';
  const isConfiguration = form.getFieldValue('type') === 'configuration';

  return (
    <div className="flex flex-col min-h-0 h-full overflow-y-auto overflow-x-hidden">
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Bundle Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        {isCompliance ? (
          <>
            <Col span={12}>
              <Form.Item label="Icon File" name="iconFile">
                <PackageUploader accept="image/*" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Compliance Type" name="complianceType" rules={[{ required: true }]}>
                <Select placeholder="Please Select" options={typeOptions} />
              </Form.Item>
            </Col>
          </>
        ) : null}
        {/* <Col span={6}>
          <Form.Item label="Bundle Type" name="type" rules={[{ required: true }]}>
            <Radio.Group
              options={bundleTypeOptions}
              optionType="button"
              disabled
              buttonStyle="solid"
            />
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <Form.Item label="OS" name="os" rules={[{ required: true }]}>
            <PlatformPicker
              disabled={item.id}
              placeholder="Please select"
              onChange={(value) => {
                form.setFieldValue('os', value);
                form.setFieldValue('referenceIds', undefined);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={
              isPackage ? 'Applications' : isConfiguration ? 'Configurations' : 'Compliance Rules'
            }
            name="referenceIds"
            rules={[{ required: true }]}
            valuePropName="targetKeys">
            {isPackage ? (
              <Package.Picker />
            ) : isConfiguration ? (
              <Configuration.Picker />
            ) : (
              <ComplianceRule.Picker />
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input.TextArea placeholder="Description" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
