import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import Icon from './Icon';

export default function BulkDeleteButton({
  selectedItems = [],
  children,
  resourceTitle,
  onConfirm,
  ...props
}) {
  const [modal, contextHolder] = Modal.useModal();

  const showModalForBulkDelete = () => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${selectedItems.length} ${resourceTitle}?`,
      okText: 'Yes',
      cancelText: 'Cancel',
      centered: true,
      confirmLoading: true,
      destroyOnClose: true,
      maskClosable: false,
      okType: 'danger',
      onOk() {
        onConfirm(selectedItems);
      }
    });
  };

  return selectedItems.length > 0 ? (
    <>
      <Button
        {...props}
        danger
        htmlType="button"
        onClick={() => showModalForBulkDelete(selectedItems)}>
        {children || (
          <>
            {/* <Icon name="delete" className="text-danger mr-1" /> */}
            Delete selected
          </>
        )}
      </Button>
      {contextHolder}
    </>
  ) : null;
}
