import {
  transformAssetScope,
  transformAssetScopeForServer
} from '@/src/components/pickers/AssetScopePicker';
import api from '@api';
import Merge from 'lodash/merge';

const END_POINT = `/analytics/widget`;

export const transformTimeline = (timeline) => {
  return {
    selected: timeline.timeline || 'Today',
    from: timeline.from,
    to: timeline.to
  };
};

export const transformTimelineForServer = (timeline) => {
  return {
    timeline: timeline?.selected,
    ...(timeline?.selected === 'custom'
      ? {
          from: timeline.from,
          to: timeline.to
        }
      : {})
  };
};

const transformContext = (context) => ({
  query: context.query,
  timeline: transformTimeline(context.timeline_context || {}),
  type: context.type,
  xAxis: context['x-axis'],
  yAxis: context['y-axis'],
  widgetDataFetchingType: 'query_based',
  widgetLiveOrOffline: 'Offline'
});

const transformContextForServer = (item) => ({
  query: item.query,
  timeline_context: item.timeline ? transformTimelineForServer(item.timeline) : {},
  type: item.type,
  'x-axis': item.xAxis,
  'y-axis': item.yAxis
});

export const transformWidget = (item) => ({
  id: item.id,
  name: item.widget_name,
  description: item.description || 'Some description here',
  ...transformContext(item.widget_context),
  ...transformAssetScope(item),
  widgetProperties: Merge({ opacity: 0.7 }, item.widget_property || {}),
  createdAt: item.created_time
});

export const transformWidgetForServer = (item) => {
  return {
    id: item.id,
    widget_name: item.name,
    description: item.description,
    widget_context: transformContextForServer(item),
    widget_property: item.widgetProperties || {},
    ...transformAssetScopeForServer(item)
  };
};

const searchableColumns = ['widget_name', 'description'];

export function getAllWidgetsApi(offset, size, sortFilter = {}) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transformWidget)
      };
    });
}

export function getWidgetApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transformWidget(result));
}

export function updateWidget(item) {
  return api
    .put(`${END_POINT}/${item.id}`, transformWidgetForServer(item))
    .then((data) => getWidgetApi(data.result));
}

export function createWidget(item) {
  return api
    .post(`${END_POINT}`, transformWidgetForServer(item))
    .then((data) => getWidgetApi(data.result));
}

export function deleteWidget(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}
