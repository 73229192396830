import { Spin, Button } from 'antd';
import { useEffect, useState } from 'react';
import { downloadFile } from '@/src/utils/download';
import { downloadAgentApi } from '@/src/modules/settings/api/agent-management/enroll-secret';
import Icon from '../Icon';

export default function AgentDownloadOptions({ enrollSecretId, onDone }) {
  const [fetchingDownload, setFetchingDownload] = useState(false);

  useEffect(() => {
    if (enrollSecretId) {
      setFetchingDownload(false);
    }
  }, [enrollSecretId]);

  function handleDownloadFor(platform, architecture, type) {
    setFetchingDownload(true);
    downloadAgentApi(enrollSecretId, platform, architecture, type)
      .then((response) => {
        downloadFile(
          response.data,
          undefined,
          response.headers.get('content-disposition').split('=')[1]
        );
        onDone();
      })
      .finally(() => {
        setFetchingDownload(false);
      });
  }
  return (
    <Spin spinning={fetchingDownload} tip="Preparing Agent...">
      <div className="flex flex-col my-4">
        <div className="flex justify-around flex-1">
          <div
            title="Download for Windows"
            className="flex-1 flex flex-col items-center justify-between px-8 py-4 rounded-lg border-solid border-border mx-4">
            <div className="h-2/3 flex flex-col items-center justify-center flex-shrink-0">
              <div className="inline-flex items-center justify-center ">
                <Icon name={'windows'} className="text-8xl" />
              </div>
              <h4 className="text-2xl font-semibold mb-0">Windows</h4>
            </div>
            <div className="w-full">
              <table className="w-full">
                <tr>
                  <td align="center" className="font-semibold">
                    x64
                  </td>
                </tr>
                {enrollSecretId && (
                  <tr>
                    <td align="center">
                      <Button
                        type="primary"
                        className="gradient-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownloadFor('windows', 'x64');
                        }}>
                        Download
                      </Button>
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
          <div
            title="Download for Linux"
            className="flex-1 flex flex-col items-center justify-between px-8 py-4 rounded-lg border-solid border-border mx-4">
            <div className="h-2/3 flex flex-col items-center justify-center flex-shrink-0">
              <div className="inline-flex items-center justify-center ">
                <Icon name={'linux-flat'} className="text-8xl" />
              </div>
              <h4 className="text-2xl font-semibold mb-0">Linux</h4>
            </div>
            <div className="w-full">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td align="center" className="font-semibold">
                      x64
                    </td>
                  </tr>
                  <tr>
                    <td align="center" className="font-semibold">
                      {enrollSecretId && (
                        <Button
                          type="primary"
                          size="small"
                          className="gradient-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownloadFor('linux', 'x64', 'deb');
                          }}>
                          Download
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            title="Download for macOS"
            className="flex-1 flex flex-col items-center justify-between px-8 py-4 rounded-lg border-solid border-border mx-4">
            <div className="h-2/3 flex flex-col items-center justify-center flex-shrink-0">
              <div className="inline-flex items-center justify-center ">
                <Icon name={'macos'} className="text-8xl" />
              </div>
              <h4 className="text-2xl font-semibold mb-0">macOS</h4>
            </div>
            <div className="w-full">
              <table className="w-full">
                <tr>
                  <td align="center" className="font-semibold">
                    x64
                  </td>
                </tr>
                {enrollSecretId && (
                  <tr>
                    <td align="center">
                      <Button
                        type="primary"
                        className="gradient-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownloadFor('mac');
                        }}>
                        Download
                      </Button>
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="m-4">
          <h2>How to install agent?</h2>
          <ul className="p-0 list-inside list-decimal">
            <li>Download Appropriate Installer</li>
            <li>Unzip file in remote endpoints</li>
            <li>
              If it&apos;s linux or mac endpoint, change install.sh file permission to a+x using
              chmod a+x install.sh
            </li>
            <li>
              If it&apos;s linux or mac endpoint, run install.sh with sudo using sudo ./install.sh
            </li>
            <li>
              If it&apos;s Windows endpoint, right click on install.bat file and select{' '}
              <b>Run As Administrator</b>
            </li>
            <li>Check Endpoints Enrolment status under Inventory &gt; Endpoints</li>
          </ul>
        </div>
      </div>
    </Spin>
  );
}
