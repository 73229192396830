import { Modal, Button, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Branding } from '../Branding';
import { useAuth } from '@/src/hooks/auth';
import { ProductSetup } from './ProductSetupProvider';

export default function ProductSetupStepContainer({ children, open, onClose }) {
  const { branding } = Branding.useBranding();
  const { token } = useAuth();
  const { currentSection } = ProductSetup.setup();

  return (
    <Modal
      open={open}
      width={currentSection && currentSection.width ? currentSection.width : undefined}
      header={null}
      footer={null}
      maskClosable={false}
      wrapClassName="product-setup-modal"
      closeIcon={null}>
      <div className="flex justify-between items-center">
        <div className="flex-1 flex flex-col justify-between items-center">
          <img
            style={{ width: '140px' }}
            src={
              branding.ref
                ? `/api/download?id=${
                    branding.ref || branding.response
                    // eslint-disable-next-line
                  }&mid=${token.access_token}`
                : '/images/logo.svg'
            }
            alt="logo"
          />
          <span className="font-semibold text-base">
            Get Started with initial product configuration
          </span>
        </div>
        <Button size="large" type="transparent" onClick={onClose}>
          <CloseOutlined className="text-neutral-light" />
        </Button>
      </div>
      <Divider />
      {children}
    </Modal>
  );
}
