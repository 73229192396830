import CamelCase from 'lodash/camelCase';
import SnakeCase from 'lodash/snakeCase';
import Trim from 'lodash/trim';

export function transformParamNames(obj) {
  if ((!Array.isArray(obj) && typeof obj !== 'object') || obj === null) return obj;
  return Object.keys(obj).reduce(
    function (acc, key) {
      acc[CamelCase(key.replace(/\./g, '_'))] =
        typeof obj[key] === 'string' ? obj[key] : transformParamNames(obj[key]);
      return acc;
    },
    Array.isArray(obj) ? [] : {}
  );
}

export function transformParamNamesReverse(obj) {
  if ((!Array.isArray(obj) && typeof obj !== 'object') || obj === null) return obj;
  return Object.keys(obj).reduce(
    function (acc, key) {
      acc[SnakeCase(key).replace(/_/g, '.')] =
        typeof obj[key] === 'string' ? obj[key] : transformParamNames(obj[key]);
      return acc;
    },
    Array.isArray(obj) ? [] : {}
  );
}

export function trimParams(obj) {
  if ((!Array.isArray(obj) && typeof obj !== 'object') || obj === null) return obj;
  return Object.keys(obj).reduce(
    function (acc, key) {
      acc[Trim(key)] = typeof obj[key] === 'string' ? Trim(obj[key]) : trimParams(obj[key]);
      return acc;
    },
    Array.isArray(obj) ? [] : {}
  );
}
