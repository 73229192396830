import { Row, Col, Button } from 'antd';
import Uploader from '@/src/components/Uploader';
import { useAuth } from '@/src/hooks/auth';
import { useEffect, useState } from 'react';
import { Branding } from '@/src/components/Branding';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';

export default function BrandingPage({ onDone }) {
  const { branding, updateBranding } = Branding.useBranding();
  const [logo, setLogo] = useState(branding);
  const [processing, setProcessing] = useState(false);

  const { token } = useAuth();

  useEffect(() => {
    setLogo(branding);
  }, [branding]);

  function handleUpdateBranding() {
    setProcessing(true);
    updateBranding(logo)
      .then(() => {
        if (onDone) {
          onDone();
        }
      })
      .finally(() => setProcessing(false));
  }

  function resetToDefault() {
    setProcessing(true);
    updateBranding({})
      .then(() => {})
      .finally(() => setProcessing(false));
  }

  return (
    <div className="flex flex-col h-full min-h-0 mx-4 w-full">
      <Row>
        <Col span={24} className="my-2 flex flex-col">
          <div className="flex flex-col my-2">
            <span className="font-semibold">Preview</span>
            {logo && logo.ref ? (
              <>
                <img
                  // eslint-disable-next-line
                  src={`/api/download?id=${
                    logo.ref || logo.response
                    // eslint-disable-next-line
                  }&mid=${token.access_token}`}
                  style={{ height: '100px', width: '200px' }}
                  alt="logo"
                />
                <div className="mt-2">
                  <Button danger onClick={resetToDefault}>
                    Set to default
                  </Button>
                </div>
              </>
            ) : (
              <img
                // eslint-disable-next-line
                src={`/images/logo.svg`}
                style={{ height: '100px', width: '200px' }}
                alt="logo"
              />
            )}
          </div>
          <PermissionChecker permission={constants.Update_Settings}>
            <Uploader
              message="Please select logo image"
              onChange={(item) => setLogo(item[0].response)}
            />
          </PermissionChecker>
        </Col>
      </Row>
      <PermissionChecker permission={constants.Update_Settings}>
        <Row>
          <Col span={24} className="text-right mt-8">
            <Button loading={processing} type="primary" onClick={handleUpdateBranding}>
              Update
            </Button>
          </Col>
        </Row>
      </PermissionChecker>
    </div>
  );
}
