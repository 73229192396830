import { Select } from 'antd';
import Icon from '../Icon';

export default function PlatformPicker(props) {
  const osOptions = ['Windows', 'Linux', 'macOS'].map((platform) => ({
    label: (
      <div className="flex items-center">
        <Icon
          name={`platform_${platform.toLowerCase()}`}
          title={platform}
          className="text-lg mr-2"
        />
        {platform}
      </div>
    ),
    filterLabel: platform,
    value: platform === 'macOS' ? 'mac' : platform.toLowerCase()
  }));

  return <Select options={osOptions} placeholder="Please select" {...props} />;
}
