import api from '@api';

const END_POINT = `/settings/department`;

const transform = (item) => ({
  id: item.id,
  name: item.department_name,
  description: item.description,
  organization: +item.organization,
  createdAt: item.created_time
});

const transformForServer = (item) => ({
  ...(item.id ? { id: item.id } : {}),
  department_name: item.name,
  description: item.description,
  organization: item.organization
});

const sortKeyMap = {
  name: 'department_name',
  createdAt: 'created_time'
};

const searchableColumns = ['department_name', 'description'];

export function getAllDepartmentApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getDepartmentApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function updateDepartment(item) {
  return api
    .put(`${END_POINT}/${item.id}`, transformForServer(item))
    .then(() => getDepartmentApi(item.id));
}

export function createDepartment(item) {
  return api
    .post(`${END_POINT}`, transformForServer(item))
    .then((data) => getDepartmentApi(data.result));
}

export function deleteDepartment(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}
