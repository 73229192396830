import CloneDeep from 'lodash/cloneDeep';
import { Button, Form } from 'antd';
import Icon from './Icon';

export default function Repeater({
  name,
  rules,
  itemLabel,
  children,
  defaultItem,
  maxItems,
  addBtnText,
  addSectionRender,
  canAdd = true,
  ...props
}) {
  // function addItem() {
  //   if (maxItems && (value || []).length >= maxItems) {
  //     return;
  //   }
  //   const item = { ...CloneDeep(defaultItem || {}), key: generateId() };
  //   onChange([...(value || []), item]);
  // }

  // function removeItem(item) {
  //   const index = FindIndex(value, { key: item.key });
  //   if (index !== -1) {
  //     onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  //   }
  // }

  // function handleChange(item) {
  //   const index = FindIndex(value, { key: item.key });
  //   if (index !== -1) {
  //     onChange([...value.slice(0, index), { ...value[index], ...item }, ...value.slice(index + 1)]);
  //   }
  // }

  // if (!children) {
  //   return <div>Please provider render prop as single item</div>;
  // }

  return (
    <Form.List name={name} rules={rules} {...props}>
      {(fields, { add, remove, move }, { errors }) => (
        <>
          {fields.map((field, index) =>
            children({ ...field, index }, { add, remove, move, length: fields.length })
          )}
          {/* {!maxItems ||
            (maxItems && (props.value || []).length < maxItems && ( */}
          {canAdd && (
            <Form.Item className="mt-2">
              {props.disabled ? null : addSectionRender ? (
                addSectionRender(add)
              ) : (
                <div>
                  <Button onClick={() => add(CloneDeep(defaultItem))}>
                    <Icon name="add" /> {addBtnText || 'Add'}
                  </Button>
                </div>
              )}
              <Form.ErrorList errors={errors} />
            </Form.Item>
          )}
          {/* ))} */}
        </>
      )}
    </Form.List>
    // <div className="flex flex-col">
    //   {value.map((item) =>
    //     children({
    //       item,
    //       onChange: handleChange,
    //       add: addItem,
    //       remove: () => removeItem(item),
    //       canAdd: maxItems && (value || []).length >= maxItems
    //     })
    //   )}
    //   {(value || []).length === 0 &&
    //     (addSectionRender ? (
    //       addSectionRender(addItem)
    //     ) : (
    //       <div>
    //         <Button outline onClick={addItem}>
    //           <Icon name="add" /> {addBtnText || 'Add'}
    //         </Button>
    //       </div>
    //     ))}
    // </div>
  );
}
