import momentTz from 'moment-timezone';
import { Button } from 'antd';
import FindIndex from 'lodash/findIndex';
import ProductSetupStep from './ProductSetupStep';
import WelcomeStep from './WelcomeStep';
import BrandingPage from '@/src/modules/settings/views/system-settings/Branding';
import MailServerConfig from '@/src/modules/settings/views/system-settings/MailServerConfig';
import ProductSetupStepForm from './ProductSetupStepForm';
import OrganizationForm from '@/src/modules/settings/components/user-management/OrganizationForm';
import { createOrganization } from '@/src/modules/settings/api/user-management/organization';
import DepartmentForm from '@/src/modules/settings/components/user-management/DepartmentForm';
import { Organization } from '../pickers/OrganizationPicker';
import { createDepartment } from '@/src/modules/settings/api/user-management/department';
import UserForm from '@/src/modules/settings/components/user-management/UserForm';
import { Department } from '../pickers/DepartmentPicker';
import { Role } from '../pickers/RolePicker';
import { createUser } from '@/src/modules/settings/api/user-management/users';
import EnrollSecretForm from '@/src/modules/settings/components/agent-management/EnrollSecretForm';
import { createEnrollSecretApi } from '@/src/modules/settings/api/agent-management/enroll-secret';
import SuccessStep from './SuccessStep';
import AgentDownloadOptions from '../enroll/AgentDownloadOptions';

const sections = [
  {
    id: 'welcome',
    title: '',
    excluded: true,
    component: (props) => (
      <ProductSetupStep {...props}>
        <WelcomeStep />
      </ProductSetupStep>
    )
  },
  {
    id: 1,
    title: 'Update Branding Logo',
    children: [],
    component: (props) => (
      <div className="mb-4 -mx-4">
        <ProductSetupStep {...props}>
          <BrandingPage />
        </ProductSetupStep>
      </div>
    )
  },
  {
    id: 2,
    title: 'Configure Email Server',
    children: [],
    width: '70%',
    component: (props) => (
      <ProductSetupStep {...props}>
        <MailServerConfig />
      </ProductSetupStep>
    )
  },
  {
    id: 3,
    title: 'Create Organization',
    children: [],
    submitFormData(values) {
      return createOrganization(values);
    },
    component: (props) => (
      <ProductSetupStep {...props}>
        <ProductSetupStepForm>
          <OrganizationForm />
        </ProductSetupStepForm>
      </ProductSetupStep>
    )
  },
  {
    id: 4,
    title: 'Create Department',
    children: [],
    submitFormData(values) {
      return createDepartment(values);
    },
    component: (props) => (
      <ProductSetupStep {...props}>
        <ProductSetupStepForm>
          <Organization.Provider>
            <DepartmentForm />
          </Organization.Provider>
        </ProductSetupStepForm>
      </ProductSetupStep>
    )
  },
  {
    id: 5,
    title: 'Create User',
    children: [],
    width: '50%',
    submitFormData(values) {
      return createUser(values);
    },
    defaultFormItem: {
      status: true,
      timezone: momentTz.tz.guess()
    },
    component: (props) => (
      <ProductSetupStep {...props}>
        <ProductSetupStepForm>
          <Department.Provider>
            <Role.Provider>
              <UserForm />
            </Role.Provider>
          </Department.Provider>
        </ProductSetupStepForm>
      </ProductSetupStep>
    )
  },
  {
    id: 6,
    title: 'Create Endpoint Secret',
    children: [],
    submitFormData(values) {
      return createEnrollSecretApi(values).then((data) => {
        window.localStorage.setItem('created_enroll_secret_id', data.id);
      });
    },
    component: (props) => (
      <ProductSetupStep {...props}>
        <ProductSetupStepForm>
          <Department.Provider>
            <Organization.Provider>
              <EnrollSecretForm />
            </Organization.Provider>
          </Department.Provider>
        </ProductSetupStepForm>
      </ProductSetupStep>
    )
  },
  // {
  //   id: 7,
  //   title: 'Upload Certificate',
  //   children: [],
  //   component: (props) => (
  //     <ProductSetupStep {...props}>
  //       <h1>Upload Certificate Step</h1>
  //     </ProductSetupStep>
  //   )
  // },
  {
    id: 8,
    title: 'Enroll First Endpoint',
    children: [],
    width: '65%',
    isEnabled() {
      return Boolean(window.localStorage.getItem('created_enroll_secret_id'));
    },
    initialize() {
      return { enrollSecretId: +window.localStorage.getItem('created_enroll_secret_id') };
    },
    component: (props) => (
      <ProductSetupStep {...props}>
        <AgentDownloadOptions {...props.section.initialize()} />
      </ProductSetupStep>
    )
  },
  {
    id: 999,
    title: '',
    excluded: true,
    children: [],
    component: (props) => (
      <ProductSetupStep {...props}>
        <SuccessStep />
      </ProductSetupStep>
    )
  }
];

export function getComponentForSection({ sectionId, ...rest }) {
  const step = sections.find((s) => s.id === sectionId);

  if (step) {
    const Component = step.component;
    return <Component {...rest} section={step} sections={sections} />;
  }

  return <h1>Failed to find setup component for section with id {sectionId}</h1>;
}

export function getActionsForSection({
  sectionId,
  onCompleted,
  markAsComplete,
  moveToNextSection,
  moveToPreviousSection
}) {
  const index = FindIndex(sections, { id: sectionId });
  const onNext = () => {
    const section = sections[index + 1];
    if (section) {
      markAsComplete(sectionId).then(moveToNextSection);
    }
  };
  const onPrevious = () => {
    const section = sections[index - 1];
    if (section) {
      markAsComplete(sectionId).then(moveToPreviousSection);
    }
  };
  let next = (
    <Button type="primary" className="ml-2" onClick={onNext}>
      Next
    </Button>
  );
  let prev = (
    <Button type="primary" onClick={onPrevious}>
      Previous
    </Button>
  );
  if (index === 0) {
    next = (
      <Button type="primary" onClick={onNext}>
        Start
      </Button>
    );
    prev = null;
  } else if (index === 1) {
    prev = null;
  }
  if (index === sections.length - 1) {
    next = null;
  }
  if (sectionId === 999) {
    prev = (
      <Button type="primary" onClick={onCompleted}>
        Close
      </Button>
    );
  }
  return (
    <div className="flex justify-between">
      <div>
        {sectionId !== 999 ? (
          <>
            {/* <Checkbox>Skip Entire configuration guide</Checkbox> */}
            <Button type="primary" onClick={onCompleted}>
              Skip
            </Button>
          </>
        ) : null}
      </div>
      <div>
        {prev}
        {next}
      </div>
    </div>
  );
}

export function getNextSection(sectionId) {
  const index = FindIndex(sections, { id: sectionId });
  return sections[index + 1] || null;
}

export function getCurrentSection(sectionId) {
  const index = FindIndex(sections, { id: sectionId });
  return sections[index] || null;
}

export function getPreviousSection(sectionId) {
  const index = FindIndex(sections, { id: sectionId });
  return sections[index - 1] || null;
}
