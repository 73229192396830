import { Department } from '@/src/components/pickers/DepartmentPicker';
import { Organization } from '@/src/components/pickers/OrganizationPicker';
import { Row, Col, Form, Input } from 'antd';

export default function EnrollSecretForm({ item }) {
  const form = Form.useFormInstance();
  return (
    <div className="overflow-x-hidden">
      <Row gutter={32}>
        <Col span={24}>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Organization" name="organization" rules={[{ required: true }]}>
            <Organization.Picker
              onChange={(organization) => {
                form.setFieldValue('organization', organization);
                form.setFieldValue('department', undefined);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Department" name="department" rules={[{ required: true }]}>
            <Department.Picker organization={form.getFieldValue('organization')} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
