export default function hzToUnit(hz, decimals = 2, predefinedUnit) {
  // if (!bytes) {
  //   return bytes
  // }
  if (isNaN(hz)) {
    return '';
  }
  if (parseInt(hz) <= 0 || hz <= 0 || hz === null || hz === undefined) {
    return '0 hz';
  }
  if (hz < 1) {
    return `${parseFloat(hz).toFixed(2)} hz`;
  }
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['hz', 'kHz', 'MHz', 'GHz', 'THz', 'PHz', 'EHz', 'ZHz', 'Yhz'];
  var i;
  if (predefinedUnit) {
    i = sizes.indexOf(predefinedUnit);
    if (i === -1) {
      i = Math.floor(Math.log(hz) / Math.log(k));
    }
  } else {
    i = Math.floor(Math.log(hz) / Math.log(k));
  }

  return parseFloat((hz / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
