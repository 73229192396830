import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { getReportApi } from '../modules/reports/api/reports';
import SocketEventHandler from '../components/SocketEventHandler';
import buildResult from '../components/widget/views/result-builder';
import { Branding } from '../components/Branding';
import { useAuth } from '../hooks/auth';

export default function ReportExportPage() {
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null);
  const params = useParams();
  const [reportContext] = useState({ reportId: +params.id });
  const { branding } = Branding.useBranding();
  const { token } = useAuth();

  useEffect(() => {
    getReportApi(params.id).then((data) => {
      setReport(data);
      window.__REPORT__ = {
        ...data,
        logo: `${
          branding.ref
            ? `${window.location.origin}/api/download?id=${
                branding.ref || branding.response
                // eslint-disable-next-line
              }&mid=${token.access_token}`
            : `${window.location.origin}/images/zirozen1.png`
        }`
      };
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [params]);

  function handleReportDataReceivd(data) {
    if (data.error) {
      throw new Error('Unable to received data');
      // handle error here
    } else {
      const result = buildResult(data);
      setTimeout(() => {
        window.__WIDGET_DATA__ = {
          rows: result.data,
          columns: result.columns
        };
        if (window.setReport) {
          window.setReport(window.__REPORT__);
        }
        if (window.captureViewPort) {
          window.captureViewPort();
        }
      }, 1000);
    }
  }

  return !loading && report.id ? (
    <SocketEventHandler
      event="report"
      context={reportContext}
      onDataReceived={handleReportDataReceivd}>
      {(data) =>
        data.error ? (
          <div className="flex flex-col items-center justify-center flex-1">
            <h2 className="text-danger">{data.error}</h2>
          </div>
        ) : (
          JSON.stringify(data)
        )
      }
    </SocketEventHandler>
  ) : (
    <Spin spinning={loading}>
      <div className="flex flex-col min-w-0 min-h-0 flex-1" />
    </Spin>
  );
}
