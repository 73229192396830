export function downloadFile(blob, url, name) {
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  if (blob) {
    url = window.URL.createObjectURL(blob);
  }
  a.href = url;
  a.download = name;
  a.click();
  if (blob) {
    window.URL.revokeObjectURL(url);
  }
}
