import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const DashboardPage = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ './views/DashboardPage')
);
const LandingPage = lazy(() => import(/* webpackChunkName: "dashboard" */ './views/LandingPage'));

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="dashboard" key="dashboard" element={<AppLayout />}>
      {/* if no route is defined navigate to about route */}
      <Route index element={<LandingPage />}></Route>
      <Route path=":id" element={<DashboardPage />} />
    </Route>
  );
}
