import api from '@api';

const END_POINT = `/inventory/enroll`;

const transform = (item) => ({
  id: item.id,
  name: item.name,
  secret: item.secret,
  department: +item.department,
  organization: +item.organization,
  createdAt: item.created_time
});

const transformForServer = async (item) => {
  return Promise.resolve({
    name: item.name,
    department: item.department,
    organization: item.organization
  });
};

const sortKeyMap = {
  createdAt: 'created_time'
};

const searchableColumns = ['name'];

export function getAllEnrollSecretApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getAllEnrollSecretForOrgAndDepApi(organization, department) {
  return api
    .post(
      `${END_POINT}/search`,
      {
        organization,
        department
      },
      {
        params: {
          offset: 0,
          size: 1000
        }
      }
    )
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getEnrollSecretApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function createEnrollSecretApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getEnrollSecretApi(data.result));
}

export function deleteEnrollSecretApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}

export function downloadAgentApi(secretId, platform, architecture, type) {
  return api.post(
    `${END_POINT}/agent/${secretId}`,
    {
      platform,
      architecture,
      ...(platform === 'linux' ? { type } : {})
    },
    { responseType: 'blob', fullResponse: true }
  );
}
