import PackageUploader from '@/src/components/PackageUploader';
import { useAuth } from '@/src/hooks/auth';

export default function PackageLogo({ package: packageObj, onChange, ...props }) {
  const { token } = useAuth();

  return packageObj.iconFile ? (
    <img
      {...props}
      src={`${packageObj.iconFile[0].url}${token.access_token}`}
      alt={packageObj.displayName}
    />
  ) : props.disabled ? null : (
    <PackageUploader accept="image/*" onUploaded={onChange}>
      <div {...props}>
        <img src="/images/addlogo_icon.png" alt="Add Logo" className="w-full cursor-pointer" />
      </div>
    </PackageUploader>
  );
}
