import api from '@api';

export function updateBrandingApi(data) {
  return api.put(`/settings/branding/0`, {
    branding_image: data.name || null,
    branding_image_ref: data.ref || null
  });
}

export function getBrandingApi() {
  return api.get(`/settings/branding/0`).then(({ result }) => ({
    ref: result.branding_image_ref === 'null' ? null : result.branding_image_ref,
    name: result.branding_image === 'null' ? null : result.branding_image
  }));
}
