import axios from 'axios';
import Omit from 'lodash/omit';
import { trimParams } from '@utils/params';
import { message, notification } from 'antd';
import TokenRefresher from './token-refresher';

/**
 * @class Api
 * @typedef {import('@/node_modules/axios/index').AxiosRequestConfig} RequestConfig
 * @typedef {import('@/node_modules/axios/index').AxiosResponse} Response
 */
class Api {
  totalRequests = 0;

  tokenRefresher = null;

  _store = null;

  /**
   * @constructor
   */
  constructor() {
    this._client = null;
    this.createApiClient();
    this.buildTokenRefresher();
  }

  buildTokenRefresher() {
    this.tokenRefresher = new TokenRefresher(this);
    this.tokenRefresher.onError((e) => {
      this.showToastNotification('Session has been expired.', 'error');
      const event = new Event('session-expired');
      window.dispatchEvent(event);
    });
  }

  /**
   * show notification
   * @param {string} description
   * @param {string} alertType
   */
  showToastNotification = (description, alertType) => {
    message[alertType](description);
  };

  showBigNotification = (notificationConfig, type) => {
    notification[type]({
      ...Omit(notificationConfig, ['error'])
    });
  };

  /**
   * Destroy api client
   */
  destroyClient() {
    this._client = null;
  }

  /**
   * show progress loader
   */
  showProgressLoader = () => {
    this.totalRequests++;
  };

  /**
   * hide request progress loader
   */
  hideProgressLoader = () => {
    this.totalRequests--;
    if (this.totalRequests < 0) {
      this.totalRequests = 0;
    }
  };

  /**
   * Intercept all outgoing request
   * @param {RequestConfig} config
   * @returns {RequestConfig}
   */
  requestInterceptor = (config) => {
    config = Object.assign(config, config.data.__metadata);
    delete config.data.__metadata;
    if (config.data.__qs) {
      config.data = config.data.__qs;
    }
    if (config.data) {
      // apply param trim to all params recurssive
      config.data = trimParams(config.data);
    }
    if (config.loader !== false) {
      this.showProgressLoader();
    }

    return config;
  };

  /**
   * Handle success resposne
   * @param {Response<object>} response
   * @returns {object}
   */
  responseSuccessHandler = (response) => {
    if (response.config.loader !== false) {
      this.hideProgressLoader();
    }
    const message =
      response.config.message ||
      // response.data.message || // remove backend message from simple api
      (response.config.notification || {}).message;
    if (
      (['post', 'put', 'delete'].indexOf(response.config.method.toLowerCase()) >= 0 ||
        response.config.message ||
        response.config.notification) &&
      response.config.notify !== false
    ) {
      requestAnimationFrame(() => {
        if (response.config.notification && response.config.notification.message) {
          this.showBigNotification(
            {
              ...response.config.notification,
              message
            },
            'success'
          );
        } else {
          if (message) {
            this.showToastNotification(message || 'The Operation has been completed.', 'success');
          }
        }
      });
    }
    if (response.config.fullResponse) {
      return response;
    }
    return response.data;
  };

  /**
   * handle response error
   * @param {Response<object>} error
   * @return {Promise<Resposne>}
   */
  responseErrorHandler = (error) => {
    if (axios.isCancel(error) || error.config.loader !== false) {
      this.hideProgressLoader();
    }
    if (axios.isCancel(error) || error.config.notify === false) {
      return Promise.reject(error);
    }
    if (error.config.url.indexOf('user/logout') >= 0) {
      return Promise.reject(error);
    }
    if ((error.response || {}).status !== 401) {
      let message =
        ((error.response || {}).data || {})['response-message'] ||
        ((error.response || {}).data || {}).result ||
        ((error.response || {}).data || {}).message ||
        'Unable to connect with server.';
      if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') >= 0) {
        message = 'Request timeout reached, Please Try again';
      }
      if ((error.config.notification || {}).error) {
        this.showBigNotification(
          {
            ...error.config.notification.error,
            description:
              (error.response.data || {}).message || error.config.notification.error.description
          },
          'error'
        );
      } else {
        requestAnimationFrame(() => {
          this.showToastNotification(message, 'error');
        });
      }
    } else {
      if (error.config.url === '/token') {
        return Promise.reject(error);
      }
      return new Promise((resolve, reject) => {
        const { config } = error;
        // token is expired start refreshing it
        this.tokenRefresher.onRefresh((token) => {
          const event = new CustomEvent('token-refresh', { detail: token });
          window.dispatchEvent(event);
          this.setHeaders({
            Authorization: `Bearer ${token.access_token}`
          });
          config.headers['Authorization'] = `Bearer ${token.access_token}`;
          this._client
            .request({
              ...config,
              url: config.url.replace(config.baseURL, '')
            })
            .then(resolve, reject);
          this.buildTokenRefresher();
        });
        this.tokenRefresher.refresh();
      });
    }
    return Promise.reject(error);
  };

  // this is only for static data where /api/v1 prefix is not used
  getNewClient(baseURL) {
    const client = axios.create({
      baseURL: baseURL,
      timeout: 120000
    });
    return client;
  }

  /**
   * Create Api client
   */
  createApiClient() {
    const headers = {
      'Content-Type': 'application/json'
    };
    this._client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}`,
      timeout: 120000,
      headers
    });
    this._client.interceptors.request.use(this.requestInterceptor);
    this._client.interceptors.response.use(this.responseSuccessHandler, this.responseErrorHandler);
  }

  /**
   * Apply header to all requests
   * @param {[key: string]: string} headers
   */
  setHeaders(headers) {
    if (!this._client) {
      throw new Error('no client found');
    }
    Object.keys(headers).forEach((key) => {
      this._client.defaults.headers.common[key] = headers[key];
    });
  }

  getHeaders() {
    if (!this._client) {
      throw new Error('no client found');
    }
    return this._client.defaults.headers;
  }

  /**
   * remove header from api client
   * @param {string} headerName
   */
  removeHeader(headerName) {
    if (!this._client) {
      throw new Error('no client found');
    }
    delete this._client.defaults.headers.common[headerName];
  }

  /**
   * Send Actual request
   * @param {RequestConfig} config
   * @returns {Promise<Response>}
   */
  _sendRequest(config) {
    const fireRequest = (resolve, reject) => {
      if (!this._client) {
        try {
          this.createApiClient();
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
          return reject(e);
        }
      }
      return this._client
        .request({
          ...config
        })
        .then(resolve, reject);
    };
    return new Promise((resolve, reject) => fireRequest(resolve, reject));
  }

  /**
   * send get request
   * @param {string} url
   * @param {RequestConfig} config
   */
  get(url, config) {
    return this._sendRequest({
      ...config,
      url,
      method: 'get',
      data: {
        __metadata: {
          ...config
        }
      }
    });
  }

  /**
   * Send post request
   * @param {string} url
   * @param {object|any} data
   * @param {RequestConfig} config
   */
  post(url, data, config) {
    if (typeof data !== 'string' && !Array.isArray(data)) {
      if (data) {
        data.__metadata = config;
      } else {
        data = {
          __metadata: config
        };
      }
    } else {
      data = {
        __qs: data,
        __metadata: config
      };
    }
    return this._sendRequest({
      ...config,
      url,
      data,
      method: 'post'
    });
  }

  /**
   * Send put request
   * @param {string} url
   * @param {object|any} data
   * @param {RequestConfig} config
   */
  put(url, data, config) {
    if (typeof data !== 'string') {
      if (data) {
        data.__metadata = config;
      } else {
        data = {
          __metadata: config
        };
      }
    } else {
      data = {
        __qs: data,
        __metadata: config
      };
    }
    return this._sendRequest({
      ...config,
      url,
      data,
      method: 'put'
    });
  }

  /**
   * send delete request
   * @param {string} url
   * @param {RequestConfig} config
   */
  delete(url, config = {}) {
    return this._sendRequest({
      ...config,
      data: {
        ...(config.data || {}),
        __metadata: {
          ...config
        }
      },
      url,
      method: 'delete'
    });
  }
}

export default Api;
