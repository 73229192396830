import { Transfer } from 'antd';
import buildPicker from './pickerBuilder';
import { getAllRulesApi } from '@/src/modules/compliance/api/compliance-rules';

const { Provider, contextHook } = buildPicker(
  getAllRulesApi,
  ComplianceRulePicker,
  (i, options) => ({
    key: i.id,
    title: `${i.bindings} - ${i.name}`
  })
);

function ComplianceRulePicker({ target, ...props }) {
  const { filteredOptions, allOptions } = contextHook();

  let options = filteredOptions || allOptions;

  if (target === 4) {
    options = Array.isArray(options)
      ? options
      : Array.from(options.values()).filter((i) => i.os.toLowerCase() === 'windows');
  }
  if (target === 5) {
    options = Array.isArray(options)
      ? options
      : Array.from(options.values()).filter((i) => i.os.toLowerCase() === 'linux');
  }
  if (target === 6) {
    options = Array.isArray(options)
      ? options
      : Array.from(options.values()).filter((i) => i.os.toLowerCase() === 'mac');
  }

  return (
    <Transfer
      {...props}
      showSearch
      render={(i) => i.title}
      filterOption={(searchTerm, node) => {
        return node.title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
      }}
      dataSource={Array.isArray(options) ? options : Array.from(options.values())}
      listStyle={{
        width: '45%',
        height: 350
      }}
    />
  );
}

export const ComplianceRule = {
  useComplianceRule: contextHook,
  Provider: Provider,
  Picker: ComplianceRulePicker
};
