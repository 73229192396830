import { useState, createContext, useContext, Suspense } from 'react';
import { Row, Col } from 'antd';
import { Outlet } from 'react-router-dom';
import PageHeading from '@/src/components/PageHeading';
import SettingsMenu from '../components/SettingsMenu';
import AnimatedRoutes from '@components/AnimatedRoutes';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';
import Loading from '@/src/components/Loading';
import SettingHeading from '../components/SettingHeading';

const SettingsLayoutContext = createContext();

export default function SettingsLayout() {
  const [showMenu, setShowMenu] = useState(true);
  const [activeMenu, setactiveMenu] = useState(true);

  return (
    <SettingsLayoutContext.Provider
      value={{
        hideMenu: () => setShowMenu(false),
        showMenu: () => setShowMenu(true),
        setactiveMenu: (menuItem) => setactiveMenu(menuItem),
        activeMenu: activeMenu
      }}>
      <div className="h-full flex flex-col">
        <PageHeading icon="settings" title="Settings" />
        <div className="flex-1 min-h-0 flex flex-col h-full">
          <Row className="h-full">
            {showMenu && (
              <Col span={4} className="flex flex-col min-h-0 h-full">
                <SettingsMenu />
              </Col>
            )}
            <PermissionChecker
              permission={[constants.View_Settings, constants.View_Quick_Checks]}
              hasAny>
              <AnimatedRoutes element={Col} span={24 - (showMenu ? 4 : 0)} className="h-full">
                <SettingHeading style={{ height: '10%' }} />

                <Row className="flex-grow flex flex-1" style={{ height: '90%' }}>
                  <Suspense fallback={<Loading />}>
                    <Outlet />
                  </Suspense>
                </Row>
              </AnimatedRoutes>
            </PermissionChecker>
          </Row>
        </div>
      </div>
    </SettingsLayoutContext.Provider>
  );
}

export function useSettingsLayout() {
  return useContext(SettingsLayoutContext);
}
