import { Row, Col, Form, Select } from 'antd';
import { Asset } from '@/src/components/pickers/AssetPicker';
import { Department } from '@/src/components/pickers/DepartmentPicker';
import { useEffect, useState } from 'react';
import Api from '@api';

export function transformAssetScope(item) {
  return {
    scope: {
      ...([4, 5, 6].includes(+item.scope)
        ? { assets: item.platform_versions || [] }
        : {
            assets:
              item.assets && item.assets !== 'null' && item.assets !== null
                ? Array.isArray(item.assets)
                  ? item.assets
                  : item.assets.split(',').map((i) => +i)
                : []
          }),
      assetFilter: +item.scope
    }
  };
}

export function transformAssetScopeForServer(item, useArray = false, useCamelCase = false) {
  return {
    scope: (item.scope || {}).assetFilter,
    ...([4, 5, 6].includes((item.scope || {}).assetFilter)
      ? {
          ...(useCamelCase
            ? { platformVersions: (item.scope || {}).assets || [] }
            : { platform_versions: (item.scope || {}).assets || [] })
        }
      : {
          assets: ((item.scope || {}).assets || []).length
            ? useArray
              ? item.scope.assets
              : item.scope.assets.join(',')
            : null
        })
  };
}

export const assetFilterOptions = [
  {
    label: 'All Endpoints',
    value: 1
  },
  {
    label: 'Specific Endpoints',
    value: 2
  },
  {
    label: 'Specific Departments',
    value: 3
  },
  {
    label: 'Windows',
    value: 4
  },
  {
    label: 'Linux',
    value: 5
  },
  {
    label: 'macOS',
    value: 6
  }
];

export default function AssetScopePicker({
  skipProvider,
  label = 'Specific Endpoint',
  name = 'assetFilter',
  subname = 'assets',
  gutter = 32,
  onPlatformChange
}) {
  const form = Form.useFormInstance();

  let [version, setVersion] = useState({});

  function getAssetVersions() {
    Api.get(`/inventory/asset/platform/versions`).then(({ result }) => {
      let versionMap = {};
      for (let key of Object.keys(result)) {
        versionMap[key] = result[key].map((i) => ({ value: i, label: i }));
      }
      setVersion(versionMap);
    });
  }

  useEffect(() => {
    if ([4, 5, 6].includes(form.getFieldValue(name)) && !version[form.getFieldValue(name)]) {
      getAssetVersions();
    }
    // eslint-disable-next-line
  }, [form.getFieldValue(name)]);

  const children = (
    <Row gutter={gutter}>
      <Col span={12}>
        <Form.Item label={label} name={name} rules={[{ required: true }]}>
          <Select
            placeholder="Select One"
            options={assetFilterOptions}
            onChange={(assetFilter) => {
              form.setFieldValue(name, assetFilter);
              form.setFieldValue(subname, []);
              if (onPlatformChange) {
                onPlatformChange(assetFilter);
              }
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        {[4, 5, 6].includes(form.getFieldValue(name)) && (
          <Form.Item label="OS Version" name={subname}>
            <Select
              mode="multiple"
              options={version[form.getFieldValue(name)]}
              placeholder="Select Version(s)"
              onChange={(assets) => form.setFieldValue(subname, assets)}
            />
          </Form.Item>
        )}
        {[3, 4, 5, 6].includes(form.getFieldValue(name)) === false && (
          <Form.Item label="Endpoints" name={subname}>
            <Asset.Picker
              mode="multiple"
              disabled={form.getFieldValue(name) !== 2}
              onChange={(assets) => form.setFieldValue(subname, assets)}
            />
          </Form.Item>
        )}
        {form.getFieldValue(name) === 3 && (
          <Form.Item label="Departments" name={subname}>
            <Department.Picker
              mode="multiple"
              onChange={(assets) => form.setFieldValue(subname, assets)}
            />
          </Form.Item>
        )}
      </Col>
    </Row>
  );

  if (skipProvider) {
    return children;
  }
  return (
    <Asset.Provider>
      <Department.Provider>{children}</Department.Provider>
    </Asset.Provider>
  );
}
