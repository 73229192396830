import React, { createContext, useContext } from 'react';
import { message, notification } from 'antd';
import { Outlet } from 'react-router-dom';
import { PublicGuard } from '@components/PublicGuard';
import Loading from '@components/Loading';

const PublicLayoutContext = createContext();

export default function PublicLayout() {
  const [notificationApi, notificationHolder] = notification.useNotification();
  const [messageApi, messageHolder] = message.useMessage();

  return (
    <PublicGuard>
      <PublicLayoutContext.Provider value={{ notification: notificationApi, message: messageApi }}>
        <React.Suspense fallback={<Loading />}>
          <Outlet />
          {messageHolder}
          {notificationHolder}
        </React.Suspense>
      </PublicLayoutContext.Provider>
    </PublicGuard>
  );
}

export function usePublicLayout() {
  return useContext(PublicLayoutContext);
}
