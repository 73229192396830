import React from 'react';
import { Divider, Row, Col } from 'antd'; // Import Divider from Ant Design
// import SettingHeading from '@modules/settings/components/SettingHeading';

const SettingFormLayout = ({ title, form, help }) => {
  return (
    <div className="form-container w-full h-full">
      {/* {title && <SettingHeading title={title} className="w-full" />} */}
      <div className="form-body flex">
        <div className="flex-1">
          <Row gutter={0}>
            <Col span={14} className="p-4">
              {form} {/* The form component will be rendered here */}
            </Col>
          </Row>
        </div>
        {help && (
          <>
            <Divider type="vertical" className="divider" /> {/* Vertical divider */}
            <div>
              {help} {/* The help component will be rendered here */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SettingFormLayout;
