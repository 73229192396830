import momentTZ from 'moment-timezone';
import { useState } from 'react';
import Picker from './Picker';

export default function TimezonePicker({ value, onChange, ...props }) {
  const timezones = momentTZ.tz.names().map((item) => ({ value: item, label: item }));

  const [options, setOptions] = useState(timezones);

  function search(term) {
    if (term) {
      setOptions(timezones.filter((t) => t.label.toLowerCase().includes(term.toLowerCase())));
    } else {
      setOptions(timezones);
    }
  }

  return (
    <Picker
      {...props}
      style={{ width: '100%' }}
      placeholder="Please Select"
      value={value}
      onChange={onChange}
      onSearch={search}
      options={options}
    />
  );
}
