import { Col, Button } from 'antd';
// import { Link } from 'react-router-dom';
// import { ReactComponent as Logo } from '@/src/assets/logo.svg';
import PackageLogo from './PackageLogo';
import Icon from '@/src/components/Icon';

export function PackageCard({ record, span = 6, showAssetLink, disabled, deleteItem, edit }) {
  return (
    <Col span={span} className="mb-4">
      <div className="w-full bg-seperator rounded-lg shadow-md flex flex-col px-4 py-4 relative">
        <div className="w-full flex justify-between">
          <PackageLogo
            disabled={disabled}
            package={record}
            className="mr-2 w-12 h-12 object-contain"
            //   onChange={(logo) => {
            //     update({
            //       ...record,
            //       iconFile: [logo]
            //     });
            //   }}
          />

          <div className="flex">
            <Button type="text" onClick={() => edit(record)}>
              <Icon name="edit" style={{ fontSize: '1.1rem' }} />
            </Button>

            <Button type="text" onClick={() => deleteItem(record)}>
              <Icon name="delete" style={{ fontSize: '1.1rem' }} />
            </Button>
          </div>
        </div>
        <h2 className="mt-2">{record.displayName} </h2>
        <div className="text-ellipsis" title={record.description}>
          {record.description}
        </div>
        <div className="flex w-full justify-between mt-2">
          <div className="flex ">
            <span className="p-2 mr-2  " style={{ border: '1px solid #cab6ba' }}>
              {record.version}
            </span>
            <span className="p-2  " style={{ border: '1px solid #cab6ba' }}>
              {record.pkgType}
            </span>
          </div>
          <span className="flex flex-col justify-end">
            <Icon name={`platform_${record.os}`} style={{ fontSize: '1.5rem' }} />
          </span>
        </div>
      </div>
    </Col>
  );
}
