import api from '@api';
// import {
//   transformAssetScope,
//   transformAssetScopeForServer
// } from '@/src/components/pickers/AssetScopePicker';

const END_POINT = `/patch/computer-group`;

const transform = (item, forServer) => {
  const context = {
    ...(!forServer
      ? {
          id: item.id,
          created_time: item.created_time,
          modified_time: item.modified_time,
          asset_ids: item.assetIds

          // ...transformAssetScope(item)
        }
      : {
          assetIds: item.asset_ids

          // ...transformAssetScopeForServer(item)
        }),
    name: item.name,
    description: item.description
  };

  return context;
};

const sortKeyMap = {
  name: 'name',
  description: 'description'
};

const searchableColumns = ['name', 'exchange_rate'];

export function getAllComputerGroupsApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((i) => transform(i))
      };
    });
}

export function getComputerGroupsApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function updateComputerGroupsApi(item) {
  return api
    .put(`${END_POINT}/${item.id}`, transform(item, true))
    .then((data) => getComputerGroupsApi(data.result));
}

export function createComputerGroupsApi(item) {
  return api
    .post(`${END_POINT}`, transform(item, true))
    .then((data) => getComputerGroupsApi(data.result));
}

export function deleteComputerGroupsApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}
