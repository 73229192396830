export default function bytesPerSecToSize(bytes, decimals = 2, predefinedUnit) {
  // if (!bytes) {
  //   return bytes
  // }
  if (isNaN(bytes)) {
    return '';
  }
  if (parseInt(bytes) <= 0 || bytes <= 0 || bytes === null || bytes === undefined) {
    return '0 Bps';
  }
  if (bytes < 1) {
    return `${parseFloat(bytes).toFixed(2)} Bps`;
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bps', 'KBps', 'MBps', 'GBps', 'TBps', 'PBps', 'EBps', 'ZBps', 'YBps'];
  var i;
  if (predefinedUnit) {
    i = sizes.indexOf(predefinedUnit);
    if (i === -1) {
      i = Math.floor(Math.log(bytes) / Math.log(k));
    }
  } else {
    i = Math.floor(Math.log(bytes) / Math.log(k));
  }

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function bytesToSize(bytes, decimals = 2, predefinedUnit) {
  if (bytes === 0 || bytes === null || bytes === undefined) {
    return '0 Bytes';
  }

  if (bytes < 1) {
    return `${parseFloat(bytes).toFixed(2)} Bytes`;
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var i;
  if (predefinedUnit) {
    i = sizes.indexOf(predefinedUnit);
    if (i === -1) {
      i = Math.floor(Math.log(bytes) / Math.log(k));
    }
  } else {
    i = Math.floor(Math.log(bytes) / Math.log(k));
  }

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
