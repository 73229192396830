import { Navigate, useNavigate } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useAuth } from '@/src/hooks/auth';
import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import { usePublicLayout } from '@/src/layouts/PublicLayout';

export default function Login() {
  const { authenticate, user } = useAuth();

  const layout = usePublicLayout();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  if (user) {
    return <Navigate replace to="/dashboard" />;
  }

  const onFinish = (values) => {
    setLoading(true);
    authenticate(values)
      .then(() => {
        navigate('/dashboard');
      })
      .catch((e) => {
        layout.notification.error({
          message: 'Error',
          description: e.message
        });
        setLoading(false);
      });
  };

  return (
    <div
      className="flex h-full flex-col login-container font-sans"
      style={{
        background: `url(/images/loginscreen_pattern.jpg) repeat`,
        backgroundSize: 'cover'
      }}>
      <div className="flex flex-1 items-center justify-start my-16 h-full login-card-container flex-col relative">
        <div className="mx-4 max-w-[450px] login-card w-full relative h-[80%] border-container">
          <div className="max-w-[300px] flex flex-col m-auto">
            <div className="text-center text-white">
              <img src="/images/endpointOps_logo.png" alt="EndpointOps" className="w-full" />
            </div>
            <div className="mb-20 text-lg text-center login-tag-line label">
              Empower Security Unify Control
            </div>
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off">
              <Form.Item
                label={null}
                required={false}
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}>
                <Input size="large" prefix={<UserOutlined />} placeholder="Type your username" />
              </Form.Item>

              <Form.Item
                label={null}
                name="password"
                required={false}
                rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password
                  size="large"
                  placeholder="Type your password"
                  prefix={<LockOutlined />}
                />
              </Form.Item>

              <div className="mt-8">
                <Button
                  block
                  size="large"
                  className="gradient-btn"
                  loading={loading}
                  type="primary"
                  htmlType="submit">
                  <span className="text-base tracking-[0.15rem] uppercase">Login</span>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <img src="/images/loginscreen_footer.png" alt="footer" className="absolute bottom-0 w-full" />
    </div>
  );
}
