import { createContext, useEffect, useContext, useMemo, useState } from 'react';
import SocketClient from '@vertx/eventbus-bridge-client.js';
import Bus from '@utils/emitter';

const SocketContext = createContext();

const Provider = (props) => {
  let [socket, setSocket] = useState(null);
  let [isConnected, setConnected] = useState(null);

  useEffect(() => {
    if (isConnected === null) {
      return;
    }
    if (isConnected && props.onConnect) {
      props.onConnect();
    } else if (!isConnected && props.onDisconnect) {
      props.onDisconnect();
    }
    // eslint-disable-next-line
  }, [isConnected, props]);

  const handleError = (error) => {
    console.error('socket error', error);
    throw error;
  };

  const send = (event, message) => {
    if (!isConnected) {
      return;
    }
    try {
      socket.send('events', {
        type: event,
        context: message
      });
      console.group(
        `%c  ${event} send at ${Date.now()}`,
        'background:#e72b25 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff'
      );
      // eslint-disable-next-line
      console.log(message);
      // eslint-disable-next-line
      console.groupEnd();
    } catch (e) {
      console.error(e);
    }
  };

  const socketEventListener = (error, payload) => {
    // eslint-disable-next-line
    if (error) {
      return handleError(error);
    }
    setTimeout(async () => {
      const eventType = payload.body['type'];
      let eventContext = payload.body['context'] || payload;
      try {
        const eventContext = payload.body['context'];
        if (props.onEvent) {
          props.onEvent({ eventType, eventContext });
        }
        console.group(
          `%c  ${eventType} received at ${Date.now()}`,
          'background:#099DD9 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff'
        );
        // eslint-disable-next-line
        console.log(eventContext);
        // eslint-disable-next-line
        console.groupEnd();
        Bus.emit(eventType, eventContext);
      } catch (e) {
        // eslint-disable-next-line
        console.log(`failed to decode byte string ${eventContext.result}`);
        // eslint-disable-next-line
        console.error(e);
      }
    });
  };

  useEffect(() => {
    const socket = new SocketClient(props.url, {
      vertxbus_ping_interval: 5000,
      transports: 'websocket'
    });
    const registerHandler = () => {
      socket.registerHandler('events_ui', socketEventListener);
    };
    socket.enableReconnect(true);
    socket.onopen = () => {
      registerHandler(props.sessionId);
      setTimeout(() => {
        Bus.emit('socket:connected');
        setConnected(true);
      });
    };
    // socket.onreconnect = () => {
    //   setTimeout(() => {
    //     if (props.onConnect) {
    //       props.onConnect();
    //     }
    //     if (props.onReconnect) {
    //       props.onReconnect();
    //     }
    //     Bus.emit('socket:connected');
    //     setConnected(true);
    //   });
    // };
    socket.onerror = handleError;
    socket.onclose = () => {
      setTimeout(() => {
        Bus.emit('socket:disconnected');
        setConnected(false);
      });
    };

    setSocket(socket);
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const value = useMemo(() => ({
    send,
    socket,
    connected: isConnected
  }));

  return <SocketContext.Provider value={value}>{props.children}</SocketContext.Provider>;
};

function contextHook() {
  // eslint-disable-next-line
  return useContext(SocketContext);
}

export const Socket = {
  useSocket: contextHook,
  Provider: Provider
};
