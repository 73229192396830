import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import SettingsLayout from './layout/SettingsLayout.jsx';
import NotFound from '@/src/components/NotFound.jsx';
import PermissionChecker from '@/src/components/PermissionChecker.jsx';
import constants from '@/src/constants/index.js';
import ThreatDatabase from './views/system-settings/ThreatDatabase.jsx';

const Audit = lazy(() => import(/* webpackChunkName: "settings" */ './views/audit/List.jsx'));

const Actions = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/integrations/Actions.jsx')
);

const DeploymentPolicies = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/software-deployment/DeploymentPolicies.jsx')
);

const Organization = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/user-management/Organization.jsx')
);
const Department = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/user-management/Department.jsx')
);
const Roles = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/user-management/Roles.jsx')
);
const Users = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/user-management/Users.jsx')
);
const Hosts = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/inventory-profile/Hosts.jsx')
);
const EditHost = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/inventory-profile/EditHost.jsx')
);
const CreateHost = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/inventory-profile/CreateHost.jsx')
);
const ViewHost = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/inventory-profile/ViewHost.jsx')
);
const QuickCheckToolbar = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/policy-management/QuickCheckToolbar.jsx')
);
const Policies = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/policy-management/Policies.jsx')
);
const MailServerConfig = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/MailServerConfig.jsx')
);
const LDAPServerConfig = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/LDAPServerConfig.jsx')
);
const Branding = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/Branding.jsx')
);
const License = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/License.jsx')
);
const Rootkit = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/policy-management/Rootkit.jsx')
);
const LogoManagement = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/LogoManagement.jsx')
);
const RiskSetting = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/RiskSetting.jsx')
);
const EnrollSecret = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/agent-management/EnrollSecret.jsx')
);
const AgentConfiguration = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/agent-management/AgentConfiguration.jsx')
);
const BlacklistedPorts = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/BlacklistedPort.jsx')
);
const RemoteDesktopSettings = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/RemoteDesktopSettings.jsx')
);
const ServerSettings = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/ServerSettings.jsx')
);

const LicenseEdition = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/licenseEdition.jsx')
);

const Currency = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/system-settings/Currency.jsx')
);
// const PatchApprovalPolicy = lazy(() =>
//   import(/* webpackChunkName: "settings" */ './views/patch-management/PatchApprovalPolicy.jsx')
// );
const ComputerGroups = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/patch-management/ComputerGroups.jsx')
);
// const PatchDeclinePolicy = lazy(() =>
//   import(/* webpackChunkName: "settings" */ './views/patch-management/PatchDeclinePolicy.jsx')
// );

const PatchPrefrence = lazy(() =>
  import(/* webpackChunkName: "settings" */ './views/patch-management/PatchPrefrence.jsx')
);

// const SystemHealth = lazy(() =>
//   import(/* webpackChunkName: "settings" */ './views/patch-management/SystemHealth.jsx')
// );

// const UpdatePatchDatabase = lazy(() =>
//   import(/* webpackChunkName: "settings" */ './views/patch-management/UpdatePatchDatabase.jsx')
// );

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="/settings" key="settings" element={<AppLayout />}>
      <Route element={<SettingsLayout />}>
        <Route index element={<Navigate replace to="user-management" />}></Route>
        <Route path="user-management" element={<Outlet />}>
          <Route index element={<Navigate replace to="organizations" />}></Route>
          <Route path="organizations" element={<Organization />} />
          <Route path="departments" element={<Department />} />
          <Route path="user-roles" element={<Roles />} />
          <Route path="users" element={<Users />} />
        </Route>
        <Route path="inventory-profiles" element={<Outlet />}>
          <Route index element={<Navigate replace to="hosts" />}></Route>
          <Route path="hosts" element={<Outlet />}>
            <Route path="" element={<Hosts />} />
            <Route
              path="create"
              index
              element={
                <PermissionChecker redirect permission={[constants.Create_Settings]}>
                  <CreateHost />
                </PermissionChecker>
              }
            />
            <Route path=":id/view" index element={<ViewHost />} />
            <Route
              path=":id/edit"
              index
              element={
                <PermissionChecker redirect permission={[constants.Update_Settings]}>
                  <EditHost />
                </PermissionChecker>
              }
            />
          </Route>
        </Route>
        <Route path="policy-management" element={<Outlet />}>
          <Route index element={<Navigate replace to="policies" />}></Route>
          <Route
            path="policies"
            element={
              <PermissionChecker redirect permission={[constants.View_Alert]}>
                <Policies />
              </PermissionChecker>
            }
          />
          <Route
            path="rootkit"
            element={
              <PermissionChecker redirect permission={[constants.View_Root_Kit]}>
                <Rootkit />
              </PermissionChecker>
            }
          />
          <Route
            path="quick-check-toolbar"
            element={
              <PermissionChecker redirect permission={[constants.View_Quick_Checks]}>
                <QuickCheckToolbar />
              </PermissionChecker>
            }
          />
        </Route>
        <Route path="system-settings" element={<Outlet />}>
          <Route index element={<Navigate replace to="mail-server-config" />}></Route>
          <Route path="branding" element={<Branding />} />
          <Route path="logo-management" element={<LogoManagement />} />
          <Route path="ziroscore-settings" element={<RiskSetting />} />
          <Route path="mail-server-config" element={<MailServerConfig />} />
          <Route path="ldap-server-config" element={<LDAPServerConfig />} />
          <Route path="blacklisted-ports" element={<BlacklistedPorts />} />
          <Route
            path="threat-database"
            element={
              <PermissionChecker redirect permission={[constants.Manage_Settings]}>
                <ThreatDatabase />
              </PermissionChecker>
            }
          />
          <Route
            path="license-management"
            element={
              <PermissionChecker redirect permission={[constants.Manage_Settings]}>
                <License />
              </PermissionChecker>
            }
          />
          <Route path="remote-desktop-settings" element={<RemoteDesktopSettings />} />
          <Route path="server-settings" element={<ServerSettings />} />
          <Route path="license-edition" element={<LicenseEdition />} />
          <Route path="currency" element={<Currency />} />
        </Route>
        <Route path="agent-management" element={<Outlet />}>
          <Route index element={<Navigate replace to="agent-configuration" />}></Route>
          <Route path="agent-configuration" element={<AgentConfiguration />} />
          <Route path="enroll-secret" element={<EnrollSecret />} />
        </Route>
        <Route path="software-deployment" element={<Outlet />}>
          <Route index path="" element={<DeploymentPolicies />} />
        </Route>

        <Route path="patch-management" element={<Outlet />}>
          <Route index element={<Navigate replace to="computer-groups" />}></Route>
          {/* <Route path="patch-approval-policy" element={<PatchApprovalPolicy />} /> */}
          <Route path="computer-groups" element={<ComputerGroups />} />
          <Route path="patch-preference" element={<PatchPrefrence />} />
          {/* <Route path="system-health" element={<SystemHealth />} /> */}
          {/* <Route path="update-patch-database" element={<UpdatePatchDatabase />} /> */}
        </Route>
        <Route path="integrations" element={<Outlet />}>
          <Route index path="" element={<Actions />} />
        </Route>
        <Route path="audit" element={<Outlet />}>
          <Route index element={<Audit />}></Route>
        </Route>
        <Route path="*" element={<NotFound redirectTo="/settings" />} />
      </Route>
    </Route>
  );
}
