import { Select } from 'antd';
import { User } from './UserPicker';

export default function RecipientsPicker(props) {
  const { allOptions } = User.useUsers();

  return (
    <Select
      placeholder="Please Select"
      {...props}
      mode="tags"
      options={Array.from(allOptions.values()).map((i) => ({
        label: i.email,
        value: i.email
      }))}
    />
  );
}
