import { Select } from 'antd';

export default function Picker({ value, onChange, onSearch, options, ...props }) {
  return (
    <Select
      {...props}
      filterOption={false}
      showSearch={Boolean(onSearch)}
      style={{ width: '100%' }}
      placeholder={props.placeholder || 'Please Select'}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
      options={Array.isArray(options) ? options : Array.from(options.values())}
    />
  );
}
