import { transformAssetScopeForServer } from '@/src/components/pickers/AssetScopePicker';
import generateId from '@/src/utils/id';
import api from '@api';
import { transform as transformEndpoint } from '@modules/inventory/api/hosts';

export const END_POINT = `/patch/patch`;

const transform = (item) => ({
  id: item.id,
  title: item.title,
  osPlatform: item.osPlatform,
  osArch: item.osArch,
  vendorId: item.vendorId,
  osApplicationId: item.osApplicationId,
  downloadStatus: item.downloadStatus,
  downloadSize: item.downloadSize,
  patchApprovalStatus: item.patchApprovalStatus,
  patchTestStatus: item.patchTestStatus,
  approvedOn: item.approvedOn,
  approvedBy: item.approvedBy,
  description: item.description,
  affectedProducts: item.affectedProducts,
  tags: item.tags,
  downloadOn: item.downloadOn,
  bulletinId: item.bulletinId,
  cveNumber: item.cveNumber,
  kbId: item.kbId,
  patchSeverity: item.patchSeverity,
  patchUpdateCategory: item.patchUpdateCategory,
  supportUrl: item.supportUrl,
  languageSupported: item.languageSupported,
  rebootBehaviour: item.rebootBehaviour,
  isUninstallable: item.isUninstallable,
  hasSupersededUpdates: item.hasSupersededUpdates,
  isSuperseded: item.isSuperseded,
  downloadFileDetails: (item.downloadFileDetails || []).map((f) => ({
    ...f,
    id: generateId(),
    url: `/api/patch/download/${f.refName}?mid=`
  })),
  uuid: item.uuid,
  status: item.status,
  source: item.source,
  createdAt: item.createdTime,
  updatedAt: item.updatedTime,
  name: item.name,
  releaseDate: item.releaseDate,
  installedEndpoints: item.installedEndpoints,
  missingEndpoints: item.missingEndpoints,
  totalEndPoints:
    item.installedEndpoints || 0 + item.missingEndpoints || 0 + item.ignoredEndpoints || 0
});

const transformForServer = async (item) => {
  return Promise.resolve({
    title: item.title,
    osPlatform: item.osPlatform,
    osArch: item.osArch,
    vendorId: item.vendorId,
    osApplicationId: item.osApplicationId,
    downloadStatus: item.downloadStatus,
    downloadSize: item.downloadSize,
    patchApprovalStatus: item.patchApprovalStatus,
    patchTestStatus: item.patchTestStatus,
    approvedOn: item.approvedOn,
    approvedBy: item.approvedBy,
    description: item.description,
    affectedProducts: item.affectedProducts,
    tags: item.tags,
    downloadOn: item.downloadOn,
    bulletinId: item.bulletinId,
    cveNumber: item.cveNumber,
    kbId: item.kbId,
    patchSeverity: item.patchSeverity,
    patchUpdateCategory: item.patchUpdateCategory,
    supportUrl: item.supportUrl,
    languageSupported: item.languageSupported,
    rebootBehaviour: item.rebootBehaviour,
    isUninstallable: item.isUninstallable,
    hasSupersededUpdates: item.hasSupersededUpdates,
    isSuperseded: item.isSuperseded,
    downloadFileDetails: (item.downloadFileDetails || []).map((i) => ({
      ...(i && (i.ref || i.response)
        ? {
            refName: i.ref || i.response.ref,
            fileName: i.name || i.response.name,
            size: i.size
          }
        : i),
      ...(i.size ? { size: i.size } : {})
    })),
    uuid: item.uuid,
    status: item.status,
    source: item.source
  });
};

const sortKeyMap = {
  name: 'full_name',
  phone: 'mobile_no',
  createdAt: 'created_time'
};

export const searchableColumns = [
  'title',
  'description',
  'tags',
  'bulletinId',
  'cveNumber',
  'kbId',
  'supportUrl',
  'uuid',
  'name',
  'kbId'
];

export function getAllPatchApi(offset, size, sortFilter, filters = {}) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: (sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
      ).concat(
        filters.platform !== 'all'
          ? [
              {
                operator: 'equals',
                column: 'osPlatform',
                value: filters.platform,
                condition: 'and'
              }
            ]
          : []
      )
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getPatchApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function updatePatchApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${END_POINT}/${item.id}`, data);
    })
    .then((data) => getPatchApi(data.result));
}

export function createPatchApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getPatchApi(data.result));
}

export function deletePatchApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}

const endpointSortKeyMap = {
  computerName: 'computer_name',
  hostname: 'host_name',
  owner: 'users',
  department: 'departments',
  createdAt: 'created_at'
};

const endpointSearchableColumns = [
  'host_name',
  'uuid',
  'arch',
  'code_name',
  'major',
  'minor',
  'name',
  'patch',
  'platform_like',
  'os_version',
  'osquery_version',
  'vendor',
  'version',
  'cpu_type',
  'cpu_subtype',
  'cpu_brand',
  'cpu_physical_cores',
  'cpu_logical_cores',
  'hardware_vendor',
  'hardware_model',
  'hardware_version',
  'hardware_serial',
  'computer_name',
  'kernel_version'
];

export function getEndpointsForPatchApi(patchId, offset, size, sortFilter, filters = {}) {
  return api
    .post(`/patch/asset-patch-relation/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              endpointSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: (sortFilter.searchTerm
        ? endpointSearchableColumns.map((c, index) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm,
            ...(index === endpointSearchableColumns.length - 1 ? { condition: 'and' } : {})
          }))
        : []
      ).concat([
        {
          operator: 'equals',
          column: 'patchId',
          value: patchId.toString(),
          condition: 'and'
        },
        ...(filters.category
          ? [
              {
                operator: 'equals',
                column: 'patchState',
                value: filters.category,
                condition: 'and',
                type: 'enum',
                reference: 'patchState'
              }
            ]
          : [])
      ])
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map((i) => ({
          ...transformEndpoint(i.endpoint || {}),
          endpointId: (i.endpoint || {}).id,
          id: i.id
        }))
      };
    });
}

export function addPatchAssetRelationApi(data) {
  return api.post(`/patch/asset-patch-relation`, {
    patchId: data.patchId,
    patchState: data.patchState,
    ...transformAssetScopeForServer(data)
  });
}

export function deletePatchAssetRelatinApi({ id }) {
  return api.delete(`/patch/asset-patch-relation/${id}`);
}
