import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const PatchLayout = lazy(() => import(/* webpackChunkName: "alert" */ './layout/PatchLayout'));
const List = lazy(() => import(/* webpackChunkName: "alert" */ './views/List'));
const PathDetail = lazy(() => import(/* webpackChunkName: "alert" */ './views/ViewPatch'));
const PatchDeployment = lazy(() =>
  import(/* webpackChunkName: "alert" */ './views/PatchDeployment')
);
const AutoPatchDeploymentTest = lazy(() =>
  import(/* webpackChunkName: "alert" */ './views/AutoPatchDeploymentTest')
);
const AutoPatchDeployment = lazy(() =>
  import(/* webpackChunkName: "alert" */ './views/AutoPatchDeployment')
);

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="patch" key="patch" element={<AppLayout />}>
      <Route element={<PatchLayout />}>
        {/* if no route is defined navigate to about route */}
        <Route path="patches" index element={<List />}></Route>
        <Route path="patches/:id" element={<PathDetail />}></Route>
        <Route path="patch-deployments" element={<PatchDeployment />}></Route>
        <Route path="auto-patch-test" element={<AutoPatchDeploymentTest />}></Route>
        <Route path="auto-patch-deployment" element={<AutoPatchDeployment />}></Route>
      </Route>
    </Route>
  );
}
