import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function AnimatedRoutes({ element, ...props }) {
  const Component = element || 'div';
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState('fadeIn');

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage('fadeOut');
  }, [location, displayLocation]);

  return (
    <Component
      className={`${transitionStage} relative`}
      onAnimationEnd={() => {
        if (transitionStage === 'fadeOut') {
          setTransistionStage('fadeIn');
          setDisplayLocation(location);
        }
      }}
      {...props}
    />
  );
}
