import { Button } from 'antd';
import { useState } from 'react';
import EnrollDownloadFormModal from './AgentDownloadFormModal';

export default function EnrollBtn() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        type="primary"
        size="medium"
        className="gradient-btn"
        onClick={() => setIsModalOpen(true)}>
        Enroll
      </Button>
      {isModalOpen ? (
        <EnrollDownloadFormModal isModalOpen={isModalOpen} onDone={() => setIsModalOpen(false)} />
      ) : null}
    </>
  );
}
