import OmitBy from 'lodash/omitBy';
import { DEPLOYMENT_TYPE } from '@modules/device-automation/deployments';

import {
  transformAssetScope,
  transformAssetScopeForServer
} from '@/src/components/pickers/AssetScopePicker';

export function transformAutoPatchDeployementTestForClient(item) {
  const computerGroupFilterRest = item.computerGroupFilterRest || {};
  return {
    id: item.id,
    displayName: item.displayName,
    description: item.description,
    patchCategories: item.patchCategories,
    patchSeverities: item.patchSeverities,
    platform: item.platform,
    applicationType: item.applicationType,
    productIds: item.productIds,
    deploymentPolicyId: item.deploymentPolicyId,
    notifyEmailIds: item.notifyTo,
    deployAfterApprovalDays: item.deployAfterApprovalDays,

    ...transformAssetScope(computerGroupFilterRest),
    computerFilterType: computerGroupFilterRest.computerFilterType,
    computerGroupIds: computerGroupFilterRest.computerGroupIds,
    thirdPartyPatchSeverities: item.thirdPartyPatchSeverities,
    includeDefinitionUpdate: item.includeDefinitionUpdate,
    includeDriverUpdates: item.includeDriverUpdates,
    deploymentCategory: DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST
  };
}

export function transformAutoPatchDeployementTestForServer(item) {
  let obj = {
    displayName: item.displayName,
    description: item.description,
    patchCategories: item.patchCategories,
    patchSeverities: item.patchSeverities,
    platform: item.platform,
    applicationType: item.applicationType,
    productIds: item.productIds,
    computerGroupFilterRest: {
      ...(item.computerFilterType === 'scope'
        ? transformAssetScopeForServer(item, true, true)
        : item.computerFilterType === 'specific_cg'
        ? { computerGroupIds: item.computerGroupIds }
        : {}),
      computerFilterType: item.computerFilterType
    },
    deploymentPolicyId: item.deploymentPolicyId,
    notifyTo: item.notifyEmailIds,
    deployAfterApprovalDays: item.deployAfterApprovalDays,
    thirdPartyPatchSeverities: item.thirdPartyPatchSeverities,
    includeDefinitionUpdate: item.includeDefinitionUpdate,
    includeDriverUpdates: item.includeDriverUpdates
  };

  obj = OmitBy(obj, (value) => value === null);

  return obj;
}
