import { ReactComponent as All } from './all.svg';
import { ReactComponent as Windows } from './windows.svg';
import { ReactComponent as Linux } from './linux.svg';
import { ReactComponent as Apple } from './apple.svg';
import { ReactComponent as Ubantu } from './ubantu.svg';

const icons = {
  platform_all: All,
  platform_windows: Windows,
  platform_linux: Linux,
  platform_mac: Apple,
  platform_macos: Apple,
  platform_ubuntu: Ubantu
};

export default icons;
