import { createContext, useContext, useMemo, useState } from 'react';

const PageHeaderContext = createContext();

const Provider = (props) => {
  const [pageHeader, setPageHeader] = useState(null);

  const value = useMemo(
    () => ({
      pageHeader,
      setPageHeader
    }),
    [pageHeader]
  );

  return <PageHeaderContext.Provider value={value}>{props.children}</PageHeaderContext.Provider>;
};

function contextHook() {
  // eslint-disable-next-line
  return useContext(PageHeaderContext);
}

export const PageHeader = {
  usePageHeader: contextHook,
  Provider: Provider
};
