import PackageUploader from '@/src/components/PackageUploader';
import PlatformPicker from '@/src/components/pickers/PlatformPicker';
import { Form, Input, Row, Col, Switch, Select } from 'antd';

export default function PackageForm({ item = {} }) {
  const osArchOptions = ['x64', 'x86', 'amd64'].map((i) => ({ value: i, label: i }));
  const pkgTypeOptions = {
    windows: ['exe', 'msi', 'zip', 'script'].map((i) => ({
      value: i,
      label: i
    })),
    linux: ['zip', 'script', 'application'].map((i) => ({
      value: i,
      label: i
    })),
    mac: ['zip', 'script', 'application'].map((i) => ({
      value: i,
      label: i
    }))
  };

  const pkgLocationOptions = [
    { label: 'Local Directory', value: 'local_dir' },
    { label: 'Shared Directory', value: 'shared_dir' },
    { label: 'Public URL', value: 'public_url' }
  ];

  const labelMap = {
    local_dir: 'Application File',
    shared_dir: 'Application Address',
    public_url: 'Application URL'
  };

  const form = Form.useFormInstance();

  return (
    <div className="flex flex-col min-h-0 h-full overflow-y-auto overflow-x-hidden">
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Application Name" name="displayName" rules={[{ required: true }]}>
            <Input placeholder="displayName" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Icon File" name="iconFile">
            <PackageUploader accept="image/*" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Self Service" name="selfServiceSupported" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input.TextArea placeholder="Description" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="OS" name="os" rules={[{ required: true }]}>
            <PlatformPicker
              disabled={item.id}
              onChange={(value) => {
                form.setFieldValue('os', value);
                form.setFieldValue('pkgType', undefined);
                form.setFieldValue('pkgLocation', undefined);
                form.setFieldValue('pkgFilePath', undefined);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Architecture" name="osArch" rules={[{ required: true }]}>
            <Select options={osArchOptions} placeholder="Please select" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Version" name="version" rules={[{ required: true }]}>
            <Input placeholder="ex. 1.0.0" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Application Type" name="pkgType" rules={[{ required: true }]}>
            <Select
              placeholder="Please select"
              onChange={(value) => {
                form.setFieldValue('pkgType', value);
                form.setFieldValue('pkgLocation', undefined);
                form.setFieldValue('pkgFilePath', undefined);
              }}
              options={form.getFieldValue('os') ? pkgTypeOptions[form.getFieldValue('os')] : []}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Application Location Type"
            name="pkgLocation"
            rules={[{ required: true }]}>
            <Select
              placeholder="Please select"
              options={
                form.getFieldValue('pkgType') === 'script'
                  ? pkgLocationOptions.filter((i) => i.value === 'local_dir')
                  : pkgLocationOptions
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={labelMap[form.getFieldValue('pkgLocation')] || 'Application File'}
            name="pkgFilePath"
            rules={[{ required: true }]}>
            {form.getFieldValue('pkgLocation') === 'local_dir' ? (
              <PackageUploader />
            ) : (
              <Input placeholder="Path of package" />
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Installation Command" name="installCommand">
            <Input placeholder="Installation Command" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Uninstallation Command" name="uninstallCommand">
            <Input placeholder="Uninstallation Command" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Upgrade Command" name="upgradeCommand">
            <Input placeholder="Upgrade Command" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
