import { ALL_CAPABILITES } from '@/src/constants/permissions';
import api from '@api';

export const AVAILABLE_CAPABILITIES = Object.keys(ALL_CAPABILITES).reduce(
  (prev, key) => [...prev, { key: key, label: key, children: ALL_CAPABILITES[key] }],
  []
);

const END_POINT = `/settings/permission`;

const transform = (item) => ({
  id: item.id,
  name: item.user_permission_name,
  description: item.description,
  capabilities: item.capabilities,
  createdAt: item.created_time
});

const transformForServer = (item) => ({
  ...(item.id ? { id: item.id } : {}),
  user_permission_name: item.name,
  description: item.description,
  capabilities: item.capabilities || []
});

const sortKeyMap = {
  name: 'user_permission_name',
  createdAt: 'created_time'
};

const searchableColumns = ['user_permission_name', 'description'];

export function getAllRoleApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getRoleApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function updateRole(item) {
  return api.put(`${END_POINT}/${item.id}`, transformForServer(item)).then((data) => {
    return getRoleApi(data.result);
  });
}

export function createRole(item) {
  return api.post(`${END_POINT}`, transformForServer(item)).then((data) => getRoleApi(data.result));
}

export function deleteRole(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}
