import { useState } from 'react';

import { Button, Tag, Segmented, Row } from 'antd';
import { CrudProvider } from '@/src/hooks/crud';
import {
  bulkDeletePackageApi,
  createPackageApi,
  deletePackageApi,
  getAllPackagesApi,
  updatePackageApi
} from '../packages';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';
import PackageForm from '../components/PackageForm';
import { User } from '@/src/components/pickers/UserPicker';
import Icon from '@/src/components/Icon';
import PackageLogo from '../components/PackageLogo';
import BulkDeleteButton from '@/src/components/BulkDeleteButton';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { PackageCard } from '@/src/modules/device-automation/components/PackageCard';

export default function Packages({ fetchFn, disabled }) {
  const [viewType, setViewType] = useState('list');

  const columns = [
    {
      title: 'ID',
      dataIndex: 'name',
      key: 'name',
      type: 'view_link'
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render({ record, update }) {
        return (
          <div className="flex items-center">
            <PackageLogo
              disabled={disabled}
              package={record}
              style={{ width: '30px' }}
              className="mr-2"
              onChange={(logo) => {
                update({
                  ...record,
                  iconFile: [logo]
                });
              }}
            />
            <div>{record.displayName}</div>
          </div>
        );
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      align: 'center',
      sortable: false,
      render({ record }) {
        return (
          <div className="text-xl">
            <Icon name={`platform_${record.os}`} />
          </div>
        );
      }
    },
    {
      title: 'Version',
      dataIndex: 'version',
      align: 'center',
      key: 'version',
      sortable: false
    },
    {
      title: 'Type',
      dataIndex: 'pkgType',
      align: 'center',
      key: 'pkgType',
      sortable: false,
      render({ record }) {
        return (
          <Tag
            color={'processing'}
            className="inline-flex items-center justify-center"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>
            {record.pkgType}
          </Tag>
        );
      }
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sortable: false,
      render({ record }) {
        return <User.Picker textOnly value={record.createdBy} disabled />;
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      type: 'datetime'
    },
    ...(disabled
      ? []
      : [
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            editPermissions: [constants.Update_Software],
            deletePermissions: [constants.Delete_Software]
          }
        ])
  ];

  return (
    <User.Provider>
      <CrudProvider
        columns={columns}
        defaultFormItem={{
          selfServiceSupported: true,
          os: 'linux',
          osArch: 'x64',
          pkgType: 'zip',
          pkgLocation: 'local_dir'
        }}
        resourceTitle="Application"
        disableFormScrolling
        allowSelection={!disabled}
        hasSearch
        fetchFn={fetchFn || getAllPackagesApi}
        deleteFn={deletePackageApi}
        createFn={createPackageApi}
        updateFn={updatePackageApi}
        disableColumnSelection={disabled}
        createAfterSlot={(_, { selectedItems, fetchData, setSelectedItems }) => (
          <BulkDeleteButton
            selectedItems={selectedItems}
            resourceTitle="Applications"
            className="ml-2"
            onConfirm={() =>
              bulkDeletePackageApi(selectedItems).then(() => {
                setSelectedItems([]);
                return fetchData();
              })
            }
          />
        )}
        createSlot={(createFn) =>
          !disabled && (
            <>
              <PermissionChecker permission={constants.Create_Software}>
                <Button type="primary" className="mr-2" onClick={createFn}>
                  Create
                </Button>
              </PermissionChecker>
              <Segmented
                value={viewType}
                onChange={(e) => setViewType(e)}
                options={[
                  { value: 'list', icon: <BarsOutlined /> },
                  { value: 'card', icon: <AppstoreOutlined /> }
                ]}
              />
            </>
          )
        }
        formFields={(item) => <PackageForm item={item} />}
        tableSlot={
          viewType === 'card'
            ? ({ data, edit, deleteItem }) => (
                <div className="px-2">
                  <Row gutter={16}>
                    {data.map((item) => (
                      <PackageCard
                        key={item.id}
                        record={item}
                        span={8}
                        disabled={disabled}
                        deleteItem={deleteItem}
                        edit={edit}
                      />
                    ))}
                  </Row>
                </div>
              )
            : null
        }
      />
    </User.Provider>
  );
}
