import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import Moment from 'moment';
import FindIndex from 'lodash/findIndex';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Icon from '@/src/components/Icon';
import CloneDeep from 'lodash/cloneDeep';
import { useAuth } from '@/src/hooks/auth';
import Crud from '../components/Crud';
import Bus from '../utils/emitter';
import PermissionChecker from '../components/PermissionChecker';
import exportAllData from '../utils/export';
import { downloadFile } from '../utils/download';

const CrudContext = createContext();

export const CrudProvider = ({
  children,
  fetchFn,
  columns,
  keyFn,
  hasSearch,
  resourceTitle,
  formFields,
  updateFn,
  createFn,
  deleteFn,
  defaultFormItem,
  prependColumns,
  appendColumns,
  autoRefresh,
  initCrud,
  ...props
}) => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [modal, contextHolder] = Modal.useModal();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [searchTerm, setSearchTerm] = useState(query.get('zSearch') || null);
  const [formItem, setFormItem] = useState(null);
  const [viewItem, setViewItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState(props.selectedItems || []);
  const [currentColumns, setColumns] = useState(buildColumns());
  const [rawColumns] = useState(buildColumns());
  const { formatDateTime } = useAuth();

  let currentData = [];

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: props.defaultPageSize || 20,
      showSizeChanger: true,
      showTotal: (total, range) => `showing ${range[0]}-${range[1]} of ${total} items`
    }
  });

  const fetchData = () => {
    setLoading(true);
    return fetchFn(
      (tableParams.pagination.current - 1) * tableParams.pagination.pageSize,
      tableParams.pagination.pageSize,
      { searchTerm, sort: tableParams.sorter }
    ).then((data) => {
      setData([...data.result]);
      currentData = data.result;
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.totalCount
        }
      });
    });
  };

  useEffect(() => {
    let initCrudCleanup;
    if (initCrud) {
      initCrudCleanup = initCrud({
        getCurrentData: () => (currentData.length ? currentData : data),
        updateSingeItem: updateLocalData
      });
    }
    if (query.get('zOperation') === 'create') {
      let formItem = {
        ...(defaultFormItem || {}),
        ...Array.from(query.keys())
          .filter((i) => i !== 'zOperation')
          .reduce((prev, key) => ({ ...prev, [key]: query.get(key) }), {})
      };
      if (props.onSetForm) {
        props.onSetForm(formItem);
      }
      setFormItem(CloneDeep(formItem));
    }
    let timeout;
    if (autoRefresh) {
      let refreshFn = () => {
        fetchData().then(() => {
          if (timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(refreshFn, autoRefresh);
        });
      };
      timeout = setTimeout(refreshFn, autoRefresh);
    }
    return () => {
      if (initCrudCleanup) {
        initCrudCleanup();
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handler = ({ id }) => {
      const item = data.find((d) => d.id === id);
      if (props.onSetForm) {
        props.onSetForm(item);
      }
      setFormItem(CloneDeep(item));
    };
    Bus.on('crud:edit-item', handler);
    return () => {
      Bus.off('crud:edit-item', handler);
    };
  }, [props, data]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [JSON.stringify(tableParams), searchTerm]);

  // useEffect(() => {
  //   setColumns(buildColumns());
  //   // eslint-disable-next-line
  // }, [columns]);

  const handleUpdateColumnSettings = (columns) => {
    const builtColumns = buildColumns().map((c) => {
      const givenColumn = columns.find((ic) => ic.key === c.key) || {};
      return {
        ...c,
        ...givenColumn,
        ...('isColumnHidden' in givenColumn ? { hidden: givenColumn.isColumnHidden } : {})
      };
    });
    setColumns(columns.map((c) => builtColumns.find((bc) => bc.key === c.key)).filter(Boolean));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sorter
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  function updateLocalData(item) {
    const index = FindIndex(currentData.length ? currentData : data, { id: item.id });
    if (index !== -1) {
      currentData = [
        ...(currentData.length ? currentData : data).slice(0, index),
        { ...item },
        ...(currentData.length ? currentData : data).slice(index + 1)
      ];
      setData(currentData);
    }
  }

  function handleFormSubmit(item) {
    setProcessing(true);
    item = { ...formItem, ...item };
    if (props.beforeSubmit) {
      item = props.beforeSubmit(item);
    }
    let p = item.id !== undefined && item.id !== null ? updateFn(item) : createFn(item);
    return p
      .then((response) => {
        if (item.id !== undefined && item.id !== null) {
          updateLocalData({ id: item.id, ...response });
          Bus.emit('crud:update-item', response);
        } else {
          fetchData();
        }
        if (props.onFormSubmitted) {
          props.onFormSubmitted();
        }
        setFormItem(null);
        if (query.get('zOperation') === 'create' && query.get('moveBack')) {
          navigate(-1);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  function deleteItem(deletingItem) {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${deletingItem[props.titleColumn || 'name']}?`,
      okText: 'Yes',
      cancelText: 'Cancel',
      centered: true,
      confirmLoading: true,
      destroyOnClose: true,
      maskClosable: false,
      okType: 'danger',
      onOk() {
        return deleteFn(deletingItem).then((response) => {
          fetchData();
        });
      }
    });
  }

  function handleResetFormItem() {
    if (formItem.id) {
      const formItemIndex = FindIndex(data, { id: formItem.id });
      if (formItemIndex !== -1) {
        setFormItem(CloneDeep(data[formItemIndex]));
      }
    } else {
      setFormItem(CloneDeep(defaultFormItem || {}));
    }
  }

  function buildColumn(c) {
    return {
      ...c,
      ...(c.key === 'actions'
        ? {
            fixed: 'right'
          }
        : {}),
      ...(c.resizable !== false ? { width: c.width || 100 } : { width: c.width }),
      ...(c.type === 'datetime'
        ? {
            render: (text, record) =>
              text && typeof text === 'string' ? text : text && formatDateTime(+text)
          }
        : {}),
      ...(c.type === 'view_link'
        ? {
            render: (text, record) =>
              text && (
                // eslint-disable-next-line
                <a
                  className="cursor-pointer"
                  // eslint-disable-next-line
                  href="javascript:;"
                  onClick={() =>
                    props.onView ? props.onView(record, setFormItem) : setViewItem(record)
                  }>
                  {text}
                </a>
              )
          }
        : {}),
      ...(c.key === 'actions' && !c.render
        ? {
            render(_, record) {
              return (
                <div>
                  {c.prependAction &&
                    c.prependAction({
                      record,
                      edit: setFormItem,
                      delete: deleteItem,
                      view: setViewItem,
                      update: handleFormSubmit
                    })}
                  {(
                    c.buttons ||
                    (c.canHaveButtons && c.canHaveButtons(record)) || ['edit']
                  ).includes('edit') ? (
                    <PermissionChecker permission={c.editPermissions}>
                      <Button
                        type="link"
                        onClick={() => {
                          if (props.onEdit) {
                            return props.onEdit(record, setFormItem);
                          }
                          if (props.onSetForm) {
                            props.onSetForm();
                          }
                          setFormItem(record);
                        }}
                        className="mr-2">
                        <Icon name="edit" style={{ fontSize: '1.1rem' }} />
                      </Button>
                    </PermissionChecker>
                  ) : null}
                  {(
                    c.buttons ||
                    (c.canHaveButtons && c.canHaveButtons(record)) || ['delete']
                  ).includes('delete') && record.id !== 0 ? (
                    <PermissionChecker permission={c.deletePermissions}>
                      <Button
                        danger
                        type="text"
                        onClick={() =>
                          props.onDelete ? props.onDelete(record) : deleteItem(record)
                        }>
                        <Icon name="delete" style={{ fontSize: '1.1rem' }} />
                      </Button>
                    </PermissionChecker>
                  ) : null}
                  {c.appendAction &&
                    c.appendAction({
                      record,
                      edit: setFormItem,
                      delete: deleteItem,
                      view: setViewItem,
                      update: handleFormSubmit
                    })}
                </div>
              );
            }
          }
        : {}),
      ...(c.key !== 'actions' && c.sortable !== false
        ? {
            sorter: {}
          }
        : {}),
      ...(c.render
        ? {
            render: (text, record, index) =>
              c.render({
                record,
                edit: setFormItem,
                delete: deleteItem,
                view: setViewItem,
                update: handleFormSubmit
              })
          }
        : {})
    };
  }

  function buildColumns() {
    return columns.map(buildColumn);
  }

  function exportData() {
    let type = 'csv';
    fetchFn(0, 999999, { searchTerm, sort: tableParams.sorter }).then((data) => {
      exportAllData(columns, data.result, type).then((blob) => {
        downloadFile(
          blob,
          undefined,
          `zirozen__${resourceTitle ? `${resourceTitle}__` : ''}${Moment().format(
            'DDMMYYYYHHMM'
          )}.${type}`
        );
      });
    });
  }

  const slotProps = {
    formItem,
    viewItem,
    edit: (item) => {
      if (props.onEdit) {
        return props.onEdit(item, setFormItem);
      }
      if (props.onSetForm) {
        props.onSetForm(item);
      }
      setFormItem(item);
    },
    closeDrawer: () => {
      setFormItem(null);
      setViewItem(null);
    },
    create: () => {
      if (props.onSetForm) {
        props.onSetForm(defaultFormItem || {});
      }
      setFormItem(CloneDeep(defaultFormItem || {}));
    },
    resetForm: handleResetFormItem,
    submitForm: handleFormSubmit,
    dataChange: handleTableChange,
    setSearchTerm: setSearchTerm,
    searchTerm,
    delete: deleteItem,
    loading: loading,
    processingForm: processing,
    data: data,
    rawColumns,
    columns: currentColumns,
    // prependColumns: (prependColumns || []).map((c) => ({ ...buildColumn(c), fixed: 'left' })),
    prependColumns: (prependColumns || []).map((c) => ({ ...buildColumn(c) })),
    appendColumns: (appendColumns || []).map((c) => ({ ...buildColumn(c) })),
    setColumns: handleUpdateColumnSettings,
    keyFn,
    hasSearch,
    resourceTitle,
    formFields,
    tableParams,
    selectedItems,
    setFormItem,
    setSelectedItems(data) {
      if (props.onChange) {
        props.onChange(data);
      }
      setSelectedItems(data);
    },
    fetchData,
    exportData
  };

  const value = useMemo(
    () => slotProps,
    // eslint-disable-next-line
    [
      formItem,
      viewItem,
      data,
      currentColumns,
      columns,
      setColumns,
      keyFn,
      hasSearch,
      resourceTitle,
      formFields,
      loading,
      tableParams,
      processing,
      selectedItems,
      searchTerm,
      setFormItem
    ]
  );
  return (
    <CrudContext.Provider value={value}>
      {children || <Crud {...props} />}
      {contextHolder}
    </CrudContext.Provider>
  );
};

export function useCrud() {
  return useContext(CrudContext);
}
