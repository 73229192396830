import { useEffect, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import Chunk from 'lodash/chunk';
import Dayjs from 'dayjs';
import { Popover, Button, Input, Divider, DatePicker } from 'antd';

export default function TimelinePicker({ value = {}, onChange, ...props }) {
  const allOptions = [
    {
      label: 'This Minute',
      value: 'This Minute'
    },
    {
      label: 'This Hour',
      value: 'This Hour'
    },
    {
      label: 'Today',
      value: 'Today'
    },
    {
      label: 'This Week',
      value: 'This Week'
    },
    {
      label: 'This Month',
      value: 'This Month'
    },
    {
      label: 'This Quarter',
      value: 'This Quarter'
    },
    {
      label: 'This Year',
      value: 'This Year'
    },
    {
      label: 'Previous Day',
      value: 'Previous Day'
    },
    {
      label: 'Previous Minute',
      value: 'Previous Minute'
    },
    {
      label: 'Previous Hour',
      value: 'Previous Hour'
    },
    {
      label: 'Previous 24 Hour',
      value: 'Previous 24 Hour'
    },
    {
      label: 'Previous Week',
      value: 'Previous Week'
    },
    {
      label: 'Previous Month',
      value: 'Previous Month'
    },
    {
      label: 'Previous Quarter',
      value: 'Previous Quarter'
    },
    {
      label: 'Previous Year',
      value: 'Previous Year'
    }
    // {
    //   label: 'Custom',
    //   value: 'custom'
    // }
  ];
  const DEFAULT_OPTIONS = Chunk(allOptions, Math.ceil(allOptions.length / 3));
  const [rangeValue, setRangeValue] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value.from && value.to) {
      setRangeValue([value.from, value.to]);
    }
  }, [value]);

  function handleRangeSelected(values, strs) {
    setRangeValue(strs);
  }

  function handleSelection(item) {
    if (onChange) {
      onChange({
        selected: item.value
      });
    }
    setOpen(false);
  }

  function confirmCustomTimeline() {
    if (onChange) {
      onChange({
        selected: 'custom',
        from: rangeValue[0],
        to: rangeValue[1]
      });
    }
    setOpen(false);
  }

  return (
    <Popover
      open={open}
      trigger="click"
      onOpenChange={setOpen}
      overlayStyle={{ minWidth: '500px' }}
      content={() => (
        <div className="flex flex-col">
          <div className="flex items-center">
            <div className="flex-1">
              <DatePicker.RangePicker
                showTime
                size="medium"
                className="w-full"
                value={[
                  rangeValue[0] ? Dayjs(rangeValue[0]) : undefined,
                  rangeValue[1] ? Dayjs(rangeValue[1]) : undefined
                ]}
                onChange={handleRangeSelected}
              />
            </div>
            <Button
              type="primary"
              htmlType="button"
              className="mx-4"
              onClick={confirmCustomTimeline}>
              Select
            </Button>
          </div>
          <Divider />
          <div className="flex justify-around">
            {DEFAULT_OPTIONS.map((ch, index) => (
              <div className="flex flex-col" key={index}>
                {ch.map((item) => (
                  <Button
                    key={item.value}
                    type="link"
                    className="my-2"
                    htmlType="button"
                    onClick={() => handleSelection(item)}>
                    {item.label}
                  </Button>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
      title="Select Timeline">
      <div className="flex items-center">
        <div className="flex-shrink-0 mr-2">
          {value.selected === 'custom'
            ? `${Dayjs(rangeValue[0]).format('YYYY-MM-DD hh:mm A')} - ${Dayjs(rangeValue[1]).format(
                'YYYY-MM-DD hh:mm A'
              )}`
            : ''}
        </div>
        <Input
          value={value.selected !== 'Custom' ? value.selected : ''}
          style={{ minWidth: '200px' }}
          readOnly
          prefix={<CalendarOutlined />}
          placeholder="Timeline"
        />
      </div>
    </Popover>
    // <Picker
    //   {...props}
    //   style={{ width: '100%' }}
    //   placeholder="Please Select"
    //   value={value}
    //   onChange={onChange}
    //   onSearch={search}
    //   options={options}
    // />
  );
}
