import Uniq from 'lodash/uniq';
import Duration from '@src/utils/duration';
import NumberFormat from '@src/utils/number-format';
import bytesPerSecToSize, { bytesToSize } from '@src/utils/bytes';
import { getAllowedUnit } from '@utils/unit-checker';
import buildConfig from './build-chart-config';
import TooltipBuilder from './tooltip-builder';
import colorPalettes from './chart-colors';

function buildYAxis(title, widgetProperty, unit, options = {}) {
  return {
    name: title,
    nameGap: 35,
    nameTextStyle: { color: 'var(--page-text-color)', fontWeight: '500' },
    // ...(['percent'].includes(unit)
    //   ? {
    //       max: 100,
    //     }
    //   : {}),
    axisLabel: {
      // rotation: isNaN(labelProperty.rotation)
      //   ? 0
      //   : labelProperty.rotation,
      ...(widgetProperty.ellipsisEnabled
        ? {
            ellipsis: '...'
          }
        : {}),
      formatter(value) {
        return NumberFormat(value, 2);
      },
      color: 'var(--page-text-color)',
      ...(['percent'].includes(unit)
        ? {
            formatter: `{value}%`
          }
        : {}),

      ...(['ms', 's'].includes(unit)
        ? {
            formatter: function (value) {
              return `${value < 0 ? '-' : ''}${Duration(
                value,
                unit === 'ms' ? 'milliseconds' : 'seconds',
                false,
                undefined,
                true
              )}`;
            }
          }
        : {}),

      ...(['bytes', 'bps'].includes(unit)
        ? {
            formatter: function (value) {
              if (value === 0) {
                return 0;
              }
              return unit === 'bps' ? bytesPerSecToSize(value) : bytesToSize(value);
            }
          }
        : {})
    },
    // ...(uniqUnits.length === 1 && ['bytes', 'bps'].includes(uniqUnits[0])
    //   ? {
    //       tickInterval: 1024,
    //     }
    //   : {}),
    // reversedStacks: false,
    splitLine: {
      lineStyle: {
        color: 'var(--border-color)'
      }
    },
    // ...(['percent'].includes(unit) ? { endOnTick: false } : {}),
    ...options
  };
}

export default function getWidgetChartCommonOptions(data, widgetProperties = {}, options = {}) {
  const units = data.map((series) => getAllowedUnit(series.counter));
  const hasUnknownUnits = units.filter((u) => !u).length > 0;
  const uniqUnits = hasUnknownUnits ? [] : Uniq(units);
  let yAxis;
  if (uniqUnits.length > 1 && uniqUnits.length <= 2) {
    const unitYAxisMap = {};
    yAxis = uniqUnits.map((unit, index) => {
      unitYAxisMap[unit] = index;
      const prop = index === 0 ? 'yAxisTitle' : 'zAxisTitle';
      return buildYAxis(
        widgetProperties[prop] !== undefined ? widgetProperties[prop] || unit : undefined,
        widgetProperties,
        unit,
        index > 0 ? { opposite: true } : {}
      );
    });
    data = data.map((series) => {
      const unit = getAllowedUnit(series.counter);
      return {
        ...series,
        yAxis: unitYAxisMap[unit]
      };
    });
  } else {
    yAxis = buildYAxis(
      widgetProperties.yAxisTitle !== undefined ? widgetProperties.yAxisTitle || '' : undefined,
      widgetProperties,
      uniqUnits.length === 1 ? uniqUnits[0] : undefined
    );
  }

  return buildConfig({
    timezone: options.timezone,
    ...(widgetProperties.colorPalette && colorPalettes[widgetProperties.colorPalette]
      ? {
          colors: colorPalettes[widgetProperties.colorPalette]
        }
      : {}),
    title: {
      text: null, // widgetName || '',
      align: 'center'
    },
    series: data,
    legend: {
      show: widgetProperties.legendEnabled
    },
    xAxis: {
      axisTick: {
        length: 0
      },
      name: widgetProperties.xAxisTitle || '',
      nameTextStyle: { color: 'var(--page-text-color)', fontWeight: '500' },
      nameGap: 30,
      axisLabel: {
        rotate: isNaN(widgetProperties.rotation) ? 0 : widgetProperties.rotation,
        ...(widgetProperties.ellipsisEnabled ? { ellipsis: '...' } : {})
      }
    },
    yAxis,
    tooltip: {
      borderWidth: 1,
      borderColor: 'var(--border-color)',
      className: 'shadow-lg hc-tooltip-bg',
      animation: false,
      borderRadius: 10,
      ...(options.outsideTooltip
        ? {
            appendToBody: true
          }
        : {}),
      // valueFormatter: (value) =>
      //   `${value}${
      //     options.unit || uniqUnits.length > 0 ? ` ${uniqUnits[0]}` : undefined
      //   }`,
      ...(data.length > 0 && options.sharedTooltip ? { shared: true } : {}),
      ...(options.omitTooltipFormatter
        ? {}
        : {
            formatter: function (value) {
              var tooltip = new TooltipBuilder(
                data,
                {
                  ...options,
                  ...(widgetProperties.tooltipFormatter
                    ? { tooltipFormatter: widgetProperties.tooltipFormatter }
                    : {})
                },
                value
              );
              return tooltip.html();
            }
          })
    },
    ...(options.serverSideZoom && options.hasDateTime ? { serverSideZoom: 10000 } : {})
  });
}
