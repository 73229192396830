import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import DeviceAutomationLayout from './layout/DeviceAutomationLayout';
import Deployments from './views/Deployments';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants';
import Bundles from './views/Bundle';
import Icon from '@/src/components/Icon';

const Packages = lazy(() => import(/* webpackChunkName: "device-automation" */ './views/Packages'));
const Configurations = lazy(() =>
  import(/* webpackChunkName: "device-automation" */ './views/Configurations')
);

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="automation" key="automation" element={<AppLayout />}>
      {/* if no route is defined navigate to about route */}
      <Route
        path="software-automation"
        element={
          <DeviceAutomationLayout
            routes={[
              {
                key: 'software-automation',
                label: (
                  <div className="flex items-center">
                    <Icon name="app" className="text-base" />
                    <span className="">App Catalog</span>
                  </div>
                )
              },
              {
                key: 'bundles',
                label: (
                  <div className="flex items-center">
                    <Icon name="app-bundle" className="text-base" />
                    <span className="">App Bundle</span>
                  </div>
                )
              },
              {
                key: 'deployments',
                label: (
                  <div className="flex items-center">
                    <Icon name="app-deployment" className="text-base" />
                    <span className="">App Deployment</span>
                  </div>
                )
              }
            ]}
          />
        }>
        <Route
          index
          path=""
          element={
            <PermissionChecker redirect permission={constants.View_Software}>
              <Packages />
            </PermissionChecker>
          }
        />
        <Route
          index
          path="bundles"
          element={
            <PermissionChecker redirect permission={constants.View_Software}>
              <Bundles type="package" />
            </PermissionChecker>
          }
        />
        <Route
          path="deployments"
          element={
            <PermissionChecker redirect permission={constants.View_Software}>
              <Deployments type="package" />
            </PermissionChecker>
          }
        />
      </Route>
      <Route
        path="configuration-automation"
        element={
          <DeviceAutomationLayout
            routes={[
              {
                key: 'configuration-automation',
                label: (
                  <div className="flex items-center">
                    <Icon name="configuration" className="text-base" />
                    <span className="">Config Catalog</span>
                  </div>
                )
              },
              {
                key: 'bundles',
                label: (
                  <div className="flex items-center">
                    <Icon name="configuration-bundle" className="text-base" />
                    <span className="">Config Bundle</span>
                  </div>
                )
              },
              {
                key: 'deployments',
                label: (
                  <div className="flex items-center">
                    <Icon name="configuration-deployment" className="text-base" />
                    <span className="">Config Deployment</span>
                  </div>
                )
              }
            ]}
          />
        }>
        <Route
          index
          path=""
          element={
            <PermissionChecker redirect permission={constants.View_Configuration}>
              <Configurations />
            </PermissionChecker>
          }
        />
        <Route
          index
          path="bundles"
          element={
            <PermissionChecker redirect permission={constants.View_Configuration}>
              <Bundles type="configuration" />
            </PermissionChecker>
          }
        />
        <Route
          path="deployments"
          element={
            <PermissionChecker redirect permission={constants.View_Configuration}>
              <Deployments type="configuration" />
            </PermissionChecker>
          }
        />
      </Route>
    </Route>
  );
}
