import { ReactComponent as Windows } from './windows.svg';
import { ReactComponent as Linux } from './linux.svg';
import { ReactComponent as centos } from './centos.svg';
import { ReactComponent as debian } from './debian.svg';
import { ReactComponent as fedora } from './fedora.svg';
import { ReactComponent as macos } from './macos.svg';
import { ReactComponent as opensuse } from './opensuse.svg';
import { ReactComponent as oracle } from './oracle.svg';
import { ReactComponent as redhat } from './redhat.svg';
import { ReactComponent as ubuntu } from './ubuntu.svg';
import { ReactComponent as linuxFlat } from './linux-flat.svg';

const icons = {
  windows: Windows,
  linux: Linux,
  centos,
  debian,
  fedora,
  macos,
  opensuse,
  oracle,
  redhat,
  ubuntu,
  'linux-flat': linuxFlat
};

export default icons;
