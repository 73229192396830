import React, { useEffect, useState } from 'react';
import Omit from 'lodash/omit';
import OrderBy from 'lodash/orderBy';
import FindIndex from 'lodash/findIndex';
import UniqBy from 'lodash/uniqBy';
import { ReactSortable } from 'react-sortablejs';
import { DragOutlined } from '@ant-design/icons';
import { Popover, Button, Checkbox, Divider } from 'antd';
import { UserPreference } from './UserPreference';
import Icon from './Icon';

export default function ColumnSelection({ columns, onChange, uniqueId, rawColumns }) {
  const { updateMyPreference, preference } = UserPreference.usePreference();
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(
    columns.map((c) => ({
      ...Omit(c, ['hidden']),
      isColumnHidden: c.hidden
    }))
  );

  function setUpdatedColumns(checked, key) {
    const index = FindIndex(items, { key });
    if (index !== -1) {
      const action = columns.find((c) => c.key === 'actions');
      setItems(
        UniqBy(
          [
            ...items.slice(0, index),
            { ...items[index], isColumnHidden: checked },
            ...items.slice(index + 1),
            ...(action ? [action] : [])
          ],
          'key'
        )
      );
    }
  }

  function mergeColumns(givenColumns) {
    const availableColumnKeys = givenColumns.map((c) => c.key);
    return OrderBy(
      UniqBy(
        columns.map((c) =>
          availableColumnKeys.includes(c.key)
            ? {
                ...givenColumns.find((ic) => ic.key === c.key),
                order: availableColumnKeys.indexOf(c.key)
              }
            : {
                ...Omit(c, ['hidden']),
                isColumnHidden: c.hidden
              }
        ),
        'key'
      ),
      'order'
    ).map((d) => Omit(d, ['order']));
  }

  useEffect(() => {
    if (preference[uniqueId] && preference[uniqueId].columns) {
      const finalColumns = mergeColumns(
        preference[uniqueId].columns.map((c) => ({
          ...Omit(c, ['hidden']),
          isColumnHidden: c.hidden
        }))
      );
      setItems(finalColumns);
      onChange(finalColumns);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (preference[uniqueId] && preference[uniqueId].columns) {
      const finalColumns = mergeColumns(
        preference[uniqueId].columns.map((c) => ({
          ...Omit(c, ['hidden']),
          isColumnHidden: c.hidden
        }))
      );
      setItems(finalColumns);
      onChange(finalColumns);
    }
    // eslint-disable-next-line
  }, [preference]);

  function handleSelectionConfirm() {
    const columns = UniqBy(
      items.map((i) => ({ ...i, hidden: i.isColumnHidden, isColumnHidden: i.isColumnHidden })),
      'key'
    );
    updateMyPreference({
      [uniqueId]: { columns }
    }).then(() => {
      onChange(mergeColumns(columns));
      setOpen(false);
    });
  }

  function handleReset() {
    // const currentColumns = UniqBy(
    //   columns.map((i) => ({ ...i, hidden: i.hidden, isColumnHidden: i.hidden })),
    //   'key'
    // );
    updateMyPreference({
      [uniqueId]: { columns: null }
    }).then(() => {
      onChange(rawColumns);
      setOpen(false);
    });
  }

  return (
    <Popover
      open={open}
      className="ml-auto"
      overlayClassName="picker-overlay"
      onOpenChange={setOpen}
      placement="bottomRight"
      trigger="click"
      content={() => {
        return (
          <div className="flex flex-col min-h-0 h-full">
            <div className="flex flex-1 flex-col min-h-0 overflow-auto">
              <ReactSortable
                className="flex flex-col flex-1 min-h-0"
                handle=".sort-icon"
                list={items}
                setList={(items) => setItems(items)}>
                {items
                  .filter((c) => c.key !== 'actions' && c.selectable !== false)
                  .map((c) => (
                    <span key={c.key} className="my-1" onClick={(e) => e.stopPropagation()}>
                      <DragOutlined className="sort-icon mr-2 cursor-move" />
                      <Checkbox
                        disabled={c.disabled}
                        className="mr-1"
                        checked={c.isColumnHidden !== true}
                        onChange={(e) => setUpdatedColumns(!e.target.checked, c.key)}>
                        {c.title}
                      </Checkbox>
                    </span>
                  ))}
              </ReactSortable>
            </div>
            <Divider style={{ margin: 0 }} />
            <div style={{ padding: 8 }} className="text-right">
              <Button type="primary" size="small" onClick={handleSelectionConfirm}>
                Apply
              </Button>
              <Button ghost className="ml-1" type="primary" size="small" onClick={handleReset}>
                Reset
              </Button>
              <Button
                type="primary"
                ghost
                className="ml-1"
                size="small"
                onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </div>
          </div>
        );
      }}>
      <Button type="link">
        <Icon name="table_settings" className="text-xl text-color" />
      </Button>
    </Popover>
  );
}
