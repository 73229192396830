import { getAllOrganizationApi } from '@/src/modules/settings/api/user-management/organization';
import Picker from './Picker.jsx';
import buildPicker from './pickerBuilder';

const { Provider, contextHook } = buildPicker(getAllOrganizationApi, OrganizationPicker);

function OrganizationPicker({ value, onChange, ...props }) {
  const { filteredOptions, allOptions, search } = contextHook();
  if (props.disabled && props.textOnly) {
    return allOptions.has(value) ? allOptions.get(value).label : null;
  }
  return (
    <Picker
      {...props}
      style={{ width: '100%' }}
      placeholder="Please Select"
      value={value}
      onChange={onChange}
      onSearch={search}
      options={filteredOptions || allOptions}
    />
  );
}

export const Organization = {
  useOrganizations: contextHook,
  Provider: Provider,
  Picker: OrganizationPicker
};
