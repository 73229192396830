import { cloneElement } from 'react';

export default function ProductSetupStep({ children, ...props }) {
  return (
    <div className="flex flex-col">
      <h3 className="text-center">{props.section.title}</h3>
      {cloneElement(children, props)}
    </div>
  );
}
