import { getAllRoleApi } from '@/src/modules/settings/api/user-management/roles';
import Picker from './Picker.jsx';
import buildPicker from './pickerBuilder';

const { Provider, contextHook } = buildPicker(getAllRoleApi, RolePicker);

function RolePicker({ value, onChange, ...props }) {
  const { filteredOptions, allOptions, search } = contextHook();
  if (props.disabled && props.textOnly) {
    return allOptions.has(value) ? allOptions.get(value).label : null;
  }
  return (
    <Picker
      {...props}
      style={{ width: '100%' }}
      placeholder="Please Select"
      value={value}
      onChange={onChange}
      onSearch={search}
      options={filteredOptions || allOptions}
    />
  );
}

export const Role = {
  useRoles: contextHook,
  Provider: Provider,
  Picker: RolePicker
};
