import { Col, Row, Popover } from 'antd';
import Capitalize from 'lodash/capitalize';
import Icon from '@/src/components/Icon';

export default function DeploymentPolicyShower({ policyContext, policyId }) {
  function getFormatedValues(str) {
    return Capitalize((str || '').replaceAll('_', ' '));
  }

  return (
    <>
      {policyId && policyContext ? (
        <Row gutter={0} className="p-4  m-0 rounded-lg ant-tag  ant-tag-blue bordered flex">
          <Col span={24}>
            <span className="mr-2">Deployment Policy Info</span>
            {policyId && policyContext ? (
              <>
                <Popover
                  placement="right"
                  title={''}
                  content={() => {
                    return (
                      <>
                        <Row gutter={16} style={{ width: '400px' }}>
                          <Col span={12}>Type:</Col>
                          <Col span={12}> {getFormatedValues(policyContext.type)}</Col>
                          <Col span={12}>Supported module:</Col>
                          <Col span={12}> {getFormatedValues(policyContext.refModel)}</Col>

                          {policyContext.initiateDeploymentOn ? (
                            <>
                              <Col span={12}>Initiate Deployment On :</Col>
                              <Col span={12}>
                                {getFormatedValues(policyContext.initiateDeploymentOn)}
                              </Col>
                            </>
                          ) : null}

                          {policyContext.executionInterval ? (
                            <>
                              <Col span={12}>Interval :</Col>
                              <Col span={12} className="flex">
                                {policyContext.executionInterval.value}
                                {policyContext.executionInterval.unit}
                              </Col>
                            </>
                          ) : null}

                          {policyContext.deploymentDays ? (
                            <>
                              <Col span={12}>Deployment Days :</Col>
                              <Col span={12} className="whitespace-pre-line">
                                {(policyContext.deploymentDays || [])
                                  .map(getFormatedValues)
                                  .join(' ')}
                              </Col>
                            </>
                          ) : null}
                          {policyContext.deploymentTimeFrom ? (
                            <>
                              <Col span={12}>Deployment Time From :</Col>
                              <Col span={12}> {policyContext.deploymentTimeFromFormated}</Col>
                            </>
                          ) : null}

                          {policyContext.deploymentTimeTo ? (
                            <>
                              <Col span={12}>Deployment Time To :</Col>
                              <Col span={12}> {policyContext.deploymentTimeToFormated}</Col>
                            </>
                          ) : null}
                        </Row>
                      </>
                    );
                  }}>
                  <Icon name="info-circle" className="text-primaray cursor-pointer" />
                </Popover>
              </>
            ) : null}
          </Col>

          {policyContext.type ? (
            <>
              <Col span={6}>Type :</Col>
              <Col span={6} className="flex">
                {getFormatedValues(policyContext.type)}
              </Col>
            </>
          ) : null}

          {policyContext.executionInterval ? (
            <>
              <Col span={6}>Interval :</Col>
              <Col span={6} className="flex">
                {policyContext.executionInterval.value}
                {policyContext.executionInterval.unit}
              </Col>
            </>
          ) : null}
          {policyContext.deploymentTimeFrom ? (
            <>
              <Col span={6} style={{ fontSize: '11px' }}>
                Deployment Time From :
              </Col>
              <Col span={6} style={{ fontSize: '11px' }}>
                {policyContext.deploymentTimeFromFormated}
              </Col>
            </>
          ) : null}
          {policyContext.deploymentTimeTo ? (
            <>
              <Col span={6} style={{ fontSize: '11px' }}>
                Deployment Time To :
              </Col>
              <Col span={6} style={{ fontSize: '11px' }}>
                {policyContext.deploymentTimeToFormated}
              </Col>
            </>
          ) : null}
        </Row>
      ) : (
        <span />
      )}
    </>
  );
}
