import { createContext, useEffect, useContext, useMemo, useState } from 'react';
import { Spin } from 'antd';
import {
  getMyPreferenceApi,
  updateMyPreferenceApi
} from '../modules/settings/api/user-management/users';

const UserPreferenceContext = createContext();

const Provider = (props) => {
  const [loading, setLoading] = useState(true);
  let [preference, setPreference] = useState({});

  useEffect(() => {
    getMyPreferenceApi().then((preference) => {
      setPreference(preference);
      if (props.onDataReceived) {
        props.onDataReceived(preference);
      }
      setTimeout(() => {
        setLoading(false);
      });
    });
    // eslint-disable-next-line
  }, []);

  function updateMyPreference(data) {
    return updateMyPreferenceApi({
      ...preference,
      ...data
    }).then((preference) => setPreference(preference));
  }

  // eslint-disable-next-line
  const value = useMemo(() => ({
    updateMyPreference,
    preference
  }));

  return (
    <UserPreferenceContext.Provider value={value}>
      {loading ? (
        <div className="flex-1 flex items-center justify-center h-full">
          <Spin spinning />
        </div>
      ) : (
        props.children
      )}
    </UserPreferenceContext.Provider>
  );
};

function contextHook() {
  // eslint-disable-next-line
  return useContext(UserPreferenceContext);
}

export const UserPreference = {
  usePreference: contextHook,
  Provider: Provider
};
