import { Navigate } from 'react-router-dom';
import { useAuth } from '@hooks/auth';
import { useLocation } from 'react-router';

const Routes = ['/login'];

const isPublicRoute = (route) => {
  return Boolean(Routes.find((r) => r.indexOf(route.pathname) >= 0));
};

export const PublicGuard = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();
  const routeIsPublic = isPublicRoute(location);
  if (user && routeIsPublic) {
    // user is not authenticated
    return <Navigate to="/dashboard" replace />;
  } else if (!user && !isPublicRoute(location)) {
    return <Navigate to="/login" replace />;
  }
  return children;
};
