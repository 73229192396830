import { Navigate } from 'react-router-dom';
import { Permissions } from './Permissions';

export default function PermissionChecker({
  children,
  redirect = false,
  hasAny = false,
  permission = []
}) {
  const { hasPermission } = Permissions.usePermission();

  const canAccess = hasPermission(permission, hasAny);

  if (canAccess) {
    return children;
  }
  if (redirect) {
    return <Navigate to="/unauthorized" />;
  }

  return null;
}
