import { getAllDepartmentApi } from '@/src/modules/settings/api/user-management/department';
import Picker from './Picker.jsx';
import buildPicker from './pickerBuilder';

const { Provider, contextHook } = buildPicker(getAllDepartmentApi, DepartmentPicker, (item) => ({
  value: item.id,
  label: item.name,
  organization: item.organization
}));

function DepartmentPicker({ value, onChange, ...props }) {
  const { filteredOptions, allOptions, search } = contextHook();
  if (props.disabled && props.textOnly) {
    return allOptions.has(value) ? allOptions.get(value).label : null;
  }
  return (
    <Picker
      {...props}
      style={{ width: '100%' }}
      placeholder="Please Select"
      value={value}
      onChange={onChange}
      onSearch={search}
      options={
        props.organization !== undefined
          ? (filteredOptions || Array.from(allOptions.values())).filter(
              ({ organization }) => organization === props.organization
            )
          : props.hideWithoutOrganization
          ? []
          : filteredOptions || allOptions
      }
    />
  );
}

export const Department = {
  useDepartments: contextHook,
  Provider: Provider,
  Picker: DepartmentPicker
};
