import api from '@api';
import generateId from '@/src/utils/id';
import { transformTimelineForServer } from '../../dashboard/widget-api';

const END_POINT = `/inventory/alert`;

export const transform = (item) => ({
  id: generateId(),
  alertId: item.alert_id,
  alert: item.alert,
  assetId: item.asset_id,
  asset: item.asset,
  severity: item.severity,
  module: item.module,
  value: item.value,
  threat_context: item.threat_context,
  attribute: item.attribute,
  message: item.alert_message,
  createdAt: item.created_time
});

const sortKeyMap = {
  message: 'alert_message',
  createdAt: 'created_time'
};

const searchableColumns = [
  'alert',
  'asset',
  'severity',
  'module',
  'value',
  'alert_message',
  'attribute'
];

export function getAllAlertsApi(
  offset,
  size,
  sortFilter,
  { severity, moduleName, assetId, timeline }
) {
  return api
    .post(`${END_POINT}`, {
      offset,
      size,
      ...((timeline || {}).selected
        ? {
            timeline: transformTimelineForServer(timeline)
          }
        : {}),
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      ...(severity && severity !== 'All' ? { severity } : {}),
      ...(moduleName && moduleName !== 'All' ? { module: moduleName } : {}),
      ...(assetId && assetId !== 'All' ? { id: assetId } : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getSeverityWiseCountApi(assetId, filters = {}) {
  return api
    .post(`${END_POINT}/severities`, {
      ...(assetId && assetId !== 'All' ? { id: assetId } : {}),
      ...((filters.timeline || {}).selected
        ? {
            timeline: transformTimelineForServer(filters.timeline)
          }
        : {})
    })
    .then((data) => {
      return Object.keys(data.result || {})
        .filter((key) => key !== 'total_count')
        .map((key) => ({
          severity: key,
          count: data.result[key]
        }));
    });
}

export function getModuleWiseCountApi(assetId, filters = {}) {
  return api
    .post(`${END_POINT}/modules`, {
      ...(assetId && assetId !== 'All' ? { id: assetId } : {}),
      ...((filters.timeline || {}).selected
        ? {
            timeline: transformTimelineForServer(filters.timeline)
          }
        : {})
    })
    .then((data) => {
      const total = Object.keys(data.result || {})
        .filter((key) => key !== 'total_count')
        .reduce((total, key) => total + data.result[key], 0);
      return [{ moduleName: 'All', count: total }].concat(
        Object.keys(data.result || {})
          .filter((key) => key !== 'total_count')
          .map((key) => ({
            moduleName: key,
            count: data.result[key]
          }))
      );
    });
}

export function getAlertThreatContextApi(threat_context) {
  return api
    .post(`/settings/action/threat`, {
      threat_context
    })
    .then(({ result }) => {
      return {
        type: result.threat_intelligence_type,
        context: result.result
      };
    });
}

export function refreshAlertThreatContextApi(threat_context) {
  return api
    .post(`/settings/action/threat/refresh`, {
      threat_context
    })
    .then(({ result }) => {
      return {
        type: result.threat_intelligence_type,
        context: result.result
      };
    });
}
