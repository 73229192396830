import Capitalize from 'lodash/capitalize';
import { Select } from 'antd';

export default function PatchCategoryPicker({ textOnly, ...props }) {
  const categoryOptions = [
    'updates',
    'tools',
    'feature_packs',
    'service_packs',
    'update_rollups',
    'definition_updates',
    'critical_updates',
    'security_updates',
    'hotfix',
    'upgrades'
  ].map((i) => ({
    label: i
      .split('_')
      .map((i) => Capitalize(i))
      .join(' '),
    value: i
  }));
  if (textOnly) {
    const selected_category = categoryOptions.find((s) => s.value === props.value);
    return (selected_category || {}).label || '---';
  }
  return <Select options={categoryOptions} placeholder="Select" {...props} />;
}
