// import { useLocation } from 'react-router-dom';
// import CurrentBreadCrumb from './CurrentBreadCrumb';
import Icon from './Icon';

export default function PageHeadingContent({ icon, title, children }) {
  // const route = useLocation();
  return (
    <div className="py-1 bg-page-background inline-flex px-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {icon ? (
            typeof icon === 'function' ? (
              icon()
            ) : (
              <div className="flex bg-table-header px-2 py-2 rounded-md items-center justify-center">
                <Icon name={icon} className="text-primary-500" style={{ fontSize: '1.5rem' }} />
              </div>
            )
          ) : null}
          <div className="flex flex-col">
            <span className="ml-2 text-color font-semibold text-lg">{title}</span>
            {/* <small className="ml-2">
              {/\/dashboard\//.test(route.pathname) === false ? <CurrentBreadCrumb /> : null}
            </small> */}
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-2">{children}</div>
        </div>
      </div>
    </div>
  );
}
