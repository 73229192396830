import { useSettingsLayout } from '../layout/SettingsLayout';

import { Divider } from 'antd';

export default function SettingHeading({ icon, title, children }) {
  const layout = useSettingsLayout();

  return (
    <div className="flex flex-col w-full flex-none">
      <div className="py-1  bg-page-background inline-flex   flex-col mt-3">
        <div className="flex justify-between items-center pl-4">
          <div className="flex items-center">
            <div className="flex flex-col">
              <span className="text-color font-semibold text-lg">
                {layout?.activeMenu?.label || ''}
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="mr-2">{children}</div>
          </div>
        </div>
      </div>
      <Divider className="my-3" />
    </div>
  );
}
