import Uploader from '@/src/components/Uploader';
import { Form, Row, Col, Input, Button, Radio } from 'antd';
import { useState } from 'react';
import {
  addBlackListedIpApi,
  reloadBlackListedIpApi
} from '../../api/system-settings/threat-database';
import { useLayout } from '@/src/layouts/Layout';

export default function BlackListedIPForm() {
  const [processing, setProcessing] = useState(false);
  const [type, setType] = useState('single_ip');
  const [operationStatus, setOperationStatus] = useState({});
  const [isReloading, setIsReloading] = useState(false);
  const layout = useLayout();

  const handleReload = () => {
    setIsReloading(true);
    reloadBlackListedIpApi()
      .then(({ result }) => {
        layout.notification.success({
          message: 'Success!',
          description: result
        });
      })
      .finally(() => {
        setIsReloading(false);
      });
  };

  const [form] = Form.useForm();

  const submitForm = (values) => {
    setProcessing(true);
    addBlackListedIpApi(values)
      .then(({ result }) => {
        form.resetFields();
        setOperationStatus({
          status: 'success',
          message: result
        });
        setProcessing(false);
      })
      .catch((e) => {
        setOperationStatus({
          status: 'error',
          message: e.response.data['response-message']
        });
        setProcessing(false);
      });
  };

  return (
    <div className="flex flex-col min-h-0">
      <Form
        layout="vertical"
        form={form}
        className="h-full"
        onFinish={submitForm}
        onValuesChange={(values) => setType(values.type)}
        initialValues={{ type: 'single_ip' }}>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item name="type" label="">
              <Radio.Group
                options={[
                  { label: 'Add IP', value: 'single_ip' },
                  { label: 'Upload CSV File', value: 'csv_file' }
                ]}
              />
            </Form.Item>
          </Col>
          {(form.getFieldValue('type') || type) === 'single_ip' ? (
            <>
              <Col span={12}>
                <Form.Item
                  label="IP Address"
                  name="ip"
                  rules={[
                    { required: true },
                    {
                      pattern: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
                      message: 'Please enter a valid IP Address'
                    }
                  ]}>
                  <Input placeholder="Ex. 20.20.20.20" />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col span={24}>
              <div>
                <h4>Download Sample CSV</h4>
                <a href="/sample_ip.csv">sample_ip.csv</a>
              </div>
              <Form.Item
                required={false}
                label=" "
                name="csv_file"
                className="flex-1"
                rules={[{ required: true }]}>
                <Uploader
                  message="Please select CSV file containing Malicious Hashes"
                  accept="text/csv"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24} />
          {operationStatus.status ? (
            <Col span={24}>
              <div className={operationStatus.status === 'error' ? 'text-danger' : 'text-success'}>
                {operationStatus.message}
              </div>
            </Col>
          ) : null}
          <Col
            span={(form.getFieldValue('type') || type) === 'single_ip' ? 12 : 24}
            className="text-left">
            <Button type="primary" loading={isReloading} className="mr-2" onClick={handleReload}>
              Reload
            </Button>
            <Button type="primary" loading={processing} htmlType="submit" className="mr-2">
              {(form.getFieldValue('type') || type) === 'single_ip'
                ? 'Add Blacklisted IP'
                : 'Upload'}
            </Button>
            <Button type="primary" ghost htmlType="reset">
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
