import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';
import api from '@api';
import { useAuth } from '../hooks/auth';

export default function PackageUploader({
  children,
  onChange,
  value,
  message,
  onUploaded,
  accept = '*/*',
  useDragger = false,
  multiple = false,
  ...props
}) {
  const { token } = useAuth();
  const uploaderProps = {
    accept,
    multiple,
    fileList: (value || []).map((v) => ({
      ...v,
      ...(v.url
        ? {
            url: `${v.url}${token.access_token}`
          }
        : v.response
        ? {
            url: v.response.url
          }
        : {})
    })),
    customRequest({ onProgress, file, onSuccess }) {
      const headers = api.getHeaders();
      const formData = new FormData();
      formData.append('file', file);
      return api
        .getNewClient(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}/patch`
        )
        .post(`/upload`, formData, {
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
          headers: {
            Authorization: headers.common.Authorization,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(({ data }) => {
          const obj = {
            ref: data.refName,
            name: data.realName,
            url: `/api/patch/download/${data.refName}?mid=${token.access_token}`,
            status: 'done'
          };

          onSuccess(obj);

          if (onUploaded) {
            onUploaded(obj);
          }
        });
    },
    onChange(data) {
      if (!multiple) {
        for (let i = 0; i < data.fileList.length - 1; i++) {
          data.fileList.splice(i, 1);
        }
      }
      if (onChange) {
        onChange(data.fileList);
      }
    }
  };

  return useDragger ? (
    <Upload.Dragger {...uploaderProps} {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        {message} {message ? <br /> : null} Support for a {multiple ? 'File' : 'Single File'}{' '}
        upload.
      </p>
    </Upload.Dragger>
  ) : (
    <Upload {...uploaderProps} {...(multiple ? {} : { maxCount: 1 })} {...props}>
      {children || <Button icon={<UploadOutlined />}>Upload {multiple ? '' : '(Max: 1)'}</Button>}
    </Upload>
  );
}
