import api from '@api';

export function addMaliciousHashApi(data) {
  return api.post(`/settings/hash`, {
    ...(data.type === 'single_hash'
      ? {
          hash: data.hash,
          hash_name: data.hash_name
        }
      : {
          ref_name: data.csv_file[0].ref || data.csv_file[0].response.ref,
          file_name: data.csv_file[0].ref || data.csv_file[0].response.name
        })
  });
}

export function reloadMaliciousHashApi() {
  return api.post(`/settings/hash/reload`);
}

export function addBlackListedIpApi(data) {
  return api.post(`/settings/ip/blacklist`, {
    ...(data.type === 'single_ip'
      ? {
          ipAddress: data.ip
        }
      : {
          ref_name: data.csv_file[0].ref || data.csv_file[0].response.ref,
          file_name: data.csv_file[0].ref || data.csv_file[0].response.name
        })
  });
}

export function reloadBlackListedIpApi() {
  return api.post(`/settings/ip/reload`);
}
