import { Form, Input } from 'antd';

export default function OrganizationForm() {
  return (
    <>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item label="Description" name="description" rules={[{ required: true }]}>
        <Input.TextArea placeholder="Description" />
      </Form.Item>
    </>
  );
}
