export function transformPackageIcon(data) {
  return data.iconFile && data.iconFile.refName
    ? [
        {
          ref: data.iconFile.refName,
          name: data.iconFile.realName,
          uid: data.iconFile.refName,
          url: `/api/patch/download/${data.iconFile.refName}?mid=`,
          status: 'done'
        }
      ]
    : undefined;
}
