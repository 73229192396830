import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';

const RootkitLayout = lazy(() =>
  import(/* webpackChunkName: "rootkit" */ './layout/RootkitLayout')
);
const RootkitRules = lazy(() => import(/* webpackChunkName: "rootkit" */ './views/RootkitRules'));
const RootkitRuleDetail = lazy(() =>
  import(/* webpackChunkName: "rootkit" */ './views/RootkitRuleDetail')
);

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="rootkit" key="rootkit" element={<AppLayout />}>
      <Route element={<RootkitLayout />}>
        {/* if no route is defined navigate to about route */}
        <Route index element={<Navigate replace to="rules" />}></Route>
        <Route path="rules" element={<RootkitRules />}></Route>
        <Route
          path="alerts"
          element={
            <PermissionChecker permission={constants.View_Root_Kit} redirect>
              <RootkitRuleDetail />
            </PermissionChecker>
          }></Route>
      </Route>
    </Route>
  );
}
