import { Form, Input } from 'antd';
import { Organization } from '@/src/components/pickers/OrganizationPicker.jsx';

export default function DepartmentForm() {
  return (
    <>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item label="Description" name="description" rules={[{ required: true }]}>
        <Input.TextArea placeholder="Description" />
      </Form.Item>
      <Form.Item label="Organization" name="organization" rules={[{ required: true }]}>
        <Organization.Picker />
      </Form.Item>
    </>
  );
}
