// src/components/ExternalLink.jsx
import React from 'react';

const ExternalLink = ({ to, children, className, url, ...props }) => {
  const handleClick = (e) => {
    e.preventDefault();
    let link = `${window.location.origin}${to}`;
    if (url) {
      link = url;
    }
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <a href={to} onClick={handleClick} className={className} {...props}>
      {children}
    </a>
  );
};

export default ExternalLink;
