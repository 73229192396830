export function isUnitConvertible(counter) {
  if (/\.status(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return true;
  }
  if (/\.bytes(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return true;
  }
  if (
    /\.(?:ms|time|duration|sec|seconds)(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)
  ) {
    if (/bytes\.per\.sec/.test(counter)) {
      return true;
    } else if (/per\.(?:ms|time|duration|sec|seconds)/.test(counter)) {
      return false;
    } else {
      return true;
    }
  }
  return /\.(?:percent(?:age)?|bytes\.per\.sec|bytes|hz)|dbm(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(
    counter
  );
}

export function getAllowedUnit(counter = '') {
  if (/\.(?:percent(?:age)?)(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return `percent`;
  } else if (/\.dbm(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return 'dBm';
  } else if (/\.hz(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return 'hz';
  } else if (/\.(?:bytes\.per\.sec)(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return 'bps';
  } else if (/\.bytes(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)) {
    return 'bytes';
  } else if (
    /\.(?:ms|time|duration|sec|seconds)(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter)
  ) {
    if (/per\.(?:ms|time|duration|sec|seconds)/.test(counter)) {
      return undefined;
    }
    return /\.ms(?:\.(?:avg|sum|last|min|max|sparkline))?$/.test(counter) ? 'ms' : 's';
  }
  return undefined;
}

export function extractUnitAndValue(value, valueDigits = undefined) {
  if (value === undefined || value === null) {
    return {};
  }
  if (/^\d+(\.\d+)?\s*$/.test(value)) {
    if (valueDigits) {
      value = parseFloat(value).toFixed(valueDigits);
    }
    return {
      value: value
    };
  }
  let extractedValue = value.replace(/^(-?\d+(?:\.\d+)?)\s?(\/\w+|\w+|%|k|M|G|T|P|E)$/, '$1');
  if (valueDigits) {
    if (/\d+\.\d+/.test(extractedValue)) {
      extractedValue = parseFloat(extractedValue).toFixed(valueDigits);
    }
  }
  const extractedUnit = value.replace(/^-?\d+(?:\.\d+)?\s?(\/\w+|\w+|%|k|M|G|T|P|E)$/, '$1');
  return {
    value: extractedValue,
    unit: extractedUnit
  };
}
