import { useEffect } from 'react';
import { PageHeader } from '../components/PageHeader';
import PageHeadingContent from './PageHeadingContent';

export default function PageHeading({ appendToRoot = true, ...props }) {
  const { setPageHeader } = PageHeader.usePageHeader();

  useEffect(() => {
    if (appendToRoot) {
      setPageHeader(<PageHeadingContent {...props} />);
    }
    // eslint-disable-next-line
  }, [props.title, props.icon, props.children]);

  if (!appendToRoot) {
    return <PageHeadingContent {...props} />;
  }
  return null;
}
