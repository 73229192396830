import HumanizeDuration from 'humanize-duration';

const abbrHumanizer = HumanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'Y',
      mo: () => 'M',
      w: () => 'W',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms'
    }
  }
});

export default function duration(
  value,
  initialUnit = 'seconds',
  fullLength = false,
  units = ['y', 'mo', 'd', 'h', 'm'],
  allowDecimal = false
) {
  // eslint-disable-next-line
  if (typeof value === 'bigint') {
    value = Number(value);
  }
  if (initialUnit === 'seconds') {
    value = value * 1000;
  }
  if (value === 0) {
    return '0';
  }

  const options = {
    largest: 8,
    delimiter: '   ',
    round: value > 1000 ? true : !allowDecimal,
    ...(units ? { units } : {})
  };
  if (fullLength) {
    return HumanizeDuration(value, options);
  }
  return abbrHumanizer(value, options);
}
