import { transformPackageIcon } from '@/src/helpers/package-icon';
import api from '@api';

const END_POINT = `/patch/package`;

const transform = (item) => ({
  id: item.id,
  name: item.name,
  displayName: item.displayName,
  description: item.description,
  os: item.os,
  osArch: item.osArch,
  version: item.version,
  pkgType: item.pkgType,
  pkgLocation: item.pkgLocation,
  pkgFilePath:
    item.pkgLocation === 'local_dir'
      ? [
          {
            ref: item.pkgFilePath.refName,
            name: item.pkgFilePath.realName,
            uid: item.pkgFilePath.refName,
            url: `/api/patch/download/${item.pkgFilePath.refName}?mid=`,
            status: 'done'
          }
        ]
      : (item.pkgFilePath || {}).refName,
  installCommand: item.installCommand,
  uninstallCommand: item.uninstallCommand,
  upgradeCommand: item.upgradeCommand,
  selfServiceSupported: item.selfServiceSupported,
  iconFile: transformPackageIcon(item),
  createdBy: item.createdById,
  createdAt: item.createdTime
});

const transformForServer = async (item) => {
  return Promise.resolve({
    id: item.id,
    displayName: item.displayName,
    description: item.description,
    os: item.os,
    osArch: item.osArch,
    version: item.version,
    pkgType: item.pkgType,
    pkgLocation: item.pkgLocation,
    pkgFilePath:
      item.pkgLocation !== 'local_dir'
        ? { refName: item.pkgFilePath }
        : item.pkgFilePath && (item.pkgFilePath[0].ref || item.pkgFilePath[0].response)
        ? {
            refName: item.pkgFilePath[0].ref || item.pkgFilePath[0].response.ref,
            realName: item.pkgFilePath[0].name || item.pkgFilePath[0].response.name
          }
        : item.pkgFilePath,
    installCommand: item.installCommand,
    uninstallCommand: item.uninstallCommand,
    upgradeCommand: item.upgradeCommand,
    iconFile:
      item.iconFile && item.iconFile.length && (item.iconFile[0].ref || item.iconFile[0].response)
        ? {
            refName: item.iconFile[0].ref || item.iconFile[0].response.ref,
            realName: item.iconFile[0].name || item.iconFile[0].response.name
          }
        : null,
    selfServiceSupported: item.selfServiceSupported
  });
};

const sortKeyMap = {
  createdAt: 'createdTime'
};

const searchableColumns = [
  'name',
  'displayName',
  'description',
  'version',
  'installCommand',
  'uninstallCommand',
  'upgradeCommand'
];

export function getAllPackagesApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: [
        ...(sortFilter.os
          ? [
              {
                operator: '=',
                column: 'os',
                value: sortFilter.os
              }
            ]
          : []),
        ...(sortFilter.ids
          ? [
              {
                operator: 'in',
                column: 'id',
                value: sortFilter.ids.join(',')
              }
            ]
          : []),
        ...(sortFilter.searchTerm
          ? searchableColumns.map((c) => ({
              operator: 'Contains',
              column: c,
              value: sortFilter.searchTerm
            }))
          : [])
      ]
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map(transform)
      };
    });
}

export function getPackageApi(id) {
  return api.get(`${END_POINT}/${id}`).then((result) => {
    return transform(result);
  });
}

export function updatePackageApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${END_POINT}/${item.id}`, data);
    })
    .then((data) => getPackageApi(data.result));
}

export function bulkDeletePackageApi(ids) {
  return api.delete(`${END_POINT}`, {
    data: { ids }
  });
}

export function createPackageApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getPackageApi(data.result));
}

export function deletePackageApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}
