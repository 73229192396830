import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';

const ReportLayout = lazy(() => import(/* webpackChunkName: "report" */ './layout/ReportLayout'));
const ReportsList = lazy(() => import(/* webpackChunkName: "report" */ './views/ReportsList'));
const CreateReport = lazy(() => import(/* webpackChunkName: "report" */ './views/CreateReport'));
const EditReport = lazy(() => import(/* webpackChunkName: "report" */ './views/EditReport'));
const ViewReport = lazy(() => import(/* webpackChunkName: "report" */ './views/ViewReport'));

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="reports" key="reports" element={<AppLayout />}>
      <Route element={<ReportLayout />}>
        {/* if no route is defined navigate to about route */}
        <Route index element={<Navigate replace to="list" />}></Route>
        <Route path="list" element={<ReportsList />}></Route>
        <Route
          path="create"
          element={
            <PermissionChecker permission={constants.Create_Report} redirect>
              <CreateReport />
            </PermissionChecker>
          }></Route>
        <Route
          path=":id/edit"
          element={
            <PermissionChecker permission={constants.Update_Report} redirect>
              <EditReport />
            </PermissionChecker>
          }></Route>
        <Route path=":id" element={<ViewReport />}></Route>
        {/* <Route path="framework/:id" element={<FrameworkDetail />}></Route> */}
      </Route>
    </Route>
  );
}
