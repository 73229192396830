import { useNavigate } from 'react-router-dom';

import Api from '@api';
import { Button, Drawer, Row, Col } from 'antd';
import { CrudProvider } from '@/src/hooks/crud';
import Icon from '../Icon';
import { useState } from 'react';
import { DRAWER_SELECTION_PICKER_TYPE } from '../helper/picker-helper';
import Severity from '@/src/components/Severity';

export default function DrawerSelectionItemPicker({
  value,
  onChange,
  disabled,
  className,
  type,
  apiEndpoint = '',
  searchableColumns = [],
  extraQualification,
  disabledAddButton = false
}) {
  const navigate = useNavigate();

  const columns =
    type === DRAWER_SELECTION_PICKER_TYPE.PATCH
      ? [
          {
            title: 'Title',
            key: 'title',
            dataIndex: 'title',
            render({ record }) {
              return (
                <div className="flex items-center">
                  {/* eslint-disable-next-line */}
                  <a
                    className="cursor-pointer"
                    onClick={() => navigate(`/patch/patches/${record.id}`)}>
                    {record.title}
                  </a>
                </div>
              );
            }
          },
          {
            title: 'Name',
            key: 'name',
            dataIndex: 'name'
          },
          {
            title: 'Severity',
            dataIndex: 'patchSeverity',
            key: 'patchSeverity',
            render({ record }) {
              return <Severity severity={record.patchSeverity} useTag />;
            }
          },
          {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            sortable: false,
            render({ record }) {
              return (
                <div className="flex items-center">
                  <Icon
                    name={`platform_${record.osPlatform.toLowerCase()}`}
                    title={record.osPlatform}
                    className="text-lg mr-2"
                  />
                  {record.osPlatform}
                </div>
              );
            }
          },
          // {
          //   title: 'Architecture',
          //   dataIndex: 'osArch',
          //   key: 'osArch',
          //   sortable: false
          // },
          // {
          //   title: 'Bulletin ID',
          //   dataIndex: 'bulletinId',
          //   key: 'bulletinId',
          //   sortable: false
          // },
          // {
          //   title: 'Reboot Required?',
          //   dataIndex: 'rebootBehaviour',
          //   key: 'rebootBehaviour',
          //   render({ record }) {
          //     return rebootMap[record.rebootBehaviour];
          //   }
          // },

          {
            title: 'Release Date',
            dataIndex: 'releaseDate',
            key: 'releaseDate',
            sortable: false,
            type: 'datetime'
          },
          {
            title: 'Category',
            dataIndex: 'patchUpdateCategory',
            key: 'patchUpdateCategory',
            sortable: false
          },

          {
            title: 'KBID',
            dataIndex: 'kbId',
            key: 'kbId',
            sortable: false
          }

          // {
          //   title: 'Created On',
          //   dataIndex: 'createdAt',
          //   key: 'createdAt',
          //   type: 'datetime'
          // }
        ]
      : type === DRAWER_SELECTION_PICKER_TYPE.APPLICATIONS
      ? [
          {
            title: 'Name',
            key: 'name',
            dataIndex: 'name'
          },
          {
            title: 'Type',
            key: 'platform',
            dataIndex: 'platform'
          }
        ]
      : [];

  const [selectedItems, setSelectedItems] = useState(value || []);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function fetchSelectionFn(offset, size, sortFilter) {
    return Api.post(`${apiEndpoint}`, {
      offset,
      size,
      qualification: (sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
      ).concat(
        extraQualification
          ? Array.isArray(extraQualification)
            ? extraQualification
            : [extraQualification]
          : []
      )
    });
  }

  function fetchSelectedFn(offset, size, sortFilter) {
    if ((value || []).length) {
      return Api.post(`${apiEndpoint}`, {
        offset,
        size,
        qualification: [
          {
            operator: 'in',
            column: 'id',
            value
          }
        ].concat(
          sortFilter.searchTerm
            ? searchableColumns.map((c) => ({
                operator: 'Contains',
                column: c,
                value: sortFilter.searchTerm
              }))
            : []

          // extraQualification
          //   ? Array.isArray(extraQualification)
          //     ? extraQualification
          //     : [extraQualification]
          //   : []
        )
      });
    }
    return Promise.resolve({ result: [], totalCount: 0 });
  }

  function handleSelect() {
    onChange(selectedItems);
    setIsDrawerOpen(false);
  }

  return (
    <div className={`flex flex-col flex-1 ${className}`}>
      {!disabled ? (
        <div className="text-right">
          <Button
            disabled={disabledAddButton}
            size="large"
            type="link"
            onClick={() => setIsDrawerOpen(true)}>
            <Icon name="add" className="ml-2" />
            {`Add ${type}`}
          </Button>
        </div>
      ) : null}
      <div className={`flex flex-col -my-2 ${className}`}>
        <CrudProvider
          disableHeadingSlot
          key={(value || []).join(',')}
          appendColumns={
            disabled
              ? undefined
              : [
                  {
                    title: '',
                    dataIndex: 'action',
                    key: 'action',
                    width: '40px',
                    sortable: false,
                    render({ record }) {
                      return (
                        <Button
                          danger
                          type="text"
                          onClick={() => onChange(value.filter((v) => v !== record.id))}>
                          <Icon name="delete" style={{ fontSize: '1.1rem' }} />
                        </Button>
                      );
                    }
                  }
                ]
          }
          disableExport
          columns={columns}
          selectedItems={selectedItems}
          onChange={setSelectedItems}
          resourceTitle={`Selected ${type}`}
          fetchFn={(...args) => fetchSelectedFn(...args)}
        />
      </div>
      <Drawer
        title={`Select ${type}`}
        placement={'right'}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        destroyOnClose
        width="70%"
        maskClosable={false}
        open={isDrawerOpen}>
        <div className="flex flex-col min-h-0 flex-1 h-full">
          <CrudProvider
            disableExport
            disableColumnSelection
            columns={columns}
            allowSelection
            selectedItems={selectedItems}
            onChange={setSelectedItems}
            resourceTitle={`${type}`}
            hasSearch
            fetchFn={(...args) => fetchSelectionFn(...args)}
          />
          <div className="flex-shrink-0">
            <Row>
              <Col span={24} className="text-right">
                <Button type="primary" className="mr-2" onClick={handleSelect}>
                  Select
                </Button>
                <Button type="primary" ghost onClick={() => setIsDrawerOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
