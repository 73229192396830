import { Form, Spin, Select, Button, Row, Col, Input, Switch } from 'antd';
// import Merge from 'lodash/merge';
import {
  getMailServerConfigApi,
  testMailServerConfigApi,
  updateMailServerConfigApi
} from '../../api/system-settings/mail-server-config';
import { useEffect, useState } from 'react';
import TestMailServerConfig from '../../components/system-settings/TestMailServerConfig';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';
import { Permissions } from '@/src/components/Permissions';
import SettingFormLayout from '@modules/settings/components/SettingFormLayout';

export default function MailServerConfig({ onDone }) {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [isModalopen, setIsModalopen] = useState(false);
  const [config, setConfig] = useState({});
  const [form] = Form.useForm();
  const [testStatus, setTestStatus] = useState({});
  const { hasPermission } = Permissions.usePermission();

  useEffect(() => {
    getMailServerConfigApi().then((response) => {
      setConfig(response);
      form.setFieldsValue(response);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const submitForm = (data) => {
    setTestStatus({});
    setProcessing(true);
    updateMailServerConfigApi(data)
      .then(() => {
        if (onDone) {
          onDone();
        }
      })
      .finally(() => setProcessing(false));
  };

  const handleTriggerTest = () => {
    setTestStatus({});
    form
      .validateFields()
      .then(() => {
        setIsModalopen(true);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onInitiateTest = (data) => {
    setProcessing(true);
    setIsModalopen(false);
    testMailServerConfigApi({
      ...form.getFieldsValue(),
      ...data
    })
      .then((data) => {
        setProcessing(false);
        setTestStatus({
          status: 'success',
          message: data.message
        });
      })
      .catch((e) => {
        setProcessing(false);
        setTestStatus({
          status: 'error',
          message: e.response.data['response-message']
        });
      });
  };

  const protocolOptions = ['None', 'SSL', 'TLS'].map((p) => ({ label: p, value: p }));

  function onValuesChange(changedValues, allValues) {
    setConfig(allValues);
  }

  const formContent = (
    <>
      <Form
        layout="vertical"
        form={form}
        className="h-full"
        disabled={!hasPermission(constants.Update_Settings)}
        onFinish={submitForm}
        onValuesChange={onValuesChange}
        initialValues={config}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="SMTP Host" name="host" rules={[{ required: true }]}>
              <Input placeholder="SMTP Host" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="SMTP Port" name="port" rules={[{ required: true }]}>
              <Input placeholder="SMTP Port" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Protocol" name="protocol" rules={[{ required: true }]}>
              <Select placeholder="Protocol" options={protocolOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Enable Authentication" name="authEnabled" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={24}></Col>
          {config.authEnabled ? (
            <>
              <Col span={12}>
                <Form.Item label="Username" name="username" rules={[{ required: true }]}>
                  <Input placeholder="Username" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                  <Input placeholder="Password" type="password" />
                </Form.Item>
              </Col>
            </>
          ) : null}
          {testStatus.status ? (
            <Col span={24}>
              <div className={testStatus.status === 'error' ? 'text-danger' : 'text-success'}>
                {testStatus.message}
              </div>
            </Col>
          ) : null}
          <PermissionChecker permission={constants.Update_Settings}>
            <Col span={24} className="text-left">
              <Button type="primary" loading={processing} htmlType="submit" className="mr-2">
                Save
              </Button>
              <Button
                ghost
                type="primary"
                loading={processing}
                htmlType="button"
                className="mr-2"
                onClick={handleTriggerTest}>
                Test
              </Button>
              <Button type="primary" ghost htmlType="reset">
                Reset
              </Button>
            </Col>
          </PermissionChecker>
        </Row>
      </Form>
      <TestMailServerConfig
        open={isModalopen}
        onCreate={onInitiateTest}
        onCancel={() => {
          setIsModalopen(false);
        }}
      />
    </>
  );

  return loading ? (
    <div className="flex flex-col min-h-0 flex-1 items-center justify-center">
      <Spin spinning />
    </div>
  ) : (
    <SettingFormLayout title="Patch Preferences" form={formContent} />
  );
}
