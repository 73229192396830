import { Select } from 'antd';
import Capitalize from 'lodash/capitalize';

export default function PatchSeverityPicker({ textOnly, ...props }) {
  const severityOptions = ['unspecified', 'critical', 'important', 'moderate', 'low'].map((i) => ({
    label: Capitalize(i),
    value: i
  }));
  if (textOnly) {
    const selected_severity = severityOptions.find((s) => s.value === props.value);
    return (selected_severity || {}).label || '---';
  }
  return <Select options={severityOptions} placeholder="Select" {...props} />;
}
