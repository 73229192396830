import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';

const ComplianceLayout = lazy(() =>
  import(/* webpackChunkName: "compliance" */ './layout/ComplianceLayout')
);
const ComplianceRules = lazy(() =>
  import(/* webpackChunkName: "compliance" */ './views/ComplianceRules')
);
const ComplianceBundle = lazy(() =>
  import(/* webpackChunkName: "compliance" */ './views/ComplianceBundle')
);
const ComplianceDeployments = lazy(() =>
  import(/* webpackChunkName: "compliance" */ './views/ComplianceDeployment')
);
// const Framework = lazy(() => import(/* webpackChunkName: "compliance" */ './views/Framework'));
// const FrameworkDetail = lazy(() =>
//   import(/* webpackChunkName: "compliance" */ './views/FrameworkDetail')
// );
/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="compliance" key="compliance" element={<AppLayout />}>
      <Route element={<ComplianceLayout />}>
        {/* if no route is defined navigate to about route */}
        <Route index element={<Navigate replace to="rules" />}></Route>
        <Route path="rules" element={<ComplianceRules />}></Route>
        <Route path="bundle" element={<ComplianceBundle />}></Route>
        <Route path="deployments" element={<ComplianceDeployments />}></Route>
        {/* <Route path="framework" element={<Framework />}></Route>
        <Route path="framework/:id" element={<FrameworkDetail />}></Route> */}
      </Route>
    </Route>
  );
}
