import { useAuth } from '@/src/hooks/auth';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export default function buildPickerContext(apiFn, PickerComponent, transformer) {
  const Context = createContext();

  function contextHook() {
    // eslint-disable-next-line
    return useContext(Context);
  }

  const Provider = (props) => {
    const [options, setOptions] = useState(new Map());
    const [searchTerm, setSearchTerm] = useState(null);
    const { token } = useAuth();

    useEffect(() => {
      (props.apiFn || apiFn)(0, 1000, {}).then(({ result }) => {
        const m = new Map();
        result.forEach((i) => {
          if (transformer) {
            m.set(i.id, transformer(i, { token }));
          } else {
            m.set(i.id, { value: i.id, label: i.name });
          }
        });
        setOptions(m);
      });
    }, [token, props.apiFn]);

    const value = useMemo(
      () => ({
        ...(searchTerm
          ? {
              filteredOptions: Array.from(options.values()).filter(
                (i) =>
                  (i.filterLabel || i.label).toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
              )
            }
          : {}),
        allOptions: options,
        search: setSearchTerm
      }),
      // eslint-disable-next-line
      [options, searchTerm]
    );

    return (
      <Context.Provider value={value}>
        {props.children || <PickerComponent {...props} />}
      </Context.Provider>
    );
  };

  return { Provider, contextHook };
}
