import PublicLayout from '@/src/layouts/PublicLayout.jsx';
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import Login from './views/Login.jsx';
/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="/" key="auth" element={<PublicLayout />}>
      {/* if no route is defined navigate to about route */}
      <Route index element={<Navigate replace to="login" />}></Route>
      <Route path="login" element={<Login />} />
    </Route>
  );
}
