import api from '@api';

const END_POINT = `/settings/mail-server/0`;

const transform = (data) => ({
  host: data.mail_server_host,
  port: data.mail_server_port,
  protocol: data.mail_server_protocol,
  authEnabled: data.mail_server_auth_status === 'yes',
  email: data.mail_server_email,
  username: data.username
});

const transformForServer = (data) => ({
  mail_server_host: data.host,
  mail_server_port: data.port,
  mail_server_protocol: data.protocol,
  mail_server_auth_status: data.authEnabled ? 'yes' : 'no',
  mail_server_email: data.email,
  username: data.username,
  password: data.password,
  ...(data.target ? { target: data.target } : {})
});

export function getMailServerConfigApi() {
  return api.get(END_POINT).then(({ result }) => transform(result));
}

export function updateMailServerConfigApi(data) {
  return api.put(END_POINT, transformForServer(data)).then(() => getMailServerConfigApi());
}

export function testMailServerConfigApi(data) {
  return api.post(`/settings/mail-server/test`, transformForServer(data)).then((data) => ({
    status: data.status,
    error: data['response-message'],
    message: data.result
  }));
}
