import Merge from 'lodash/merge';
import Omit from 'lodash/omit';
// import Moment from 'moment'
import DefaultConfig from './default-config';

const ExcludedConfigs = [];

export default function buildChartConfig(config = {}) {
  let colors = (config.series || []).map((series) => series.color).filter(Boolean);
  if ((config.series || []).length && config.series[0].type === 'pie') {
    colors = config.series[0].data.map((d) => d.color).filter(Boolean);
  }
  return Merge(
    {},
    DefaultConfig,
    {
      title: {
        text: config.title,
        subtext: {
          text: config.subtitle
        }
      },

      ...(config.serverSideZoom ? { serverSideZoom: config.serverSideZoom } : {}),

      ...(colors.length || (config.colors || []).length
        ? { color: colors.length ? colors : config.colors }
        : {}),

      ...(config.pointTransformer
        ? {
            series: (config.series || []).map((series) => ({
              name: series.name,
              ...Omit(series, ['data']),
              data: (series.data || []).map((d) => config.pointTransformer(d))
            }))
          }
        : { series: config.series })
    },
    Omit(config, ExcludedConfigs)
  );
}
