import { Link } from 'react-router-dom';
import Picker from './Picker.jsx';
import buildPicker from './pickerBuilder';
import Api from '@api';
import Icon from '../Icon.jsx';

const { Provider, contextHook } = buildPicker(
  () =>
    Api.get(`/inventory/asset`).then(({ result }) => ({
      result
    })),
  AssetPicker,
  (i) => ({
    value: i.id,
    filterLabel: `${i.hostname}`,
    label: (
      <div className="flex items-center">
        <Icon
          name="inventory-host"
          className={`mr-2 text-lg ${
            (i.current_status || '').toLowerCase() === 'offline' ? 'text-danger' : 'text-success'
          }`}
        />
        <Icon
          name={`platform_${i.platform.toLowerCase()}`}
          title={i.os_version}
          className="text-lg mr-2"
        />
        {i.hostname}
      </div>
    ),
    ...i
  })
);

function AssetPicker({ value, onChange, linkToAsset, resolveId, ...props }) {
  const { filteredOptions, allOptions, search } = contextHook();
  if (props.disabled && props.textOnly) {
    let option = allOptions.has(value) ? allOptions.get(value) : {};
    if (resolveId) {
      option = Array.from(allOptions.values()).find((i) => i.label === value) || {};
    }
    if (linkToAsset) {
      return <Link to={`/inventory/endpoints/${option.value}`}>{option.label}</Link>;
    }
    return option.label || null;
  }
  return (
    <Picker
      {...props}
      style={{ width: '100%' }}
      placeholder="Please Select"
      value={value}
      onChange={onChange}
      onSearch={search}
      options={filteredOptions || allOptions}
    />
  );
}

export const Asset = {
  useAssets: contextHook,
  Provider: Provider,
  Picker: AssetPicker
};
