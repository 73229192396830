import AntIcon, { QuestionOutlined } from '@ant-design/icons';
import icons from '../icons';

export default function Icon({ name, ...props }) {
  if (name in icons) {
    return <AntIcon {...props} component={icons[name]} />;
  }

  return <QuestionOutlined {...props} />;
}
