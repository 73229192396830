import { useState, createContext, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import { Outlet } from 'react-router-dom';
import PageHeading from '@/src/components/PageHeading';
// import PermissionChecker from '@/src/components/PermissionChecker';
// import constants from '@/src/constants/index';
import SplitPane from '@/src/components/SplitPane';

const DeviceAutomationLayoutContext = createContext();

export default function DeviceAutomationLayout({ routes }) {
  const [showMenu, setShowMenu] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [route, setRoute] = useState();

  useEffect(() => {
    setRoute(location.pathname.split('/').pop());
  }, [location]);

  function handleFilterSelected(value) {
    navigate(
      `/automation/${
        location.pathname.indexOf('software') >= 0
          ? 'software-automation'
          : 'configuration-automation'
      }${location.pathname.indexOf(value) >= 0 ? '' : `/${value}`}`
    );
  }

  return (
    <DeviceAutomationLayoutContext.Provider
      value={{
        hideMenu: () => setShowMenu(false),
        showMenu: () => setShowMenu(true),
        displayNone: () => setIsMenuVisible(false),
        displayBlock: () => setIsMenuVisible(true),
        route,
        setRoute
      }}>
      <div className="h-full flex flex-col">
        <PageHeading icon="automation" title="Device Automation" />
        {/* <PermissionChecker permission={constants.View_Inventory} redirect> */}
        <SplitPane
          hasMenu={showMenu}
          isMenuVisible={isMenuVisible}
          onVisibleChange={(i) => setIsMenuVisible(i)}
          leftPane={
            <Tabs
              activeKey={route}
              tabPosition="left"
              className="w-full h-full mt-12 device-automation-tab"
              onChange={(key) => handleFilterSelected(key)}
              items={routes}
            />
          }
          rightPane={<Outlet />}
        />
        {/* </PermissionChecker> */}
      </div>
    </DeviceAutomationLayoutContext.Provider>
  );
}

export function useInventoryLayout() {
  return useContext(DeviceAutomationLayoutContext);
}
