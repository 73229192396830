import { Tabs } from 'antd';
import { useState } from 'react';
import MaliciousHashForm from '../../components/system-settings/MaliciousHashForm';
import BlackListedIPForm from '../../components/system-settings/BlackListedIPForm';
import SettingFormLayout from '@modules/settings/components/SettingFormLayout';

export default function ThreatDatabase() {
  const [currentTab, setCurrentTab] = useState('malicious-hash');

  const formContent = (
    // <div className="flex flex-col px-6">
    <>
      <Tabs
        activeKey={currentTab}
        className="w-full"
        onChange={(key) => setCurrentTab(key)}
        items={[
          { label: 'Malicious Hash', key: 'malicious-hash' },
          { label: 'Blacklisted IP', key: 'blacklisted-ip' }
        ]}
      />
      {currentTab === 'malicious-hash' ? <MaliciousHashForm /> : <BlackListedIPForm />}
    </>
    // </div>
  );

  return <SettingFormLayout title="Patch Preferences" form={formContent} />;
}
